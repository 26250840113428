import React from 'react'
import { tw } from 'react-native-tailwindcss'
import {
  View,
  HStack,
  Container,
  VStack,
  Box,
  Heading,
  Text,
  Image,
  Button,
  Center,
  Avatar,
  Stack,
  NativeBaseProvider,
  CircleIcon,
  Alert,
  Link,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import {
  GET_HELIPORT_BY_ICAO_CODE,
  GET_HELIPORT_IMAGE,
} from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { useParams } from '../routing'
import GoogleMapReact from 'google-map-react'
import { API_KEY_MAP } from '@env'
import logo from '../assets/logo_yellow_circle.png'
import { storage } from '../utils/nhost'
import { Decimal2DMS } from 'dms-to-decimal'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'

const TextBox = ({ text }) => (
  <Center>
    <Avatar
      bg="yellow"
      source={{
        uri: logo,
      }}
      size="md"
    />
    <HStack>
      <Text bold>{text}</Text>
    </HStack>
  </Center>
)

export const Heliport = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [heliport, setHeliport] = React.useState(null)
  const [url, setUrl] = React.useState('')
  let { id } = useParams()
  const { t } = useTranslation()

  const [getHeliportImage] = useLazyQuery(GET_HELIPORT_IMAGE)
  const [getHeliport] = useLazyQuery(GET_HELIPORT_BY_ICAO_CODE)
  const [maxPriceHeliport, setMaxPriceHeliport] = React.useState(0)
  const [minPriceHeliport, setMinPriceHeliport] = React.useState(0)

  React.useEffect(async () => {
    if (id === 'contrato.pdf' || id === 'contract.pdf') {
      window.location.reload(false)
    } else {
      const heliport = await getHeliport({
        variables: { _ilike: `%${id=='box54'?'sjco':id}%` },
      })

      if (heliport.data.heliports.length > 0) {
        setHeliport(heliport.data.heliports[0])

        getMaxPrice(
          heliport.data.heliports[0].heliport_profile.heliport_aviabilities
        )
        getMinPrice(
          heliport.data.heliports[0].heliport_profile.heliport_aviabilities
        )
        let url = ''
        if (heliport.data.heliports[0].heliport_profile.heliport_image_id) {
          const heliportImage = await getHeliportImage({
            variables: {
              id: heliport.data.heliports[0].heliport_profile.heliport_image_id,
            },
          })
          const fileId = heliportImage.data.heliport_images_by_pk.file_id
          const getUrl = await storage.getPresignedUrl({ fileId })
          url = getUrl.presignedUrl.url
        } else {
          url = `https://cdn.pixabay.com/photo/2017/03/04/14/19/helicopter-2116170_1280.jpg`
        }
        setUrl(url)
      }
    }
    setLoading(false)
  }, [])

  const getMaxPrice = (aviabilities) => {
    const pricesAviabilities = aviabilities.map((aviability) => {
      return aviability.price
    })
    const maxPrice = Math.max(...pricesAviabilities)
    setMaxPriceHeliport(maxPrice)
  }

  const getMinPrice = (aviabilities) => {
    const pricesAviabilities = aviabilities.map((aviability) => {
      return aviability.price
    })
    const minPrice = Math.min(...pricesAviabilities)
    setMinPriceHeliport(minPrice)
  }

  const goToScheduleHeliport = () => {
    props.history.push(
      `/scheduleHeliport?heliport=${heliport.heliport_profile.timekit_project_id}&company=${heliport.company.id}`
    )
  }

  const coordinateHeliportLatitude = () => {
    const typeLatitude = 'latitude'
    const latitude = Decimal2DMS(
      heliport.heliport_profile.latitude,
      typeLatitude
    )
    return latitude
  }

  const coordinateHeliportLongitude = () => {
    const typeLongitude = 'longitude'
    const longitude = Decimal2DMS(
      heliport.heliport_profile.longitude,
      typeLongitude
    )
    return longitude
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const landingSlotLanguage = (landing) => {
    const number = landing.split(' ')[0]
    const time = landing.split(' ')[1]
    if (time === 'minutes') {
      return `${number} ${t('heliportDetailsOperator.minutes')}`
    } else {
      return `${number} ${t('heliportDetailsOperator.hours')}`
    }
  }
  const getHeliportDimensions = (dimensions) => {
    if (dimensions) {
      const dimensionsLimit = JSON.parse(dimensions)
      const dimensionOne = dimensionsLimit.dimension_one
        ? dimensionsLimit.dimension_one
        : ''
      const dimensionTwo = dimensionsLimit.dimension_two
        ? dimensionsLimit.dimension_two
        : ''
      return `${dimensionOne} x ${dimensionTwo}`
    } else {
      return ''
    }
  }

  if (loading) return <Loading />
  return (
    <View
      style={{
        backgroundColor: 'white',
      }}
    >
      <AppBarHeader
        style={{
          flex: 1,
        }}
      />
      {heliport ? (
        <View pl="10%" pr="10%">
          <NativeBaseProvider>
            <Heading size="3xl" style={[tw.fontMono]} mb="5">
              <Box pl={2} pr={2} rounded="xl" bgColor="#F9D342">
                <Text>{heliport.heliport_profile.icao_code}</Text>
              </Box>
              <Text> {heliport.heliport_profile.name}</Text>
            </Heading>

            <Stack
              direction={['column', 'column', 'column', 'row']}
              space={1}
              mb="5"
            >
              <Text bold fontSize="xl">
                {heliport.heliport_profile.city},{' '}
                {heliport.heliport_profile.country === 'Brazil'
                  ? t('manageHeliport.country.brazil')
                  : heliport.heliport_profile.country === 'USA'
                  ? t('manageHeliport.country.usa')
                  : t('manageHeliport.country.mexico')}{' '}
                <CircleIcon pl={3} pr={3} size={['1', '1', '2', '2']} mb={1} />
              </Text>
              <Text fontSize="xl">
                <Stack direction={['column', 'column', 'column', 'row']}>
                  <Text pr={3} fontWeight="bold">
                    {t('heliportDetailsOperator.latitude')}
                  </Text>
                  {coordinateHeliportLatitude()}
                  <Text pl={3} pr={3} fontWeight="bold">
                    {t('heliportDetailsOperator.longitude')}
                  </Text>
                  {coordinateHeliportLongitude()}
                </Stack>
              </Text>
            </Stack>
            <VStack space={4}>
              <View>
                <Box p="5" rounded="xl" bgColor="gray.200">
                  <Stack
                    space={5}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Box
                      p="6"
                      rounded="xl"
                      bgColor="white"
                      width={['100%', '100%', '100%', '70%']}
                    >
                      <Stack
                        direction={['column', 'column', 'column', 'row']}
                        space={3}
                      >
                        <Container width={['100%', '100%', '100%', '50%']}>
                          <VStack space={7}>
                            <View>
                              <Text
                                fontSize={['xs', 'sm', 'sm', 'md']}
                                fontWeight="bold"
                              >
                                {t('heliportDetailsOperator.location')}
                              </Text>
                              <Link
                                style={{
                                  color: '#4D4D4D',
                                  fontWeight: 'medium',
                                  textDecoration: 'none',
                                }}
                                isExternal
                                href={`https://google.com/maps/place/${heliport.heliport_profile.address}`}
                              >
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile.address}
                                </Text>
                              </Link>
                            </View>
                            <View>
                              <Text
                                fontSize={['xs', 'sm', 'sm', 'md']}
                                fontWeight="bold"
                              >
                                {t('heliportDetailsOperator.helipadDetails')}
                              </Text>
                              <HStack>
                                <Text bold fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t(
                                    'heliportDetailsOperator.fuelAvailability'
                                  )}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile.fuel_available
                                    ? t('heliportDetailsOperator.fuel')
                                    : t('heliportDetailsOperator.notFuel')}
                                </Text>
                              </HStack>
                              <HStack space={1}>
                                <Text bold fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t('heliportDetailsOperator.maxWeight')}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile.size_limit_weigth}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile
                                    .measure_limit_weigth
                                    ? heliport.heliport_profile
                                        .measure_limit_weigth
                                    : ' -pounds/kilos'}
                                </Text>
                              </HStack>
                              <HStack space={1}>
                                <Text bold fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t(
                                    'heliportDetailsOperator.helipadDimensions'
                                  )}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {getHeliportDimensions(
                                    heliport.heliport_profile.dimensions_limit
                                  )}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile
                                    .measure_rotor_diameter
                                    ? heliport.heliport_profile
                                        .measure_rotor_diameter
                                    : '  -feets/meters'}
                                </Text>
                              </HStack>
                              <HStack>
                                <Text bold fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t('heliportDetailsOperator.typeSurface')}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile.surface_type}
                                </Text>
                              </HStack>
                              <HStack>
                                <Text bold fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t('heliportDetailsOperator.helipadsNumber')}
                                </Text>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {heliport.heliport_profile.helipads_number}
                                </Text>
                              </HStack>
                            </View>
                          </VStack>
                        </Container>
                        <Container width={['100%', '100%', '100%', '50%']}>
                          <VStack space={1}>
                            <View>
                              <Text
                                fontSize={['xs', 'sm', 'sm', 'md']}
                                fontWeight="bold"
                              >
                                {t('heliportDetailsOperator.fees')}
                              </Text>
                            </View>
                            {minPriceHeliport > 0 && maxPriceHeliport > 0 ? (
                              <View>
                                <HStack space={1}>
                                  <View
                                    pl={2}
                                    pr={2}
                                    rounded="xl"
                                    bgColor="#F9D342"
                                  >
                                    <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                      {heliport.heliport_profile
                                        .heliport_currency === 'R$'
                                        ? 'R$'
                                        : '$'}
                                      {minPriceHeliport}
                                    </Text>
                                  </View>
                                  {minPriceHeliport === maxPriceHeliport ? (
                                    <View />
                                  ) : (
                                    <View>
                                      <HStack space={1}>
                                        <Text
                                          fontSize={['xs', 'sm', 'sm', 'md']}
                                        >
                                          -
                                        </Text>
                                        <View
                                          pl={2}
                                          pr={2}
                                          rounded="xl"
                                          bgColor="#F9D342"
                                        >
                                          <Text
                                            fontSize={['xs', 'sm', 'sm', 'md']}
                                          >
                                            {heliport.heliport_profile
                                              .heliport_currency === 'R$'
                                              ? 'R$'
                                              : '$'}
                                            {maxPriceHeliport}
                                          </Text>
                                        </View>
                                      </HStack>
                                    </View>
                                  )}
                                  <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                    {t('heliportDetailsOperator.landingFee')}
                                  </Text>
                                </HStack>
                              </View>
                            ) : (
                              <View>
                                <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                  {t('homepage.free')}
                                </Text>
                              </View>
                            )}
                            {heliport.heliport_profile.parking_available ===
                              'false' ||
                            heliport.heliport_profile.hourly_rate === 0 ||
                            heliport.heliport_profile.overnight_rate === 0 ? (
                              <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                {t('heliportDetailsOperator.notParking')}
                              </Text>
                            ) : (
                              <View>
                                <VStack space={1}>
                                  <View>
                                    <HStack space={1}>
                                      <View
                                        pl={2}
                                        pr={2}
                                        rounded="xl"
                                        bgColor="#F9D342"
                                      >
                                        <Text
                                          fontSize={['xs', 'sm', 'sm', 'md']}
                                        >
                                          {heliport.heliport_profile
                                            .heliport_currency === 'R$'
                                            ? 'R$'
                                            : '$'}
                                          {
                                            heliport.heliport_profile
                                              .hourly_rate
                                          }
                                        </Text>
                                      </View>
                                      <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                        {t(
                                          'heliportDetailsOperator.hourlyRate'
                                        )}
                                      </Text>
                                    </HStack>
                                  </View>
                                  <View>
                                    <HStack space={1}>
                                      <View
                                        pl={2}
                                        pr={2}
                                        rounded="xl"
                                        bgColor="#F9D342"
                                      >
                                        <Text
                                          fontSize={['xs', 'sm', 'sm', 'md']}
                                        >
                                          {heliport.heliport_profile
                                            .heliport_currency === 'R$'
                                            ? 'R$'
                                            : '$'}
                                          {
                                            heliport.heliport_profile
                                              .overnight_rate
                                          }
                                        </Text>
                                      </View>
                                      <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                        {t(
                                          'heliportDetailsOperator.overnightRate'
                                        )}
                                      </Text>
                                    </HStack>
                                  </View>
                                </VStack>
                              </View>
                            )}

                            <View pt={4}>
                              <Text
                                fontSize={['xs', 'sm', 'sm', 'md']}
                                fontWeight="bold"
                              >
                                {t('heliportDetailsOperator.helipadManager')}
                              </Text>
                              <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                {heliport.heliport_profile.manager_name}
                              </Text>
                              <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                {heliport.heliport_profile.email}
                              </Text>
                              <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                                {formatPhoneNumber(
                                  heliport.heliport_profile.phone.toString()
                                )}
                              </Text>
                            </View>
                          </VStack>
                        </Container>
                      </Stack>
                    </Box>
                    <Box
                      rounded="xl"
                      bgColor="gray.200"
                      width={['100%', '100%', '100%', '30%']}
                    >
                      <Stack
                        space={3}
                        direction={['column', 'column', 'column', 'row']}
                      >
                        <View
                          pt={[0, 0, 0, 20]}
                          style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <VStack space={3}>
                            <Center>
                              <HStack space={1}>
                                <Text
                                  fontSize={['xs', 'sm', 'sm', 'md']}
                                  fontWeight="bold"
                                >
                                  {landingSlotLanguage(
                                    heliport.heliport_profile
                                      .landing_slot_length
                                  )}
                                </Text>
                                <Text
                                  fontSize={['xs', 'sm', 'sm', 'md']}
                                  fontWeight="bold"
                                >
                                  {t('heliportDetailsOperator.landing')}
                                </Text>
                              </HStack>
                            </Center>
                            <View alignItems="center">
                              <Button
                                bgColor="#F9D342"
                                width={[200, 200, 200, 200]}
                                onPress={() => {
                                  goToScheduleHeliport()
                                }}
                              >
                                <Text bold={true} fontSize={['xs', 'sm', 'sm']}>
                                  {t('heliportDetailsOperator.makeReservation')}
                                </Text>
                              </Button>
                            </View>
                            <View alignItems="center">
                              <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                                {heliport.heliport_profile.timezone
                                  .split('/')[1]
                                  .replaceAll('_', ' ')}{' '}
                                {t('heliportDetailsOperator.standar')}
                              </Text>
                              <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                                GMT{heliport.heliport_profile.timezone_number}
                              </Text>
                            </View>
                          </VStack>
                        </View>
                      </Stack>
                    </Box>
                  </Stack>
                  <View pt={5}>
                    <Box p="6" rounded="xl" bgColor="white">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('heliportDetailsOperator.description')}
                      </Text>
                      <Box>
                        <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                          {heliport.heliport_profile.heliport_description
                            ? heliport.heliport_profile.heliport_description
                            : t('heliportDetailsOperator.none')}
                        </Text>
                      </Box>
                    </Box>
                  </View>
                  <View pt={5}>
                    <Box p="6" rounded="xl" bgColor="white">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('heliportDetailsOperator.otherRestrictions')}
                      </Text>
                      <Box>
                        <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                          {heliport.heliport_profile.other_restrictions
                            ? heliport.heliport_profile.other_restrictions
                            : t('heliportDetailsOperator.none')}
                        </Text>
                      </Box>
                    </Box>
                  </View>
                </Box>
              </View>
              <View>
                <Center pt={4}>
                  <Text fontSize={['xs', 'sm', 'sm', 'md']}>
                    {t('heliportDetailsOperator.map')}
                  </Text>
                </Center>
                <Box
                  width="100%"
                  height={['400', '400', '600', '500']}
                  pt={4}
                  pb={10}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: API_KEY_MAP,
                    }}
                    defaultCenter={{
                      lat: parseFloat(heliport.heliport_profile.latitude),
                      lng: parseFloat(heliport.heliport_profile.longitude),
                    }}
                    defaultZoom={17}
                  >
                    <TextBox
                      lat={parseFloat(heliport.heliport_profile.latitude)}
                      lng={parseFloat(heliport.heliport_profile.longitude)}
                      text={heliport.heliport_profile.name}
                    />
                  </GoogleMapReact>
                </Box>
              </View>
              <View>
                <Box width="100%" height={['400', '400', '500', '500']}>
                  <Image
                    source={{
                      uri: url,
                    }}
                    rounded="xl"
                    alt="Alternate Text"
                    style={{ width: '100%', height: '100%' }}
                  />
                </Box>
              </View>
            </VStack>
          </NativeBaseProvider>
        </View>
      ) : (
        <Center>
          <VStack space={5}>
            <Alert status="warning">
              <VStack space={1} flexShrink={1} w="100%">
                <HStack
                  flexShrink={1}
                  space={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HStack flexShrink={1} space={2} alignItems="center">
                    <Alert.Icon />
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      _dark={{
                        color: 'coolGray.800',
                      }}
                    >
                      {t('try_again.text')}
                    </Text>
                  </HStack>
                </HStack>
                <Box
                  pl="6"
                  _dark={{
                    _text: {
                      color: 'coolGray.600',
                    },
                  }}
                >
                  {t('errors.heliportNotFound')}
                </Box>
              </VStack>
            </Alert>
          </VStack>
        </Center>
      )}
    </View>
  )
}
export default Heliport
