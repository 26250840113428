import React from 'react'
import {
  View,
  Box,
  Heading,
  Text,
  HStack,
  VStack,
  Button,
  TextArea,
  Modal,
  Popover,
  Input,
  Image,
  Stack,
  NativeBaseProvider,
  Pressable,
  Center,
  CircleIcon,
  Select,
  CheckIcon,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useParams, useHistory } from '../routing'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../Token'
import {
  GET_OPERATOR_CONTACT,
  GET_COMPANY_MANAGERS,
  GET_COMPANY_USERS,
} from '../graphql/queries'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'
import TimePicker from 'rc-time-picker'
import '../assets/index.css'
import button_plus from '../assets/button_plus.png'
import minus_icon from '../assets/minus_icon.png'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment-timezone'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { tw } from 'react-native-tailwindcss'
import errorImage from '../assets/error.png'

const Actions = ({
  bookingDetail,
  users,
  onAction,
  timezone,
  openLoading,
  closeLoading,
}) => {
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [visible, setIsVisible] = React.useState(false)
  const [bookingId, setBookingId] = React.useState('')
  const [reason, setReason] = React.useState('')
  const [visibleEdit, setIsVisibleEdit] = React.useState(false)
  const [start, setStart] = React.useState(moment('00:00', 'HH:mm'))
  const [end, setEnd] = React.useState(moment('00:00', 'HH:mm'))
  const [date, setDate] = React.useState(new Date())
  const [dateInput, setDateInput] = React.useState('')
  const [error, setError] = React.useState('')
  const [errorEditManger, setErrorEditManager] = React.useState('')
  const { t } = useTranslation()
  const [value] = React.useState(new Date())
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const [newBoookingDate, setNewBoookingDate] = React.useState('')

  const acepptBookings = async (id) => {
    try {
      openLoading()
      const getAceptedBooking = await axiosInstance.post(`/bookings/accept`, {
        bookingId: id,
      })
      onAction(getAceptedBooking.data.booking.id, timezone)
      closeLoading()
    } catch (error) {
      closeLoading()
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.acceptBooking'))
      } else {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  const reasons = (e) => {
    setReason(e.currentTarget.value)
  }

  let history = useHistory()

  const cancelBooking = async (id) => {
    try {
      openLoading()
      const getCanceledBooking = await axiosInstance.post(`/bookings/cancel`, {
        bookingId: id,
      })
      onAction(getCanceledBooking.data.booking.id, timezone)
      closeLoading()
    } catch (error) {
      closeLoading()
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.cancelBooking'))
      } else {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  const deleteBooking = async (id) => {
    try {
      await axiosInstance.post(`/bookings/delete`, {
        bookingId: id,
      })
      history.push('/')
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.declineBooking'))
      } else {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  const declineBooking = async () => {
    try {
      await axiosInstance.post(`/bookings/decline`, {
        booking_id: bookingId,
        reason: reason,
      })
      document.body.style.overflow = 'scroll'
      history.push('/')
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('errors.declineBooking'))
      } else {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  const onChange = (newDate) => {
    const getDatePicker = newDate.toDateString()
    setNewBoookingDate(getDatePicker)
    setDateInput(moment(getDatePicker).format('ddd, ll'))
  }

  const proposeNewDateAndTime = async (id) => {
    await history.push(
      `/scheduleHeliport?heliport=${bookingDetail.project.id}&company=${
        bookingDetail.meta.company_id
      }&booking=${id}&propose=${true}&graph=${bookingDetail.state}`
    )
  }
  const openModal = (id) => {
    setBookingId(id), window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }

  const openModalEditDateHour = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleEdit(true)
  }

  const closeModalEditDateHour = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleEdit(false)
  }

  if (
    bookingDetail.state === 'cancelled' ||
    bookingDetail.state === 'cancelled_by_customer' ||
    bookingDetail.state === 'declined' ||
    bookingDetail.state === 'completed'
  ) {
    return (
      <HStack space={3}>
        <Button
          bgColor="danger.500"
          onPress={() => {
            deleteBooking(bookingDetail.id)
          }}
        >
          {t('managerBookingDetail.deleteButton')}
        </Button>
      </HStack>
    )
  } else if (
    bookingDetail.state === 'tentative' &&
    bookingDetail.meta.manual_reservation === true
  ) {
    return <View></View>
  } else if (bookingDetail.state === 'tentative') {
    return (
      <View>
        <HStack space={3}>
          <Button
            bgColor="gray.300"
            size="md"
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              proposeNewDateAndTime(bookingDetail.id)
            }}
          >
            {t('managerBookingDetail.proposeButton')}
          </Button>
          <Button
            bgColor="yellow.300"
            size="md"
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              acepptBookings(bookingDetail.id)
            }}
          >
            {t('managerBookingDetail.acceptButton')}
          </Button>
          <Button
            bgColor="danger.500"
            size="md"
            onPress={() => {
              openModal(bookingDetail.id)
            }}
          >
            {t('managerBookingDetail.rejectButton')}
          </Button>
        </HStack>
        <Modal isOpen={visible} onClose={() => closeModal()} flex={1}>
          <Modal.Content maxWidth="350">
            <Modal.CloseButton />
            <Modal.Header>
              {t('managerBookingDetail.declineBooking')}
            </Modal.Header>
            <Modal.Body>
              <Text fontSize="md" fontWeight="bold">
                {t('managerBookingDetail.reason')}
              </Text>
              <TextArea value={reason} onChange={reasons} />
              <Text p="2" fontSize="xs" color="#eb0100">
                {error}
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    closeModal()
                  }}
                >
                  {t('managerBookingDetail.cancel')}
                </Button>
                <Button
                  colorScheme="yellow"
                  onPress={() => {
                    declineBooking()
                  }}
                >
                  {t('managerBookingDetail.save')}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    )
  } else {
    return (
      <View>
        <HStack space={2}>
          <Button
            _text={{
              fontSize: ['xs', 'xs', 'sm', 'sm'],
            }}
            colorScheme="yellow"
            onPress={() => {
              proposeNewDateAndTime(bookingDetail.id)
            }}
          >
            {t('managerBookingDetail.rescheduleBooking')}
          </Button>

          <Button
            _text={{
              fontSize: ['xs', 'xs', 'sm', 'sm'],
            }}
            bgColor="danger.500"
            onPress={() => {
              cancelBooking(bookingDetail.id)
            }}
          >
            {t('managerBookingDetail.cancelReservation')}
          </Button>
        </HStack>
        <Modal
          isOpen={visibleEdit}
          onClose={() => closeModalEditDateHour()}
          flex={1}
        >
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>{t('managerBookingDetail.editBooking')}</Modal.Header>
            <Modal.Body>
              <Text pb="2" fontWeight="bold">
                {t('managerBookingDetail.landingDateTime')}
              </Text>
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Box widht="100%" borderWidth="1" borderRadius="4">
                      <HStack>
                        <Button {...triggerProps} colorScheme="yellow">
                          <Image
                            source={{
                              uri: 'https://cdn-icons-png.flaticon.com/512/2838/2838779.png',
                            }}
                            alt="Alternate Text"
                            style={{ width: 12, height: 12 }}
                          />
                        </Button>
                        <View width="90%">
                          <Pressable {...triggerProps}>
                            <Input
                              placeholder={dateInput}
                              borderColor="transparent"
                              widht="90%"
                              isReadOnly={true}
                            />
                          </Pressable>
                        </View>
                      </HStack>
                    </Box>
                  )
                }}
              >
                <Popover.Content accessibilityLabel="Delete Customerd">
                  <Popover.Arrow />
                  <Popover.Body>
                    <Calendar
                      onChange={onChange}
                      locale={language}
                      value={value}
                      maxDate={new Date(2150, 11, 31)}
                      minDate={new Date(2019, 11, 31)}
                    />
                  </Popover.Body>
                </Popover.Content>
              </Popover>
              <Text fontSize="md" fontWeight="bold">
                {t('managerBookingDetail.start')}
              </Text>
              <TimePicker
                showSecond={false}
                onChange={setStart}
                value={start}
              />
              <Text fontSize="md" fontWeight="bold">
                {t('managerBookingDetail.end')}
              </Text>
              <TimePicker showSecond={false} onChange={setEnd} value={end} />
              <Text p="2" fontSize="xs" color="#eb0100">
                {errorEditManger}
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    closeModalEditDateHour()
                  }}
                >
                  {t('managerBookingDetail.cancel')}
                </Button>
                <Button
                  colorScheme="yellow"
                  isLoading={buttonLoading}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                  onPress={() => {
                    editBooking(bookingDetail)
                  }}
                >
                  {t('managerBookingDetail.save')}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    )
  }
}

export const BookingDetail = (props) => {
  const [userId, setUserId] = React.useState('')
  const [bookingDate, setBookingDate] = React.useState('')
  const [loading, setIsLoading] = React.useState(true)
  const [bookingHourStart, setBookingHourStart] = React.useState('')
  const [bookingHourEnd, setBookingHourEnd] = React.useState('')
  const [bookingDetail, setBookingDetail] = React.useState({})
  const [operatorId, setOperatorId] = React.useState('')
  const [bookingKeys, setBookingKeys] = React.useState([])
  const [visibleEditDetails, setIsVisibleEditDetails] = React.useState(false)
  const [newDetail, setNewDetail] = React.useState('')
  const [inputnewDetail, setInputNewDetail] = React.useState('')
  const [operatorInformation, setOperatorInformation] = React.useState({})
  const [usersManagers, setUsersManagers] = React.useState([])
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [noteLoading, setNoteLoading] = React.useState(false)
  const { t } = useTranslation()
  const [dictionary, setDictionary] = React.useState({
    additional_field: t('managerBookingDetail.additionalfield'),
    flight_number: t('managerBookingDetail.flightNumber'),
    total_passenger: t('managerBookingDetail.totalPassenger'),
    disembarking_passengers: t('managerBookingDetail.disembarkingPassengers'),
    email: t('managerBookingDetail.email'),
    embarking_passengers: t('managerBookingDetail.embarkingPassengers'),
    next_destination: t('managerBookingDetail.nextDestination'),
    origin_flight: t('managerBookingDetail.originFlight'),
    passengers_ids: t('managerBookingDetail.passengersIds'),
    pilot_name: t('managerBookingDetail.pilotName'),
    copilot_name: t('managerBookingDetail.copilotName'),
    certicate_number_copilot: t(
      'managerBookingDetail.certificateNumberCopilot'
    ),
    tail_number: t('managerBookingDetail.tailNumber'),
    timezone: t('managerBookingDetail.timezone'),
    name: t('managerBookingDetail.name'),
    model: t('managerBookingDetail.model'),
    certificate_number: t('managerBookingDetail.certificateNumber'),
  })
  const [timezone, setTimezone] = React.useState('')
  const [actualArrival, setActualArrival] = React.useState(moment())
  const [actualDeparture, setActualDeparture] = React.useState(
    moment('00:00', 'HH:mm')
  )
  const [actualArrivalModal, setActualArrivalModal] = React.useState(false)
  const [actualDepartureModal, setActualDepartureModal] = React.useState(false)

  const [errorEditDetail, setErrorEditDetail] = React.useState('')

  const [locale, setLocale] = React.useState('')

  const [formValuesPassenger, setformValuesPassenger] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])

  const [note, setNote] = React.useState('')

  const [modalNote, setModalNote] = React.useState(false)
  const [errorNote, setErrorNote] = React.useState('')
  const [emptyNote, setEmptyNote] = React.useState('')

  let { id } = useParams()

  const [operator] = useLazyQuery(GET_OPERATOR_CONTACT, {
    variables: { id: operatorId },
  })

  const [managers] = useLazyQuery(GET_COMPANY_MANAGERS, {
    variables: { userId: userId },
  })

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS)

  const [showLoading, setShowLoading] = React.useState(false)

  const [projectId, setProjectId] = React.useState('')

  const bookingStatus = {
    tentative: t('managerBookingDetail.bookingStatus.tentative'),
    confirmed: t('managerBookingDetail.bookingStatus.confirmed'),
    cancelled_by_customer: t(
      'managerBookingDetail.bookingStatus.cancelledCustomer'
    ),
    cancelled: t('managerBookingDetail.bookingStatus.cancelled'),
    declined: t('managerBookingDetail.bookingStatus.declined'),
    completed: t('managerBookingDetail.bookingStatus.completed'),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  let history = useHistory()

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }
  React.useEffect(async () => {
    const tokenInformation = getToken()
    const user =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(user)

    const getoperatorInformation = await operator()
    setOperatorInformation(getoperatorInformation.data)

    const companyManagers = await managers()
    const getCompanyUsersEmails = await getUsersEmail(
      companyManagers.data.company[0].users_companies
    )
    setUsersManagers(getCompanyUsersEmails)

    const heliportTimeZone =
      companyManagers.data.company[0].heliports[0].heliport_profile.timezone

    setTimezone(heliportTimeZone)

    const timekitProjectId =
      companyManagers?.data?.company[0].heliports[0].heliport_profile
        ?.timekit_project_id
    setProjectId(timekitProjectId)
    await getBookingDetails(id, heliportTimeZone, timekitProjectId)
    const currentLocale = await AsyncStorage.getItem('i18nextLng')
    setLocale(currentLocale)
    setIsLoading(false)
  }, [])

  const getBookingDetails = async (id, heliportTimeZone, timekitProjectId) => {
    try {
      let booking = {}
      const getBooking = await axiosInstance.post(
        `/bookings/bookingByIdDetails`,
        {
          id: id,
          timekit_project_id: timekitProjectId,
        }
      )
      if (getBooking.data.booking) {
        booking = getBooking.data.booking
        if (
          booking.meta &&
          booking.meta.canceled_id &&
          booking.meta.reschedule_id
        ) {
          history.push(`/bookingDetail/${booking.meta.reschedule_id}`)
          const getRedirectBooking = await axiosInstance.post(
            `/bookings/bookingByIdDetails`,
            {
              id: booking.meta.reschedule_id,
              timekit_project_id: timekitProjectId,
            }
          )
          booking = getRedirectBooking.data.booking
        }
        setBookingDetail(booking)
        setOperatorId(
          booking.attributes.meta ? booking.attributes.meta.operator_id : null
        )
        const newBookingDetails = {
          flight_number:
            booking.meta && booking.meta.flight_number
              ? booking.meta.flight_number
              : 'None',
          total_passenger:
            booking.meta && booking.meta.total_passenger
              ? booking.meta.total_passenger
              : '0',
          disembarking_passengers:
            booking.meta && booking.meta.disembarking_passengers
              ? booking.meta.disembarking_passengers
              : booking.attributes.customer.disembarking_passengers,
          embarking_passengers:
            booking.meta && booking.meta.embarking_passengers
              ? booking.meta.embarking_passengers
              : booking.attributes.customer.embarking_passengers,
          name:
            booking.meta && booking.meta.name
              ? booking.meta.name
              : booking.attributes.customer.name,
          email:
            booking.meta && booking.meta.email
              ? booking.meta.email
              : booking.attributes.customer.email,
          tail_number:
            booking.meta && booking.meta.tail_number
              ? booking.meta.tail_number
              : booking.attributes.customer.tail_number,
          pilot_name:
            booking.meta && booking.meta.pilot_name
              ? booking.meta.pilot_name
              : booking.attributes.customer.pilot_name,
          copilot_name:
            booking.meta && booking.meta.copilot_name
              ? booking.meta.copilot_name
              : 'None',
          next_destination:
            booking.meta && booking.meta.next_destination
              ? booking.meta.next_destination
              : booking.attributes.customer.next_destination,
          origin_flight:
            booking.meta && booking.meta.origin_flight
              ? booking.meta.origin_flight
              : booking.attributes.customer.origin_flight,
        }

        let keys = Object.keys(newBookingDetails)
        setBookingKeys(keys)
        const date = moment(booking.attributes.event.start)
          .tz(heliportTimeZone)
          .format('LL')
        const bookingStart = moment(booking.attributes.event.start)
          .tz(heliportTimeZone)
          .format('HH:mm')
        const bookingEnd = moment(booking.attributes.event.end)
          .tz(heliportTimeZone)
          .format('HH:mm')
        setBookingDate(date)
        setBookingHourStart(bookingStart)
        setBookingHourEnd(bookingEnd)
      } else {
        setBookingDetail(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => setInputNewDetail(event.target.value)

  const editBookingDetail = async (detail) => {
    try {
      setButtonLoading(true)
      if (
        detail === 'disembarking_passengers' ||
        detail === 'embarking_passengers'
      ) {
        bookingDetail.meta[detail] = getPassengers(formValuesPassenger)
      } else {
        bookingDetail.meta[detail] = inputnewDetail
      }

      await axiosInstance.post(`/bookings/editBookingDetail`, {
        booking: bookingDetail,
      })
      setButtonLoading(false)
      closeModal()
    } catch (error) {
      setButtonLoading(false)
      if (error.response && error.response.data && error.response.data.errors) {
        setErrorEditDetail(t('errors.editBooking'))
      } else {
        setErrorEditDetail(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
    }
  }

  const updateActualArrival = async () => {
    const arrivalTime = actualArrival.format('HH:mm')
    try {
      await axiosInstance.post(`/bookings/updateArrivalTime`, {
        bookingId: bookingDetail.id,
        actualArrival: arrivalTime,
      })
      setActualArrivalModal(false)
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const updateActualDeparture = async () => {
    const departureTime = actualDeparture.format('HH:mm')
    try {
      await axiosInstance.post(`/bookings/updateArrivalTime`, {
        bookingId: bookingDetail.id,
        actualDeparture: departureTime,
      })
      setActualDepartureModal(false)
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersEmail = async (managersCompany) => {
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    return usersEmails
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const openModal = (booking) => {
    setNewDetail(booking)
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleEditDetails(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setInputNewDetail('')
    setIsVisibleEditDetails(false)
  }

  let addFormFields = () => {
    setformValuesPassenger([
      ...formValuesPassenger,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues.splice(i, 1)
    setformValuesPassenger(newFormValues)
  }

  let handleChangeFirstName = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].firstName = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeLastName = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].lastName = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeId = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].id = e.target.value
    setformValuesPassenger(newFormValues)
  }
  let handleChangeDocumentType = (i, e) => {
    let newFormValues = [...formValuesPassenger]
    newFormValues[i].documentType = e
    setformValuesPassenger(newFormValues)
  }

  const getPassengers = (passengers) => {
    if (passengers[0].firstName) {
      const passengerNames = passengers.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const passengerToString = passengerNames.toString()

      return passengerToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const onRefresh = async (id, timezone) => {
    const timekitProjectId = projectId
    await getBookingDetails(id, timezone, timekitProjectId)
  }

  const openModalLoading = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShowLoading(true)
  }

  const closeModalLoading = () => {
    document.body.style.overflow = 'scroll'
    setShowLoading(false)
  }

  const openModalNote = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setModalNote(true)
  }

  const closeModalNote = () => {
    document.body.style.overflow = 'scroll'
    setModalNote(false)
  }

  const notes = (e) => {
    setNote(e.currentTarget.value)
  }

  const addNotesBooking = async () => {
    if (note) {
      const meta = { ...bookingDetail.meta, note_manager: note }
      bookingDetail.meta = meta
      try {
        setNoteLoading(true)
        await axiosInstance.post(`/bookings/editBookingDetail`, {
          booking: bookingDetail,
        })
        setNoteLoading(false)
        closeModalNote()
      } catch (error) {
        setNoteLoading(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrorNote(t('managerBookingDetail.errorNote'))
        } else {
          setErrorNote(
            error.response &&
              error.response.data &&
              error.response.data.error &&
              error.response.data.error.message
              ? error.response.data.error.message
              : error.message
          )
        }
      }
    } else {
      setEmptyNote(t('managerBookingDetail.notes.emptyNote'))
    }
  }

  if (loading) {
    return <Loading />
  }

  if (bookingDetail) {
    return (
      <View>
        <AppBarHeader />
        <View pl="10%" pr="10%">
          <Heading p="1%">{t('managerBookingDetail.bookingDetail')}</Heading>
          <p />
          <Box shadow="9" rounded="2xl">
            <Box rounded="2xl" bgColor="white">
              <VStack space={3}>
                <View
                  style={{
                    backgroundColor: '#FEFBEC',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                  }}
                  p="8"
                >
                  <HStack space={3}>
                    <Text
                      fontSize={['xs', 'xs', 'xl', '2xl']}
                      fontWeight="bold"
                    >
                      {bookingDate}
                    </Text>
                    <CircleIcon size={['1', '1', '2', '2']} mt={[2, 2, 2, 4]} />
                    <Text
                      fontSize={['xs', 'xs', 'xl', '2xl']}
                      fontWeight="bold"
                    >
                      {bookingHourStart}
                    </Text>
                    <Text
                      fontSize={['xs', 'xs', 'xl', '2xl']}
                      fontWeight="bold"
                    >
                      -
                    </Text>
                    <Text
                      fontSize={['xs', 'xs', 'xl', '2xl']}
                      fontWeight="bold"
                    >
                      {bookingHourEnd}
                    </Text>
                  </HStack>
                </View>

                <NativeBaseProvider>
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <View pl="8" w={['100%', '100%', '100%', '33%']}>
                      {operatorInformation ? (
                        <View>
                          <Text
                            color="#B3B3B3"
                            fontSize={['xs', 'xs', 'sm', 'sm']}
                          >
                            {t('managerBookingDetail.operator')}
                          </Text>
                          <Text
                            color="#4D4D4D"
                            fontSize={['sm', 'sm', 'sm', 'md']}
                          >
                            {operatorInformation.operators_by_pk.operator_name}
                          </Text>
                          <br />
                          <Text
                            color="#B3B3B3"
                            fontSize={['xs', 'xs', 'sm', 'sm']}
                          >
                            {t('managerBookingDetail.phone')}
                          </Text>
                          <Text
                            color="#4D4D4D"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {formatPhoneNumber(
                              operatorInformation.operators_by_pk.operators_contact.phone.toString()
                            )}
                          </Text>
                          <br />
                          <Text
                            color="#B3B3B3"
                            fontSize={['xs', 'xs', 'sm', 'sm']}
                          >
                            {t('managerBookingDetail.email')}
                          </Text>
                          <Text
                            color="#4D4D4D"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {
                              operatorInformation.operators_by_pk
                                .operators_contact.email
                            }
                          </Text>
                        </View>
                      ) : (
                        <View>
                          <Text
                            color="#B3B3B3"
                            fontSize={['xs', 'xs', 'sm', 'sm']}
                          >
                            {t('managerBookingDetail.operator')}
                          </Text>
                          <Text
                            color="#4D4D4D"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {bookingDetail.meta && bookingDetail.meta.name
                              ? bookingDetail.meta.name
                              : bookingDetail.attributes.customer.name}
                          </Text>
                        </View>
                      )}
                      <br />
                      <Text color="#B3B3B3" fontSize={['xs', 'xs', 'sm', 'sm']}>
                        {t('managerBookingDetail.status')}
                      </Text>
                      <Text color="#4D4D4D" fontSize={['xs', 'xs', 'sm', 'md']}>
                        {bookingStatus[bookingDetail.state]}
                      </Text>
                      <br />
                      <Text color="#B3B3B3" fontSize={['xs', 'xs', 'sm', 'sm']}>
                        {t('managerBookingDetail.payment')}
                      </Text>
                      <Text color="#4D4D4D" fontSize={['xs', 'xs', 'sm', 'md']}>
                        {bookingDetail.meta.paymentStatus
                          ? getPaymentStatusStripe(bookingDetail)
                          : getPaymentStatus(bookingDetail)}
                      </Text>
                      <br />
                      {bookingDetail.meta.reason ? (
                        <View>
                          <Text
                            color="#B3B3B3"
                            fontSize={['xs', 'xs', 'sm', 'sm']}
                          >
                            {t('managerBookingDetail.reason')}
                          </Text>

                          <Text
                            color="#4D4D4D"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {bookingDetail.meta.reason === 'payment error'
                              ? t(
                                  'managerBooking.cancelCompletedRequests.errorPayment'
                                )
                              : bookingDetail.meta.reason ===
                                'Reservation canceled, due to exceeding the time of the request'
                              ? t(
                                  'managerBooking.cancelCompletedRequests.exceedingReservation'
                                )
                              : bookingDetail.meta.reason}
                          </Text>
                        </View>
                      ) : (
                        <Text></Text>
                      )}
                    </View>

                    <View
                      pl={[8, 8, 8, 0]}
                      pr={[8, 8, 8, 0]}
                      w={['100%', '100%', '100%', '33%']}
                    >
                      {bookingKeys.map((booking) => (
                        <View key={booking}>
                          <VStack>
                            <View>
                              <Text
                                color="#B3B3B3"
                                fontSize={['xs', 'xs', 'sm', 'sm']}
                              >
                                {dictionary[booking]}
                              </Text>
                            </View>
                            <HStack>
                              <View width="90%">
                                {bookingDetail.meta &&
                                bookingDetail.meta[booking] ? (
                                  <Text
                                    color="#4D4D4D"
                                    fontSize={['xs', 'xs', 'sm', 'md']}
                                  >
                                    {bookingDetail.meta[booking]}
                                  </Text>
                                ) : (
                                  <Text
                                    color="#4D4D4D"
                                    fontSize={['xs', 'xs', 'sm', 'md']}
                                  >
                                    {bookingDetail.attributes.customer[booking]}
                                  </Text>
                                )}
                              </View>
                              <View width="10%">
                                {bookingDetail.state === 'confirmed' &&
                                booking !== 'operationDisembarking' &&
                                booking !== 'operationEmbarking' ? (
                                  <Button
                                    size={'sm'}
                                    colorScheme="yellow"
                                    onPress={() => {
                                      openModal(booking)
                                    }}
                                    isDisabled={
                                      booking === 'operationDisembarking'
                                        ? true
                                        : false
                                    }
                                  >
                                    <Image
                                      source={{
                                        uri: 'https://cdn-icons-png.flaticon.com/512/1159/1159633.png',
                                      }}
                                      alt="Alternate Text"
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </Button>
                                ) : (
                                  <Text></Text>
                                )}
                              </View>
                            </HStack>
                            <br />
                          </VStack>
                        </View>
                      ))}
                    </View>
                    <View
                      pl="8"
                      pr={[8, 8, 8, 0]}
                      w={['50%', '50%', '40%', '20%']}
                    >
                      <Text
                        pb="2"
                        color="#B3B3B3"
                        fontSize={['xs', 'xs', 'sm', 'sm']}
                      >
                        {t('managerBookingDetail.actualArrival')}
                      </Text>
                      <Pressable
                        disabled={
                          bookingDetail.state === 'cancelled' ||
                          bookingDetail.state === 'declined'
                            ? true
                            : false
                        }
                        onPress={() => {
                          window.scrollTo(0, 0), setActualArrivalModal(true)
                        }}
                      >
                        <View borderWidth="2" padding="1" borderRadius="10">
                          <Text
                            color="#979797"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {bookingDetail.meta.actual_arrival
                              ? bookingDetail.meta.actual_arrival
                              : '00:00'}
                          </Text>
                        </View>
                      </Pressable>
                      <br />
                      <Text
                        pb="2"
                        color="#B3B3B3"
                        fontSize={['xs', 'xs', 'sm', 'sm']}
                      >
                        {t('managerBookingDetail.actualDeparture')}
                      </Text>
                      <Pressable
                        disabled={
                          bookingDetail.state === 'cancelled' ||
                          bookingDetail.state === 'declined'
                            ? true
                            : false
                        }
                        onPress={() => {
                          window.scrollTo(0, 0), setActualDepartureModal(true)
                        }}
                      >
                        <View borderWidth="2" padding="1" borderRadius="10">
                          <Text
                            color="#979797"
                            fontSize={['xs', 'xs', 'sm', 'md']}
                          >
                            {bookingDetail.meta.actual_departure
                              ? bookingDetail.meta.actual_departure
                              : '00:00'}
                          </Text>
                        </View>
                      </Pressable>
                      <br />
                      {bookingDetail &&
                      bookingDetail.meta &&
                      bookingDetail.meta.note_manager ? (
                        <View>
                          <VStack space={1}>
                            <Text
                              color="#B3B3B3"
                              fontSize={['xs', 'xs', 'sm', 'sm']}
                            >
                              {t('managerBookingDetail.notes.managerNote')}
                            </Text>

                            <Text
                              color="#4D4D4D"
                              fontSize={['xs', 'xs', 'sm', 'md']}
                            >
                              {bookingDetail.meta.note_manager}
                            </Text>
                          </VStack>
                        </View>
                      ) : (
                        <View />
                      )}
                      <br />
                      {bookingDetail &&
                      bookingDetail.meta &&
                      bookingDetail.meta.note_operator ? (
                        <View>
                          <VStack space={1}>
                            <Text
                              color="#B3B3B3"
                              fontSize={['xs', 'xs', 'sm', 'sm']}
                            >
                              {t('managerBookingDetail.notes.operatorNote')}
                            </Text>

                            <Text
                              color="#4D4D4D"
                              fontSize={['xs', 'xs', 'sm', 'md']}
                            >
                              {bookingDetail.meta.note_operator}
                            </Text>
                          </VStack>
                        </View>
                      ) : (
                        <View />
                      )}
                      <p />
                      <Button
                        colorScheme="yellow"
                        onPress={() => {
                          openModalNote()
                        }}
                      >
                        {bookingDetail.meta.note_manager ? (
                          <View>
                            <Text fontWeight="medium">
                              {t('managerBookingDetail.notes.editNoteTitle')}
                            </Text>
                          </View>
                        ) : (
                          <View>
                            <Text fontWeight="medium">
                              {t('managerBookingDetail.notes.addNoteTitle')}
                            </Text>
                          </View>
                        )}
                      </Button>
                    </View>
                  </Stack>
                </NativeBaseProvider>
                <View p="8" alignItems="end">
                  <Actions
                    bookingDetail={bookingDetail}
                    users={usersManagers}
                    onAction={onRefresh}
                    timezone={timezone}
                    openLoading={openModalLoading}
                    closeLoading={closeModalLoading}
                  />
                </View>
              </VStack>
            </Box>
          </Box>

          <p />
          <Modal
            isOpen={visibleEditDetails}
            onClose={() => closeModal()}
            flex={1}
          >
            <Modal.Content maxWidth="550px">
              <Modal.CloseButton />
              <Modal.Header>
                {t('managerBookingDetail.editBooking')}
              </Modal.Header>
              <Modal.Body>
                <Text fontSize="md" fontWeight="bold">
                  {newDetail.replaceAll('_', ' ')}
                </Text>
                <br />
                {newDetail === 'disembarking_passengers' ||
                newDetail === 'embarking_passengers' ? (
                  <View>
                    {formValuesPassenger.map((element, index) => (
                      <Stack
                        space={1}
                        key={index}
                        p="1%"
                        direction={['column', 'column', 'column', 'row']}
                      >
                        <Input
                          borderRadius="2xl"
                          bgColor="white"
                          placeholder="First Name"
                          value={element.firstName || ''}
                          onChange={(e) => handleChangeFirstName(index, e)}
                          width={['100%', '100%', '100%', '30%']}
                        />
                        <Input
                          borderRadius="2xl"
                          bgColor="white"
                          placeholder="Last Name"
                          value={element.lastName || ''}
                          onChange={(e) => handleChangeLastName(index, e)}
                          width={['100%', '100%', '100%', '30%']}
                        />
                        {locale === 'pt' ? (
                          <Select
                            borderRadius="2xl"
                            selectedValue={element.documentType || ''}
                            placeholder="Document"
                            _selectedItem={{
                              bg: 'teal.600',
                              endIcon: <CheckIcon size="5" />,
                            }}
                            onValueChange={(e) =>
                              handleChangeDocumentType(index, e)
                            }
                            width={['100%', '100%', '100%', '20%']}
                          >
                            <Select.Item label="Passporte" value="Passporte" />
                            <Select.Item label="CPF" value="CPF" />
                            <Select.Item label="RG" value="RG" />
                          </Select>
                        ) : (
                          <Select
                            borderRadius="2xl"
                            selectedValue={element.documentType || ''}
                            placeholder="Document"
                            _selectedItem={{
                              bg: 'teal.600',
                              endIcon: <CheckIcon size="5" />,
                            }}
                            onValueChange={(e) =>
                              handleChangeDocumentType(index, e)
                            }
                            width={['100%', '100%', '100%', '20%']}
                          >
                            <Select.Item label="Passport" value="Passport" />
                            <Select.Item
                              label="Driver's License"
                              value="Driver's License"
                            />
                          </Select>
                        )}

                        <Input
                          borderRadius="2xl"
                          bgColor="white"
                          placeholder="ID"
                          value={element.id || ''}
                          onChange={(e) => handleChangeId(index, e)}
                          width={['100%', '100%', '100%', '40%']}
                        />

                        {index ? (
                          <Pressable
                            onPress={() => removeFormFields(index)}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Image
                              source={{
                                uri: minus_icon,
                              }}
                              alt="Alternate Text"
                              style={{ width: 20, height: 20 }}
                            />
                          </Pressable>
                        ) : null}

                        <Pressable
                          onPress={() => addFormFields()}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Image
                            source={{
                              uri: button_plus,
                            }}
                            alt="Alternate Text"
                            style={{ width: 20, height: 20 }}
                          />
                        </Pressable>
                      </Stack>
                    ))}
                  </View>
                ) : (
                  <Input
                    value={inputnewDetail}
                    onChange={handleChange}
                    placeholder={newDetail}
                  />
                )}
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errorEditDetail}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onPress={() => {
                      closeModal()
                    }}
                  >
                    {t('managerBookingDetail.cancel')}
                  </Button>
                  <Button
                    isLoading={buttonLoading}
                    _loading={{
                      bg: 'amber.400:alpha.70',
                      _text: {
                        color: 'coolGray.700',
                      },
                    }}
                    _spinner={{
                      color: 'white',
                    }}
                    isLoadingText={t('submit_button.text')}
                    colorScheme="yellow"
                    onPress={() => {
                      editBookingDetail(newDetail)
                    }}
                  >
                    {t('managerBookingDetail.save')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
          <Modal
            isOpen={actualArrivalModal}
            onClose={() => setActualArrivalModal(false)}
            flex={1}
          >
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {t('managerBookingDetail.enterActualArrival')}
              </Modal.Header>
              <Modal.Body>
                <p />
                <Center>
                  <View width="50%">
                    <TimePicker
                      showSecond={false}
                      value={actualArrival}
                      onChange={setActualArrival}
                    />
                  </View>
                </Center>
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onPress={() => {
                      setActualArrivalModal(false)
                    }}
                  >
                    {t('managerBookingDetail.cancel')}
                  </Button>
                  <Button
                    isLoading={buttonLoading}
                    _loading={{
                      bg: 'amber.400:alpha.70',
                      _text: {
                        color: 'coolGray.700',
                      },
                    }}
                    _spinner={{
                      color: 'white',
                    }}
                    isLoadingText={t('submit_button.text')}
                    colorScheme="yellow"
                    onPress={() => {
                      updateActualArrival()
                    }}
                  >
                    {t('managerBookingDetail.save')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
          <Modal
            isOpen={actualDepartureModal}
            onClose={() => setActualDepartureModal(false)}
            flex={1}
          >
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {t('managerBookingDetail.enterActualDeparture')}
              </Modal.Header>
              <Modal.Body>
                <p />
                <Center>
                  <View width="50%">
                    <TimePicker
                      showSecond={false}
                      value={actualDeparture}
                      onChange={setActualDeparture}
                    />
                  </View>
                </Center>
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onPress={() => {
                      setActualDepartureModal(false)
                    }}
                  >
                    {t('managerBookingDetail.cancel')}
                  </Button>
                  <Button
                    isLoading={buttonLoading}
                    _loading={{
                      bg: 'amber.400:alpha.70',
                      _text: {
                        color: 'coolGray.700',
                      },
                    }}
                    _spinner={{
                      color: 'white',
                    }}
                    isLoadingText={t('submit_button.text')}
                    colorScheme="yellow"
                    onPress={() => {
                      updateActualDeparture()
                    }}
                  >
                    {t('managerBookingDetail.save')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
          <Modal isOpen={showLoading} _backdrop={{ bg: 'white' }}>
            <Loading />
          </Modal>
          <Modal isOpen={modalNote} onClose={() => closeModalNote()} flex={1}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {bookingDetail.meta.note_manager
                  ? t('managerBookingDetail.notes.editNoteTitle')
                  : t('managerBookingDetail.notes.addNoteTitle')}
              </Modal.Header>
              <Modal.Body>
                <Text fontSize="md" fontWeight="bold">
                  {t('managerBookingDetail.notes.note')}
                </Text>
                <TextArea value={note} onChange={notes} />
                <Text p="2" fontSize="xs" color="#eb0100">
                  {emptyNote}
                  {errorNote}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onPress={() => {
                      closeModalNote()
                    }}
                  >
                    {t('managerBookingDetail.notes.cancelButton')}
                  </Button>
                  <Button
                    colorScheme="yellow"
                    isLoading={noteLoading}
                    _loading={{
                      bg: 'amber.400:alpha.70',
                      _text: {
                        color: 'coolGray.700',
                      },
                    }}
                    _spinner={{
                      color: 'white',
                    }}
                    isLoadingText={t('submit_button.text')}
                    onPress={() => {
                      addNotesBooking()
                    }}
                  >
                    {t('managerBookingDetail.notes.saveButton')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </View>
      </View>
    )
  }
  return (
    <View>
      <AppBarHeader />
      <Center>
        <Image
          source={{ uri: errorImage }}
          alt="Alternate Text"
          style={{ width: 200, height: 200 }}
        />
        <Heading size="xs" style={[tw.fontMono]} mb="5">
          <Text> {t('errors.routeNotFound')}</Text>
        </Heading>
      </Center>
    </View>
  )
}
export default BookingDetail
