const Portuguese = {
  translation: {
    login: {
      title: 'Login',
      welcomeBack: 'Bem vindo de volta! Por favor, insira seus dados',
      rememberMe: 'Lembre-se de mim',
      notRegister: 'Não registrado ainda?',
      linkToRegister: 'Crie a sua conta aqui!',
      username: 'E-mail',
      userNameorEmail: '*E-mail',
      password: 'Senha',
      passwordInput: '*Senha',
      show: 'Mostrar',
      hide: 'Ocultar',
      signIn: 'Iniciar sessão',
      forgotPassword: 'Esqueceu a senha?',
      login: 'Login',
    },
    register: {
      title: 'Cadastro',
      subtitle: 'Por favor, insira seus dados.',
      userType: 'Selecione seu tipo de usuário *',
      helpedManager: 'Gerente de heliponto',
      helicopterOperator: 'Operador/Piloto de helicóptero',
      companyNameLabel: 'Nome da empresa',
      companyNamePlaceHolder: 'Nome da empresa',
      firstNameLabel: 'Nome',
      firstNamePlaceHolder: 'Nome',
      lastNameLabel: 'Sobrenome',
      lastNamePlaceHolder: 'Sobrenome',
      phoneNumberLabel: 'Número de telefone',
      phonenumberCodeSelect: 'Por favor selecione o DDI do seu país',
      phoneNumberPlaceHolder: 'Número de telefone',
      userNameLabel: 'E-mail',
      userNamePlaceHolder: 'E-mail',
      passwordLabel: 'Senha',
      passwordPlaceHolder: 'Senha',
      acceptTerms: 'Eu concordo com os seguintes',
      tos: 'Termos de serviço',
      show: 'Mostrar',
      hide: 'Ocultar',
      member: 'Já é um usuário?',
      signIn: 'Iniciar sessão!',
      registerButtonText: 'Cadastre-se',
      tosMessage: 'Aceite os termos e condições',
      type: 'Por favor, selecione seu tipo de usuário',
      required: 'Este campo é obrigatório.',
      onlyLetters: 'Inválido, apenas letras podem ser usadas.',
      lettersAndNumbers: 'Inválido, apenas letras e números',
      invalidValue: 'Valor inválido',
    },
    forgotPassword: {
      title: 'Esqueceu sua senha',
      enterEmailAddress: 'Insira o seu endereço de email',
      emailPlaceHolder: 'E-mail',
      send: 'Enviar',
    },
    changePassword: {
      title: 'Criar nova senha',
      enterEmailAddress: 'Insira o seu endereço de email',
      emailLabel: 'E-mail',
      emailPlaceHolder: 'Email',
      createPasswordButton: 'Criar senha',
    },
    changePasswordOperator: {
      change: 'Mudar senha',
      title: 'Por favor, crie sua nova senha',
      enterNewPassword: 'Por favor, digite sua nova senha',
      passwordInput: '*Senha',
      show: 'Mostrar',
      hide: 'Ocultar',
      cancel: 'Cancelar',
      save: 'Salve',
    },
    appBar: {
      pilotsAndOperator: 'PILOTOS E OPERADORES',
      operatorAccount: 'Conta do operador',
      fleet: 'Frota',
      pilots: 'Pilotos',
      profiles: 'Perfis',
      userProfile: 'Perfil de usuário',
      companyProfile: 'Perfil da empresa',
    },
    operatorForm: {
      title: 'Operador',
      information: 'Informações do operador',
      fillFields: 'Por favor preencha todos os campos',
      companyNameLabel: 'Nome da empresa',
      companyNamePlaceHolder: 'Nome da empresa',
      contactInformation: 'Informações de contato',
      nameLabel: 'Nome',
      namePlaceHolder: 'Nome',
      emailLabel: 'E-mail',
      emailPlaceHolder: 'E-mail',
      phoneNumberLabel: 'Número de telefone',
      phoneNumberPlaceHolder: 'Número de telefone',
      addressLabel: 'Endereço',
      addressPlaceHolder: 'Endereço',
      saveButton: 'Salvar',
    },
    operatorAccount: {
      title: 'Operador',
      information: 'Informações do operador',
      nameLabel: 'Nome',
      companyName: 'Nome da empresa',
      contactInformation: 'Informações de contato',
      contactName: 'Nome',
      contactEmail: 'E-mail',
      contactPhone: 'Número de telefone',
      contactAddress: 'Endereço',
      editButton: 'Editar',
      titleModal: 'Editar operador',
      informationModal: 'Informações do operador',
      companyNameLabel: 'Nome da empresa',
      companyNamePlaceholder: 'Nome da empresa',
      contactInformationModal: 'Informações de contato',
      nameModalLabel: 'Nome',
      nameModalPlaceholder: 'Nome',
      emailModalLabel: 'E-mail',
      emailModalPlaceholder: 'E-mail',
      phoneNumberModalLabel: 'Número de telefone',
      phoneNumberPlaceHolder: 'Número de telefone',
      addressModalLabel: 'Endereço',
      addressModalPlaceholder: 'Endereço',
      changePassword: 'Mudar senha',
      logOut: 'Sair',
      saveModalButton: 'Salvar',
      cancelModalButton: 'Cancelar',
      required: 'Isso é obrigatório.',
      onlyLetters: 'Somente letras',
      numbers: 'Somente letras',
      lettersAndNumbers: 'Somente letras e números',
      invalidValue: 'Valor inválido',
    },
    operatorAccountMenu: {
      info: 'Informações do operador',
      loginSecurity: 'Login e segurança',
      billing: 'Informações de faturamento',
    },
    operatorPayment: {
      paymentMethods: 'Métodos de pagamento',
      paymentHistory: 'Histórico de pagamento',
    },
    manageReservationOperator: {
      bookingTentative: 'Solicitado',
      bookingConfirmed: 'Confirmado',
      noBooking: 'Sem reservas',
      upcoming: 'Próximas reservas',
      past: 'Reservas anteriores',
      title: 'Reservas',
      upcomingBookings: {
        title: 'Reservas',
        upcomingBookings: 'Próximas reservas',
        reservation: {
          title: 'Reservas',
          date: 'Data:',
          start: 'Início:',
          end: 'Fim:',
        },
        customers: {
          title: 'Clientes',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumbers: 'Número da aeronave:',
        },
        status: {
          title: 'Estado',
          booking: 'Reserva:',
          payment: 'Pagamento:',
        },
        actions: {
          title: 'Ações',
          viewDetails: 'Ver detalhes',
        },
      },
      pastBookings: {
        title: 'Reservas canceladas',
        completed: 'Concluída',
        completedTitle: 'Reservas concluídas',
        reservation: {
          title: 'Reservas',
          date: 'Data:',
          start: 'Início:',
          end: 'Fim:',
        },
        customers: {
          title: 'Clientes',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumbers: 'Número da aeronave:',
        },
        actions: {
          title: 'Mais detalhes',
          viewDetails: 'Ver detalhes',
        },
        status: {
          title: 'Situação',
          booking: 'Reserva:',
          payment: 'Pagamento:',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rejeitado',
        },
        note: 'Nota:',
      },

      bookingOperatorDetail: {
        title: 'Detalhes da reserva',
        deleteButton: 'Excluir',
        rescheduleButton: 'Reagendar Reserva',
        cancelButton: 'Cancelar reserva',
        cancelModalTitle: 'Cancelar reserva',
        sureCancel: 'Tem certeza de que deseja cancelar?',
        returnReservation: 'Retornar à reserva',
        cancelModal: 'Cancelar',
        declinedBooking: 'Reserva recusada',
        reason: 'Motivo:',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rejeitado',
          completed: 'Concluído',
        },
        operator: 'Operador:',
        status: 'Status da reserva',
        booking: 'Reserva:',
        payment: 'Pagamento:',
        bookingDetail: 'Detalhes da reserva:',
        additional_field: 'Campo adicional',
        disembarking_passengers: 'Nomes dos passageiros desembarcando',
        email: 'E-mail',
        embarking_passengers: 'Nomes dos passageiros de embarque',
        next_destination: 'Próximo destino',
        origin_flight: 'Voo de origem',
        passengers_ids: 'Identificação dos passageiros',
        pilot_name: 'Nome do piloto',
        tail_number: 'Número da aeronave',
        timezone: 'Fuso horário',
        name: 'Nome',
        model: 'Marca/modelo da aeronave',
        certificate_number: 'Número do certificado do piloto',
        reason: 'Motivo:',
        error: 'Não encontramos sua reserva',
        flightNumber: 'Número do voo',
        totalPassenger: 'Número total de passageiros',
      },
    },
    methodsPayments: {
      notPayment: 'Você não tem métodos de pagamento',
      addPaymentMethod: 'Adicionar forma de pagamento',
      expiration: 'Expiração:',
      removePaymentMethod: 'Remover',
      setDefaultPaymentMethod: 'Definir como padrão',
      addPaymentMethodButton: 'Adicionar forma de pagamento',
      createPaymentMethod: 'Criar forma de pagamento',
      cardInformation: 'Informações do cartão',
      saveButton: 'Salvar',
    },
    userProfile: {
      title: 'Perfil de usuário',
      firstNameLabel: 'Nome',
      firstNamePlaceHolder: 'Nome',
      lastNameLabel: 'Sobrenome',
      lastNamePlaceHolder: 'Sobrenome',
      phoneNumberLabel: 'Número de telefone',
      phoneNumberPlaceHolder: 'Número de telefone',
      editButton: 'Editar',
      modalTitle: 'Editar perfil de usuário',
      firstNameModalLabel: 'Nome',
      firstNameModalPlaceHolder: 'Nome',
      lastNameModalLabel: 'Sobrenome',
      lastNameModalPlaceHolder: 'Sobrenome',
      phoneNumberModalLabel: 'Número de telefone',
      phoneNumberModalPlaceHolder: 'Número de telefone',
      editButtonSave: 'Salvar',
      changePassword: 'Alterar senha',
      logOut: 'Sair',
    },
    companyProfile: {
      title: 'Perfil da empresa',
      name: 'Nome da empresa',
      emailLabel: 'E-mail',
      phoneNumberLabel: 'Número de telefone',
      editButton: 'Editar',
      modalTitle: 'Editar perfil da empresa',
      companyNameModalLabel: 'Perfil da empresa',
      emailModalLabel: 'Nome do e-mail',
      phoneNumberModalLabel: 'Número de telefone',
      editButtonSave: 'Salvar',
      emails: 'E-mails da empresa',
      phones: 'Telefones da empresa',
      addEmails: 'Adicionar mais e-mails para notificações:',
      addPhones: 'Adicionar mais números de telefone para notificações:',
      none: 'Nenhum',
    }, // // here we will place our translations...
    appBar: {
      operatorMenu: {
        manageReservation: 'Gerenciar reservas',
        manageFleet: 'Gerenciar frota',
        managePilots: 'Gerenciar pilotos',
        account: 'Conta',
        logOut: 'Sair',
      },
      managerMenu: {
        manageReservation: 'Gerenciar reservas',
        manageHeliport: 'Gerenciar heliponto',
        manageCompany: 'Gerenciar empresa',
        account: 'Conta',
        logOut: 'Sair',
      },
      publicMenu: {
        register: 'Registrar',
        logIn: 'Log In',
      },
      language: {
        mexico: 'México',
        brazil: 'Brasil',
        usa: 'Estados Unidos',
      },
      languageItems: {
        mexico: 'Español',
        brazil: 'Português',
        usa: 'English',
      },
    },
    fleet: {
      required: 'Isso é obrigatório.',
      errorImage: 'Por favor, faça upload de imagem',
      noAircraft: 'Você não tem aeronaves',
      addAircraft: 'Adicionar aeronave',
      aircraftTitle: 'Aeronave',
      createAircraft: 'Criar aeronave',
      aircraftInformation: 'Informações da aeronave',
      tailNumber: 'Número da aeronave',
      model: 'Marca/modelo da aeronave',
      maxWeight: 'Peso máximo de decolagem',
      rotorLength: 'Comprimento do rotor',
      updateButton: 'Atualizar',
      removeButton: 'Remover',
      uploadButton: 'Upload de imagem',
      cancelButton: 'Cancelar',
      saveButton: 'Salvar',
      editButton: 'Editar',
      deleteButton: 'Excluir',
      editAircraftTitle: 'Editar aeronave',
      currentAircraftImage: 'Imagem atual da aeronave',
      deleteFleet: 'Excluir aeronave',
      confirmMessage: 'Você deseja excluir esta aeronave?',
      confirmButton: 'Confirmar',
      neverMindButton: 'Cancelar',
      errors: {
        create: 'Desculpe, não podemos criar sua aeronave.',
        update: 'Desculpe, não podemos atualizar sua aeronave.',
        delete: 'Desculpe, não podemos excluir sua aeronave.',
      },
      ruleNumber: 'Inválido, apenas números',
    },
    loading: {
      loading: 'Carregando',
    },
    homepage: {
      country: 'País',
      countryPlaceholder: 'País',
      city: 'Cidade',
      cityPlaceholder: 'Cidade',
      searchButton: 'Procurar',
      selectCity: 'Por favor, selecione uma cidade',
      reserve: 'Reservar',
      details: 'Detalhes',
      toTime: 'às',
      toDays: 'à',
      hours: 'horas',
      duration: 'Tempo de operação:',
      fee: 'Taxa:',
      featuredHelipads: 'Helipontos em destaque',
      notHelipads: 'Não existem helipontos',
      firstTitle: 'Há muito o que amar em Altaport.',
      secondTitle: 'Os desembarques são melhores com Altaport.',
      privacy: 'Privacidade',
      terms: 'Termos',
      siteMap: 'Mapa do site',
      firstMessage: {
        message: '"Eu achei o sistema super útil e bem',
        use: ' fácil de usar.”',
        hosting: 'Supervisora de hospedagem,',
      },
      secondMessage: {
        message: '“Uma plataforma que otimiza e',
        coordination: 'agiliza o trabalho da coordenação.”',
        operations: 'Operações de voo, Air Jet',
      },
      landingsMessage: {
        title: 'Os pousos são melhores com Altaport.',
        simplifiedMessage: {
          first: 'Gerenciamento',
          second: 'simplificado de',
          third: 'heliponto.',
          minimizingManual:
            'Minimizando as ações manuais necessárias para agendar ',
          passengers: 'pousos gerenciar passageiros e processar pagamentos.',
        },
        centralizedMessage: {
          first: 'Reservas',
          second: 'centralizadas de',
          third: 'heliponto.',
          offering:
            'Oferecendo aos operadores de helicópteros uma experiência ',
          helipad: ' simplificada para agendamento e pagamentos do heliponto.',
        },
        highVolumeMessage: {
          first: 'Operações em',
          second: 'escala dos',
          third: 'vertiports.',
          enabling: 'Possibilitando o futuro das operações autônomas de drones',
          operations: 'e táxi aéreo de alto volume.',
        },
      },
      free: 'Gratuitamente',
    },
    pilots: {
      required: 'Isso é obrigatório.',
      editButton: 'Editar',
      deleteButton: 'Excluir',
      editTitle: 'Editar piloto',
      currentPilotImage: 'Imagem do piloto atual',
      updateButton: 'Atualizar',
      removeButton: 'Remover',
      uploadImage: 'Upload de imagem',
      pilotInformation: 'Informações do piloto',
      certificateNumber: 'Número do certificado do piloto',
      firstName: 'Primeiro nome',
      lastName: 'Sobrenome',
      phone: 'Número de telefone',
      email: 'E-mail',
      cancelButton: 'Cancelar',
      saveButton: 'Salvar',
      imageError: 'Por favor, faça upload de imagem',
      noPilots: 'Você não tem pilotos',
      addPilotButton: 'Adicionar piloto',
      pilotsTitle: 'Pilotos',
      createPilot: 'Criar piloto',
      deletePilot: 'Excluir piloto',
      confirmMessage: 'Você deseja excluir este piloto?',
      confirmButton: 'Confirmar',
      neverMindButton: 'Cancelar',
      errors: {
        create: 'Desculpe, não podemos criar seu piloto.',
        update: 'Desculpe, não podemos atualizar seu piloto.',
        delete: 'Desculpe, não podemos excluir seu piloto.',
      },
    },
    reservations: {
      title: 'Reservas',
      addReservationsManually: 'Adicionar reserva',
      blockTimes: 'Bloquear horários',
      listView: 'Visualização em lista',
      calendarView: 'Visualização de calendário',
      bookingBlockTimes: 'Horários de bloqueio de reservas',
      startDateHour: 'Data e hora de início',
      endDateHour: 'Data e hora de término',
      cancelButton: 'Cancelar',
      saveButton: 'OK',
      errorAccount: 'Erro de conta',
      reminder: 'Lembrete',
      reminderMessage:
        'Lembre-se de preencher seus dados bancários para poder fazer reservas, você pode fazê-lo a partir da conta de administrador na opção Informações bancárias',
    },
    calendarView: {
      deleteButton: 'Excluir',
      accept: 'Aceitar',
      reject: 'Rejeitar',
      declineBooking: 'Recusar reserva',
      reason: 'Motivo:',
      cancel: 'Cancelar',
      save: 'Salvar',
      cancelReservation: 'Cancelar reserva',
      tentative: 'Solicitado',
      confirmed: 'Confirmado',
      cancelledCustomer: 'Cancelado',
      cancelled: 'Cancelado',
      declined: 'Rejeitado',
      completed: 'Concluído',
      approved: 'Aprovado',
      pendingApproval: 'Aprovação pendente',
      completed: 'Concluído',
      blockPeriod: 'Horário bloqueado',
      cancelled: 'Cancelado',
      unPaidInvoice: 'Não pago - fatura',
      paidAuthorized: 'Pago - autorizado',
      paidCharged: 'Pago - cobrado',
      unPaid: 'Não pago',
      booking: 'Reservas',
      notBooking: 'Sem Reservas',
      month: 'Mês',
      day: 'Dia',
      week: 'Semana',
      block: 'Bloquear',
      blockedTime: 'Horário bloqueado',
      bookingModal: 'Reserva',
      operator: 'Operador:',
      tailNumber: 'Número da aeronave:',
      status: 'Estado:',
      payment: 'Pagamento:',
      viewDetails: 'Ver Detalhes',
      manualReservation: 'Reserva manual',
      locale: 'pt',
    },
    heliportDetailsOperator: {
      latitude: 'Lat',
      longitude: 'Long',
      description: 'Descrição',
      helipad: 'Heliponto',
      helipadDimensions: 'Dimensões do heliponto',
      location: 'Localização',
      helipadDetails: 'Detalhes do heliponto',
      availability: 'Disponibilidade',
      landing: 'de slots de pouso',
      capacity: 'Capacidade de helipontos:',
      fuel: 'Disponível',
      notFuel: 'Não disponível',
      yes: 'Sim',
      no: 'Não',
      restrictions: 'Restrições',
      cancellation: 'Política de cancelamento:',
      otherRestrictions: 'Outras restrições:',
      fees: 'Taxas',
      landingFee: 'Taxa de pouso',
      parkingFee: 'Taxa de estacionamento',
      hourlyRate: 'Taxa horária',
      overnightRate: 'Taxa de pernoite',
      selectLanding: 'Selecione um horário de pouso',
      reserveLanding: 'Reservar hora de pouso',
      standar: 'Horário padrão',
      helipadManager: 'Gerente de heliponto',
      makeReservation: 'Fazer reserva',
      map: 'Mapa',
      surfaceType: 'Superfície',
      helipads: 'Helipontos',
      notParking: 'Sem estacionamento disponível',
      maxWeight: 'Peso máximo de decolagem:',
      helipadDimensions: 'Dimensões do heliponto:',
      fuelAvailability: 'Disponibilidade de combustível: ',
      helipadsNumber: 'Helipontos: ',
      typeSurface: 'Tipo de superfície: ',
      minutes: 'minuto(s)',
      hours: 'hora(s)',
      otherRestrictions: 'Outras Restrições',
      none: 'Nenhum',
    },
    managerBooking: {
      upcomingBookings: 'Próximas reservas',
      pastBookings: 'Reservas anteriores',
      bookingPendingConfirm: {
        title: 'Reservas',
        noBooking: 'Sem Reservas',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rejeitado',
          completed: 'Concluído',
        },

        confirmedRequests: {
          title: 'Solicitações confirmadas',
          reservation: 'Reserva',
          date: 'Data:',
          start: 'Início:',
          end: 'Fim:',
          customer: 'Cliente',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumber: 'Número da aeronave:',
          operation: 'Operação:',
          embarking: 'Embarque',
          disembarking: 'Desembarque',
          passengerName: 'Nomes dos passageiros:',
          status: 'Estado:',
          booking: 'Reserva:',
          payment: 'Pagamento:',
          actions: 'Ações',
          viewDetails: 'Ver detalhes',
          cancelButton: 'Cancelar',
        },
        pendingRequests: {
          title: 'Solicitações pendentes',
          reservation: 'Reserva',
          date: 'Data:',
          start: 'Início:',
          end: 'Fim:',
          customer: 'Cliente',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumber: 'Número da aeronave:',
          operation: 'Operação:',
          embarking: 'Embarque',
          disembarking: 'Desembarque',
          passengerName: 'Nomes dos passageiros:',
          status: 'Estado:',
          booking: 'Reserva:',
          payment: 'Pagamento:',
          actions: 'Ações',
          viewDetails: 'Ver detalhes',
          acceptButton: 'Aceitar',
          rejectButton: 'Rejeitar',
          declineBooking: 'Recusar reserva',
          reason: 'Motivo:',
          cancel: 'Cancelar',
          save: 'Salvar',
        },
        canceledByTime: 'Reserva cancelada, por exceder o tempo do pedido',
        errors: {
          decline: 'Desculpe, não podemos recusar sua reserva.',
          cancel: 'Erro ao cancelar a reserva!',
        },
        manualReservation: 'Reservas Manuais',
      },
      cancelCompletedRequests: {
        cancelled: 'Reservas canceladas',
        completed: 'Reservas concluídas',
        reservation: 'Reserva',
        date: 'Data:',
        start: 'Início:',
        end: 'Fim:',
        customer: 'Cliente',
        operator: 'Operador:',
        aircraftType: 'Tipo de aeronave:',
        tailNumber: 'Número da aeronave:',
        status: 'Estado:',
        booking: 'Reserva:',
        payment: 'Pagamento:',
        actions: 'Ações',
        viewDetails: 'Ver detalhes',
        deleteButton: 'Deletar',
        acceptButton: 'Aceitar',
        rejectButton: 'Rejeitar',
        declineBooking: 'Recusar reserva',
        reason: 'Motivo:',
        cancel: 'Cancelar',
        save: 'Salvar',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rejeitado',
          completed: 'Concluído',
        },
        note: 'Observação:',
        errorPayment: 'Erro no pagamento',
        exceedingReservation:
          'Reserva cancelada, por exceder o tempo do pedido',
      },
    },
    managerBookingDetail: {
      deleteButton: 'Excluir',
      acceptButton: 'Aceitar',
      rejectButton: 'Rejeitar',
      proposeButton: 'Propor um novo horário de reserva',
      rescheduleBooking: 'Reagendar Reserva',
      declineBooking: 'Recusar reserva',
      reason: 'Motivo:',
      cancel: 'Cancelar',
      save: 'Salvar',
      editDateTime: 'Editar data e hora',
      cancelReservation: 'Cancelar reserva',
      editBooking: 'Editar Reserva',
      landingDateTime: 'Data e hora de pouso',
      start: 'Início:',
      end: 'Fim:',
      additionalfield: 'Campo adicional',
      disembarkingPassengers: 'Passageiro(s) desembarcando',
      email: 'E-mail:',
      embarkingPassengers: 'Passageiro(s) embarcando',
      operationEmbarking: 'Operação: embarque',
      operationDisembarking: 'Operação: desembarque',
      nextDestination: 'Próximo destino',
      originFlight: 'Voo de origem',
      passengersIds: 'Identificação dos passageiros:',
      pilotName: 'Nome do piloto',
      tailNumber: 'Número da aeronave',
      timezone: 'Fuso horário:',
      name: 'Nome:',
      model: 'Marca/Modelo da aeronave:',
      certificateNumber: 'Número do certificado do piloto',
      copilotName: 'Nome do co-piloto',
      certificateNumberCopilot: 'Número do certificado do co-piloto',
      bookingDetail: 'Detalhe da reserva',
      operatorContact: 'Contato do operador:',
      operator: 'Operador',
      phone: 'Telefone',
      email: 'E-mail',
      status: 'Status da reserva',
      booking: 'Reserva:',
      payment: 'Pagamento',
      bookingDetails: 'Detalhes da reserva:',
      editBooking: 'Editar detalhes da reserva',
      declinedBooking: 'Reserva recusada',
      reason: 'Motivo',
      bookingStatus: {
        tentative: 'Solicitado',
        confirmed: 'Confirmado',
        cancelledCustomer: 'Cancelado',
        cancelled: 'Cancelado',
        declined: 'Rejeitado',
        completed: 'Concluído',
      },
      actualArrival: 'Hora de pouso real',
      actualDeparture: 'Hora de decolagem real',
      enterActualArrival: 'Insira a hora de chegada real',
      enterActualDeparture: 'Insira a hora de partida real',
      flightNumber: 'Número do voo',
      notes: {
        addNoteTitle: 'Adicionar comentários',
        editNoteTitle: 'Editar comentários',
        note: 'Comentários:',
        errorNote: 'Nos desculpe, não podemos adicionar o comentário',
        cancelButton: 'Cancelar',
        saveButton: 'Salvar',
        managerNote: 'Comentários do gerente de heliponto',
        operatorNote: 'Comentários do operador',
        emptyNote: 'Você deve adicionar um comentário',
      },
      totalPassenger: 'Número total de passageiros',
    },
    editHeliport: {
      identifier: 'Identificador de heliponto',
      name: 'Nome do heliponto',
      code: 'CÓDIGO ICAO',
      contactInformation: 'Informações de contato',
      managerName: 'Nome do gerente',
      phone: 'Número de telefone',
      location: 'Localização',
      city: 'Cidade',
      state: 'Estado',
      address: 'Endereço',
      latitude: 'Latitude',
      longitude: 'Longitude',
      timezone: 'Fuso horário',
      availability: 'Disponibilidade',
      timeslot: 'Slot para agendamentos',
      hours: 'hora(s)',
      minutes: 'minuto(s)',
      days: 'dia(s)',
      numberHelipads: 'Número de helipontos (capacidade)',
      fuel: 'Combustível disponível?',
      yes: 'Sim',
      no: 'Não',
      reservation: 'Tipo de reserva',
      automatic: 'Automático',
      manual: 'Aprovação manual',
      restrictions: 'Restrições',
      size: 'Limite de tamanho',
      weight: 'Limite de peso',
      maximumRotor: 'Dimensões do heliponto',
      surface: 'Tipo de superfície',
      otherRestrictions: 'Observações',
      cancellation: 'Janela de cancelamento',
      fees: 'Taxas',
      landingFee: 'Taxa de desembarque',
      currency: 'Moeda do heliponto',
      parkingCurrency: 'Moeda',
      real: 'Real',
      usd: 'USD',
      mexican: 'Peso mexicano',
      parkingAvailable: 'Estacionamento disponível?',
      editParking: 'Estacionamento',
      parkingHour: 'Taxa horária',
      parkingNight: 'Pernoite',
      description: 'Descrição do heliponto',
      saveButton: 'SALVAR',
      error: 'Não podemos atualizar seu heliponto',
      fee: 'Taxa',
      limitLateBooking: 'Limitar reserva tardia',
    },
    managerAccount: {
      menu: {
        userProfile: 'Perfil de usuário',
        loginSecurity: 'Login e segurança',
        transactions: 'Transações e pagamentos',
      },
      payoutHistory: 'Histórico de pagamentos',
      transactionHistory: 'Histórico de transações',
      bank: 'Informações bancárias',
      noPayment: 'Você não tem pagamentos',
      payout: 'Pagamento',
      chaseChecking: 'Verificando...',
      routingNumber: 'Número de roteamento:',
      changePayout: 'Alterar forma de pagamento',
      verifyAccount: 'Verificar conta',
      createBank: 'Criar informações bancárias',
    },
    manageHeliport: {
      latitude: 'Latitude: ',
      longitude: 'Longitude: ',
      description: 'Descrição',
      helipad: 'Heliponto',
      helipadDimensions: 'Dimensões do heliponto',
      manager: 'Gerente',
      phone: 'Telefone: ',
      name: 'Nome: ',
      email: 'Email: ',
      address: 'Endereço',
      companyInformation: 'Informações da empresa',
      companyName: 'Nome: ',
      companyPhone: 'Telefone: ',
      state: 'Estado: ',
      maxWeight: 'Peso máximo de decolagem:',
      helipadDimensions: 'Dimensões do heliponto:',
      cancellation: 'Janela de cancelamento:',
      editHeliport: 'Editar Heliponto',
      addReservationsManually: 'Adicionar reserva manualmente',
      editImage: 'Edit Imagem',
      currentImage: 'Imagem atual',
      updateButton: 'Atualizar',
      removeButton: 'Remover',
      uploadImage: 'Upload de imagem',
      cancel: 'Cancelar',
      save: 'Salvar',
      editAviabilities: 'Editar disponibilidades do heliponto',
      manageHelipad: {
        notHelipads: 'Você não tem helipontos',
        addHelipad: 'ADICIONAR ÁREA DE POUSO',
        editHelipad: 'EDITAR ÁREA DE POUSO',
        delete: 'EXCLUIR',
        createHelipadTitle: 'Criar heliponto',
        helipadInformation: 'Informações do heliponto',
        name: 'Nome',
        required: 'Isso é obrigatório.',
        timezone: 'Fuso horário',
        editHelipadTitle: 'Editar heliponto',
        createHelipad: 'CRIAR HELIPONTO',
        saveButton: 'Salvar',
        tryAgain: 'Erro, por favor tente outra vez!',
        errors: {
          create: 'Desculpe, não podemos criar seu heliponto.',
          delete: 'Desculpe, não podemos excluir seu heliponto.',
          edit: 'Desculpe, não podemos editar seu heliponto.',
          deleteErrorServer:
            'O heliponto não pode ser excluído porque possui reservas pendentes',
        },
      },
      reminder: 'Lembrete',
      reminderMessage:
        'Lembre-se que você deve preencher as informações do heliponto para fazer uma reserva, você pode fazê-lo em "editar heliponto"',
      reminderAccept: 'Aceitar',
      country: {
        mexico: 'México',
        brazil: 'Brasil',
        usa: 'USA',
      },
    },
    submit_button: {
      text: 'Carregando',
    },
    try_again: {
      text: 'Erro, por favor tente outra vez!',
    },
    heliportForm: {
      required: 'Isso é obrigatório.',
      identifier: 'Identificador de heliponto',
      name: 'Nome do heliponto',
      code: 'CÓDIGO ICAO',
      contactInformation: 'Informações de contato',
      managerName: 'Nome do gerente',
      firstName: 'Primeiro nome',
      lastName: 'Sobrenome',
      phone: 'Número profissional',
      location: 'Localização',
      city: 'Cidade',
      state: 'Estado',
      field: 'Campo para 2 caracteres (por exemplo, SP)',
      address: 'Endereço',
      latitude: 'Latitude',
      numbers: 'Somente números',
      longitude: 'Longitude',
      timezone: 'Fuso horário',
      country: 'País',
      availability: 'Disponibilidade',
      timeslot: 'Faixa de tempo para pousos',
      hours: 'Hora(s)',
      hour: 'Hora',
      night: 'Noite',
      minutes: 'Minuto(s)',
      days: 'Dia(s)',
      numberHelipads: 'Número de helipontos (capacidade)',
      numberHelipadsLabel: 'Número de helipontos',
      fuel: 'Combustível disponível?',
      yes: 'Sim',
      no: 'Não',
      reservation: 'Tipo de reserva',
      automatic: 'Automático',
      manual: 'Aprovação Manual',
      restrictions: 'Restrições',
      size: 'Limite de tamanho',
      weight: 'Peso',
      maximumRotor: 'Dimensões do heliponto',
      maximumRotorLabel: 'Dimensões',
      surface: 'Tipo de superfície',
      aspalthLabel: 'Asfalto',
      aspalth: 'e.g., Asfalto',
      otherRestrictions: 'Outras restrições',
      noAvailables: 'Não disponível no fim de semana',
      limitLate: 'Antecedência mínima para reservas',
      cancellation: 'Janela de cancelamento',
      before: 'Antes',
      flight: 'do voo',
      fees: 'Taxas',
      heliportCurrency: 'Moeda do heliponto',
      currency: 'Moeda',
      real: 'Real',
      usd: 'USD',
      mexican: 'Peso mexicano',
      parkingHour: 'Taxa horária',
      parkingNight: 'Pernoite',
      description: 'Descrição do heliponto',
      descriptionLabel: 'Uma pequena descrição do heliponto',
      uploadImage: 'Fazer upload de imagens',
      update: 'Atualizar',
      remove: 'Remover',
      requiedReservation: 'Detalhes de reserva obrigatórios',
      disembarkingPassengers: 'Nomes dos passageiros desembarcando',
      embarkingPassengers: 'Nomes de passageiros de embarque',
      originFlight: 'Origem do voo',
      nextDestination: 'Destino do voo',
      passengerIDs: 'ID do passageiro (por exemplo, passaporte)',
      accept: 'Concordo',
      servicesAgreement: 'Contrato de serviços Altaport',
      step: 'Etapa',
      createHeliport: 'CRIAR HELIPONTO',
      parkingAvailable: 'Estacionamento disponível ?',
      acceptTos: 'Aceite os termos de serviço',
      addressField: 'O campo de endereço é obrigatório',
      imagesField: 'Imagens obrigatórias',
    },
    managerReports: {
      reports: 'Relatórios',
      enterDate: 'Por favor, insira a data inicial e a data final',
      startDate: 'Data inicial',
      endDate: 'Data final',
      startError: 'Insira a data inicial',
      downloadReport: 'Download',
      downloadLoading: 'Carregando...',
    },
    bookingOperatorForm: {
      with: 'em',
      disembarkingPassengers: 'Desembarque de Passageiros',
      embarkingPassengers: 'Embarque de passageiros',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      document: 'Documento',
      passport: 'Passaporte',
      driversLicense: 'Carteira de motorista',
      originFlight: 'Voo de origem',
      nextDestination: 'Próximo destino',
      required: 'Isso é necessário.',
      selectPilot: 'Selecionar piloto',
      selectCopilot: 'Selecionar co-piloto',
      addPilot: 'Adicionar piloto',
      selectAircraft: 'Selecionar aeronave',
      addAircraft: 'Adicionar aeronave',
      back: 'Voltar',
      confirmReservation: 'Confirmar reserva',
      nextPayment: 'Próximo: Detalhes de pagamento',
      repeatedPilot: 'Você não pode selecionar o mesmo piloto',
      flightNumber: 'Número do voo',
      totalPassenger: 'Número total de passageiros',
    },
    bookingMannualyForm: {
      with: 'no',
      name: 'Nome',
      email: 'E-mail',
      phone: 'Número de telefone',
      disembarkingPassengers: 'Desembarque de passageiros',
      embarkingPassengers: 'Embarque de passageiros',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      document: 'Documento',
      passport: 'Passaporte',
      driversLicense: 'Carteira de motorista',
      originFlight: 'Voo de origem',
      nextDestination: 'Próximo destino',
      required: 'Isso é necessário.',
      pilotName: 'Piloto',
      pilotCertificate: 'Número do certificado de piloto',
      helicopterModel: 'Marca/modelo da aeronave',
      helicopterTail: 'Número da aeronave',
      back: 'Voltar',
      confirmReservation: 'Confirmar reserva',
      required: 'Este campo é obrigatório.',
      onlyLetters: 'Inválido, apenas letras podem ser usadas.',
      lettersAndNumbers: 'Inválido, apenas letras e números',
      invalidValue: 'Valor inválido',
      totalPassenger: 'Número total de passageiros',
    },
    paymentMethods: {
      errorMonthlyInvoices:
        'Desculpe, você só pode usar faturas mensais com helipontos da mesma moeda.',
      errorReservation: 'Desculpe, não podemos fazer sua reserva',
      creditCard: 'Cartão de crédito',
      boleto: 'Boleto',
      monthlyInvoice: 'Fatura mensal',
      fastPass: 'Passe rápido',
      back: 'Voltar',
      selectOtherMethod: 'Por favor, escolha outro método de pagamento',
      passCode: {
        title: 'Código de acesso rápido',
        introduceCode: 'Por favor, apresente seu código',
        code: 'Código:',
        required: 'Isso é obrigatório.',
        cancel: 'Cancelar',
        send: 'Enviar',
      },
    },
    paymentSummary: {
      errorBooking: {
        title: 'Erro ao reservar!',
        message: 'Sua reserva não foi recebida, entre em contato conosco',
      },
      bookingReceived: {
        title: 'Reserva recebida!',
        yourBooking: 'Sua reserva foi recebida',
        paymentsDetails: 'Detalhes de pagamento',
        amount: 'Valor:',
        billingDetails: 'Detalhes de cobrança',
        email: 'E-mail:',
        phone: 'Telefone:',
        paymentMethod: 'Método de pagamento',
        type: 'Tipo:',
        viewReservations: 'Ver reservas',
        promotionalCode: 'Código promocional',
      },
      bookingDetailPayment: {
        bookingDetails: 'Detalhes da reserva',
        additionalField: 'Campo adicional',
        disembarkingPassengers: 'Nomes de passageiros de desembarque',
        email: 'E-mail',
        embarkingPassengers: 'Nomes de passageiros de embarque',
        nextDestination: 'Próximo destino',
        originFlight: 'Voo de origem',
        passengersIds: 'Documentação dos passageiros',
        pilotName: 'Nome do piloto',
        tailNumber: 'Número da aeronave',
        timezone: 'Fuso horário',
        name: 'Nome',
        totalPassenger: 'Número total de passageiros',
      },
    },
    editAvailability: {
      title: 'Editar Disponibilidade',
      day: 'Dia',
      hour: 'Horas',
      price: 'Preço',
      approval: 'Aprovação',
      sunday: 'Domingo',
      saturday: 'Sábado',
      friday: 'Sexta',
      thursday: 'Quinta',
      wednesday: 'Quarta',
      tuesday: 'Terça',
      monday: 'Segunda',
      automatic: 'Automática',
      manual: 'Manual',
      start: 'Início:',
      end: 'Fim:',
      save: 'Salve',
      editButton: 'Editar',
      deleteButton: 'Excluir',
      add: 'Adicionar',
      noAvailability: 'sem disponibilidade',
      none: 'Nenhum',
      actions: 'Ações',
      buttons: {
        edit: 'Salvar',
        save: 'Salvar',
      },
      errorSave: 'Não foi possível salvar seus dias e horas.',
      errorEdit: 'Não foi possível editar seus dias e horas.',
    },
    errors: {
      bookings: 'Desculpe, não podemos mostrar suas reservas.',
      completeInformation: 'Por favor, preencha o formulário de heliponto.',
      errorHeliportForm: 'Formulário de heliponto',
      createHeliport: 'Desculpe, não podemos criar seu heliponto.',
      save: 'Desculpe, não foi possível salvar as disponibilidades do heliponto.',
      routeNotFound: 'Desculpe, não encontramos o que você está procurando.',
      heliportNotFound: 'Desculpe, não conseguimos encontrar o heliponto.',
      acceptBooking: 'Desculpe, não podemos aceitar sua reserva.',
      cancelBooking: 'Desculpe, não podemos cancelar sua reserva',
      declineBooking: 'Desculpe, não podemos recusar sua reserva',
      editBooking: 'Desculpe, não podemos editar sua reserva',
      userProfile:
        'Desculpe, não foi possível encontrar o perfil que você está procurando',
      connectAccount: 'Erro ao conectar conta',
      errorValidEmail: 'Todos os emails devem ser válidos',
    },
    changePassword: {
      title: 'Criar nova senha',
      introduce: 'Por favor, digite sua nova senha',
      button: 'Enviar',
    },
    paymentHistorial: {
      emptyPayments: 'Você não tem pagamentos.',
      paymentHistorialTitle: 'Histórico de pagamentos',
    },
    errorManager: {
      completeInformation: 'Por favor, complete as informações do heliponto.',
      message:
        'Você deve criar Heliponto em Gerenciar meu heliponto ou criar aviabilidades de heliponto.',
      button: 'Gerenciar meu heliponto.',
      cancel: 'Cancelar',
    },
    paymentStatus: {
      unPaidInvoice: 'Não pago - fatura',
      paidAuthorized: 'Em processamento',
      paidCharged: 'Pago',
      unPaidInvoice: 'Não pago',
      manualReservation: 'Não pago - reserva manual',
      paidCancel: 'Cancelado',
      paidCode: 'Código promocional',
      succeeded: 'Aprovado',
      canceled: 'Cancelado',
      requireCapture: 'Em processamento',
      payment: 'Pago',
    },
    time: {
      days: 'dia(s)',
      minutes: 'minuto(s)',
      hours: 'hora(s)',
    },
    country: {
      brazil: 'Brasil',
      usa: 'USA',
      mexico: 'Mexico',
    },
    errorPhoneNumber: 'Não é válido para o código do país selecionado',
    errorsPassword: {
      minLength: 'Pelo menos 8 caracteres.',
      specialChar: 'Pelo menos um caractere especial.',
      number: 'Pelo menos um número.',
      capital: 'Pelo menos uma letra maiúscula.',
      lowercase: 'Pelo menos uma letra minúscula.',
    },
    invoice: {
      title: 'Fatura',
      daysInvoice: 'Número de dias para pagar a fatura',
      sendInvoice: 'Enviar fatura automaticamente com o pagamento.',
      monthlyInvoice: 'Enviar faturas todo início do mês.',
      halfMonthlyInvoice: 'Enviar faturas a cada metade do mês',
    },
    west: 'O',
    proposeNewDate: {
      title: 'Tem certeza de que deseja reagendar a reserva?',
      changeTo: 'Alterar a reserva para:',
      date: 'Data: ',
      startHour: 'Início:',
      endHour: 'Fim: ',
      acceptButton: 'Aceitar',
      cancel: 'Cancelar',
    },
  },
}
export default Portuguese
