import { gql } from '@apollo/client'
export const GET_COMPANY = gql`
  query MyQuery2($userId: uuid) {
    users_companies(where: { user_id: { _eq: $userId } }) {
      company {
        company_profile {
          company_name
          email
          id
          phone
          created_at
        }
        id
        email_users {
          company_id
          email
          id
        }
        phone_users {
          id
          company_id
          phone_number
        }
      }
    }
  }
`
export const GET_OPERATOR = gql`
  query getOperator($userId: uuid) {
    operators(where: { user_id: { _eq: $userId } }) {
      id
      operator_company_name
      operator_name
      operators_contact {
        address
        email
        id
        name
        phone
      }
      operator_emails {
        id
        email
        operator_id
      }
      operator_phones {
        id
        phone_number
        operator_id
      }
    }
  }
`
export const GET_USER_PROFILE = gql`
  query GetUserProfile($_eq: uuid) {
    profile(where: { user_id: { _eq: $_eq } }) {
      first_name
      id
      last_name
      phone_number
      user_id
    }
  }
`
export const GET_FLEET = gql`
  query GetFleet($userId: uuid) {
    fleet(where: { operator: { user_id: { _eq: $userId } } }) {
      id
      aircrafts {
        fleet_id
        helicopter_makes_models
        id
        mtow
        tail_numbers
        weight_measure_type
        rotor_length
        rotor_measure_type
        aircraft_image {
          id
          file_id
        }
        aircraft_image_id
      }
    }
  }
`

export const GET_PILOTS = gql`
  query GetPilots($userId: uuid) {
    pilots(where: { operator: { user_id: { _eq: $userId } } }) {
      certificate_number
      email
      id
      first_name
      last_name
      phone_number
      pilot_image_id
      pilot_image {
        file_id
      }
    }
  }
`

export const GET_PILOT_IMAGE = gql`
  query getPilotImage($id: uuid!) {
    pilot_image_by_pk(id: $id) {
      id
      file_id
    }
  }
`

export const GET_USER_INFO = gql`
  query GetUserInfo($id: uuid!) {
    users_by_pk(id: $id) {
      id
      users_companies {
        company_id
      }
    }
  }
`
export const GET_HELIPADS = gql`
  query heliports($userId: uuid) {
    heliports(
      where: { company: { users_companies: { user_id: { _eq: $userId } } } }
    ) {
      id
      heliport_profile {
        timekit_project_id
        landing_slot_length
        helipads_number
        reservations
        size_limit_weigth
        measure_limit_weigth
        measure_rotor_diameter
        other_restrictions
        cancellation_policy
        landing_fee
        heliport_description
        latitude
        longitude
        heliport_currency
        limit_late_booking
        surface_type
      }
      helipads {
        id
        name
        timekit_resource_id
        helipad_image_id
        helipad_image {
          file_id
        }
      }
    }
  }
`
export const GET_HELIPORT = gql`
  query MyQuery($id: uuid!) {
    heliports_by_pk(id: $id) {
      id
      heliport_profile {
        address
        cancellation_policy
        city
        created_at
        email
        fuel_available
        helipads_number
        heliport_description
        heliport_id
        hourly_rate
        icao_code
        id
        landing_fee
        landing_slot_length
        latitude
        longitude
        manager_name
        name
        other_restrictions
        overnight_rate
        parking_fee
        phone
        reservations
        size_limit_weigth
        state
        timezone
        timezone_number
        timekit_project_id
        country
        measure_limit_weigth
        measure_rotor_diameter
        heliport_image_id
        surface_type
      }
      helipads {
        id
        name
        timekit_resource_id
        heliport_id
        created_at
      }
      company {
        id
      }
    }
  }
`
export const GET_HELIPORT_ISHIGHLIGHTED = gql`
  query heliports_highlighted($isHighlighted: Boolean) {
    heliports(where: { is_highlighted: { _eq: $isHighlighted } }) {
      id
      is_highlighted
      heliport_profile {
        city
        name
        heliport_image {
          id
          file_id
        }
        icao_code
      }
    }
  }
`
export const GET_HELIPORT_BY_COUNTRY_AND_CITY = gql`
  query heliport_by_country_by_city($country: String, $city: String) {
    heliports(
      where: {
        helipads: { id: { _is_null: false } }
        heliport_profile: {
          _and: [{ country: { _eq: $country } }, { city: { _similar: $city } }]
          heliport_aviabilities: { id: { _is_null: false } }
        }
      }
    ) {
      id
      is_highlighted
      publish
      created_at
      heliport_profile {
        name
        city
        country
        heliport_currency
        state
        icao_code
        address
        landing_fee
        landing_slot_length
        timekit_project_id
        heliport_image_id
        heliport_image {
          file_id
          id
        }
        heliport_aviabilities {
          day
          start_hour
          end_hour
          price
        }
      }
      company {
        id
      }
    }
  }
`
export const GET_HELIPORT_MANAGER = gql`
  query heliport($userId: uuid) {
    heliports(
      where: { company: { users_companies: { user_id: { _eq: $userId } } } }
    ) {
      id
      company {
        id
        account_stripe_id
        company_profile {
          company_name
          email
          phone
        }
      }
      heliport_profile {
        address
        cancellation_policy
        city
        created_at
        email
        fuel_available
        helipads_number
        heliport_description
        heliport_id
        hourly_rate
        icao_code
        id
        landing_fee
        landing_slot_length
        latitude
        longitude
        manager_name
        name
        other_restrictions
        overnight_rate
        parking_fee
        phone
        reservations
        size_limit_weigth
        state
        timezone
        timekit_project_id
        country
        measure_limit_weigth
        measure_rotor_diameter
        heliport_image_id
        heliport_currency
        limit_late_booking
        surface_type
        parking_available
        overnight_rate_currency
        hourly_rate_currency
        heliport_aviabilities {
          day
          end_hour
          currency
          booking_graph
          price
          start_hour
          id
        }
        dimensions_limit
        days_until_due
      }
    }
  }
`
export const GET_HELIPORTS_CITIES = gql`
  query heliport_by_cities($country: String) {
    heliports(
      where: {
        heliport_profile: {
          country: { _eq: $country }
          heliport_aviabilities: { id: { _is_null: false } }
        }
        helipads: { id: { _is_null: false } }
        company: { account_stripe_id: { _is_null: false } }
      }
    ) {
      id
      heliport_profile {
        id
        city
        icao_code
      }
    }
  }
`
export const GET_OPERATOR_CONTACT = gql`
  query operator_contact_by_id($id: uuid!) {
    operators_by_pk(id: $id) {
      id
      operator_name
      operators_contact {
        address
        email
        id
        name
        phone
      }
    }
  }
`
export const GET_MANAGERS_BY_PROJECT = gql`
  query company_managers($id: uuid!) {
    company_by_pk(id: $id) {
      id
      account_stripe_id
      users_companies {
        user_id
      }
      heliports {
        heliport_profile {
          id
          heliport_image {
            id
            file_id
          }
          reservations
          timekit_project_id
          timezone
          heliport_aviabilities {
            booking_graph
            created_at
            currency
            day
            end_hour
            id
            price
            start_hour
          }
          heliport_currency
          phone
          days_until_due
          country
        }
        id
        helipads {
          heliport_id
        }
      }
      email_users {
        email
        id
        company_id
      }
      phone_users {
        id
        phone_number
        company_id
      }
    }
  }
`
export const GET_COMPANY_MANAGERS = gql`
  query managers_users($userId: uuid) {
    company(where: { users_companies: { user_id: { _eq: $userId } } }) {
      users_companies {
        user_id
      }
      id
      account_stripe_id
      heliports {
        id
        heliport_profile {
          id
          timezone
          timekit_project_id
        }
      }
    }
  }
`

export const GET_HELIPORT_BY_COMPANY = gql`
  query heliport_by_company($id: uuid!) {
    company_by_pk(id: $id) {
      heliports {
        heliport_profile {
          country
          heliport_currency
          heliport_aviabilities {
            price
          }
          days_until_due
        }
      }
    }
  }
`

export const GET_HELIPORT_IMAGE = gql`
  query getHeliportImage($id: uuid!) {
    heliport_images_by_pk(id: $id) {
      file_id
      id
    }
  }
`
export const GET_OPERATOR_BY_ID = gql`
  query operatorById($id: uuid!) {
    operators_by_pk(id: $id) {
      id
      user_id
      operators_contact {
        email
        id
        name
        phone
      }
    }
  }
`
export const GET_COMPANY_USERS = gql`
  query company_users($id: uuid!) {
    user(id: $id) {
      email
      displayName
    }
  }
`
export const GET_HELIPORT_CURRENCY = gql`
  query heliport_currency($id: uuid!) {
    company_by_pk(id: $id) {
      id
      heliports {
        heliport_profile {
          id
          heliport_currency
        }
        id
      }
    }
  }
`
export const GET_HELIPORT_TIMEZONE = gql`
  query heliport($userId: uuid) {
    heliports(
      where: { company: { users_companies: { user_id: { _eq: $userId } } } }
    ) {
      id
      company {
        id
      }
      heliport_profile {
        id
        timezone
        timezone_number
        timekit_project_id
        country
      }
    }
  }
`
export const GET_HELIPORT_BY_ICAO_CODE = gql`
  query getHeliportByIcaoCode($_ilike: String) {
    heliports(where: { heliport_profile: { icao_code: { _ilike: $_ilike } } }) {
      id
      heliport_profile {
        address
        cancellation_policy
        city
        created_at
        email
        fuel_available
        helipads_number
        heliport_description
        heliport_id
        hourly_rate
        icao_code
        id
        landing_fee
        landing_slot_length
        latitude
        longitude
        manager_name
        name
        other_restrictions
        overnight_rate
        parking_fee
        phone
        reservations
        size_limit_weigth
        state
        timezone
        timezone_number
        timekit_project_id
        country
        heliport_currency
        measure_limit_weigth
        measure_rotor_diameter
        heliport_image_id
        surface_type
        parking_available
        heliport_aviabilities {
          day
          currency
          price
          id
          start_hour
          end_hour
        }
        dimensions_limit
      }
      helipads {
        id
        name
        timekit_resource_id
        heliport_id
        created_at
      }
      company {
        id
      }
    }
  }
`
export const GET_HELIPORT_AVIABILITIES = gql`
  query getHeliportAviabilities($heliportProfileId: uuid) {
    heliport_aviability(
      where: { heliport_profile_id: { _eq: $heliportProfileId } }
    ) {
      id
      price
      start_hour
      end_hour
      day
      currency
      booking_graph
    }
  }
`
