import React from 'react'
import { HStack, View, Text } from 'native-base'
import Loading from '../components/Loading'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

export const BookingDetailPayment = ({ booking }) => {
  const { t } = useTranslation()
  const [bookingDate, setBookingDate] = React.useState('')
  const [loading, setIsLoading] = React.useState(true)
  const [bookingHourStart, setBookingHourStart] = React.useState('')
  const [bookingHourEnd, setBookingHourEnd] = React.useState('')
  const [bookingKeys, setBookingKeys] = React.useState([])
  const [dictionary, setDictionary] = React.useState({
    additional_field: t('paymentSummary.bookingDetailPayment.additionalField'),
    total_passengers: t('paymentSummary.bookingDetailPayment.totalPassenger'),
    disembarking_passengers: t(
      'paymentSummary.bookingDetailPayment.disembarkingPassengers'
    ),
    email: t('paymentSummary.bookingDetailPayment.email'),
    embarking_passengers: t(
      'paymentSummary.bookingDetailPayment.embarkingPassengers'
    ),
    next_destination: t('paymentSummary.bookingDetailPayment.nextDestination'),
    origin_flight: t('paymentSummary.bookingDetailPayment.originFlight'),
    passengers_ids: t('paymentSummary.bookingDetailPayment.passengersIds'),
    pilot_name: t('paymentSummary.bookingDetailPayment.pilotName'),
    tail_number: t('paymentSummary.bookingDetailPayment.tailNumber'),
    timezone: t('paymentSummary.bookingDetailPayment.timezone'),
    name: t('paymentSummary.bookingDetailPayment.name'),
    certificate_number: t('managerBookingDetail.certificateNumber'),
    model: t('managerBookingDetail.model'),
  })
  React.useEffect(async () => {
    let keys = Object.keys(booking.attributes.customer)
    setBookingKeys(keys)
    const date = moment(booking.attributes.event.start).format('LL')
    const bookingStart = moment(booking.attributes.event.start).format('HH:mm')
    const bookingEnd = moment(booking.attributes.event.end).format('HH:mm')
    setBookingDate(date)
    setBookingHourStart(bookingStart)
    setBookingHourEnd(bookingEnd)
    setIsLoading(false)
  }, [])
  if (loading) {
    return <Loading />
  }
  return (
    <View>
      <Text bold fontSize="s" p="2">
        {t('paymentSummary.bookingDetailPayment.bookingDetails')}
      </Text>
      <HStack space={2}>
        <Text fontSize="s" p="2">
          {bookingDate}
        </Text>
        <Text fontSize="s" p="2">
          {bookingHourStart}
        </Text>
        <Text fontSize="s" p="2">
          -
        </Text>
        <Text fontSize="s" p="2">
          {bookingHourEnd}
        </Text>
      </HStack>
      {bookingKeys.map((bookingKey) => (
        <View key={bookingKey}>
          <HStack space={1}>
            <View width="50%" p="2">
              <Text bold fontSize="s" p="2">
                {dictionary[bookingKey]}:
              </Text>
            </View>
            <View width="50%" p="2">
              <Text p="2">{booking.attributes.customer[bookingKey]}</Text>
            </View>
          </HStack>
        </View>
      ))}
    </View>
  )
}
export default BookingDetailPayment
