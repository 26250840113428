import React from 'react';
import {
  View,
  Center,
  Text,
  HStack,
  VStack,
  Box,
  Divider,
  Button,
} from 'native-base';
import AppBarHeader from '../components/AppBar';
import { useHistory, useLocation } from '../routing';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axiosInstance from '../AxiosInstance';
import moment from 'moment-timezone';

export const RescheduleDateAndTimeManager = () => {
  const { t } = useTranslation();
  const [bookingDate, setBookingDate] = React.useState('');
  const [loading, setIsLoading] = React.useState(true);
  const [bookingHourStart, setBookingHourStart] = React.useState('');
  const [bookingHourEnd, setBookingHourEnd] = React.useState('');
  const [bookingStart, setBookingStart] = React.useState('');
  const [bookingEnd, setBookingEnd] = React.useState('');
  const [bookingDetail, setBookingDetail] = React.useState({});
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [errorEditManger, setErrorEditManager] = React.useState('');

  function useParamQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useParamQuery();
  const company = query.get('company');
  const getGraph = query.get('graph');
  const getBooking = query.get('booking');
  const booking = getBooking ? getBooking : '';
  const graph = getGraph ? getGraph : '';
  let history = useHistory();

  React.useEffect(async () => {
    const getTimeslot = await AsyncStorage.getItem('timeslot');

    const timeslot = JSON.parse(getTimeslot);

    const heliportTimeZone = timeslot.resources[0].timezone;
    if (timeslot) {
      const bookingStartDate = timeslot.start;

      const start = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .locale('en')
        .day();

      setBookingStart(bookingStartDate);

      const bookingEndDate = timeslot.end;

      setBookingEnd(bookingEndDate);

      const date = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('YYYY-MM-DD');

      const bookingStart = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('HH:mm');

      const bookingEnd = moment(bookingEndDate)
        .tz(heliportTimeZone)
        .format('HH:mm');

      setBookingDate(date);
      setBookingHourStart(bookingStart);
      setBookingHourEnd(bookingEnd);
    }

    const bookingById = await axiosInstance.post(`/bookings/bookingById`, {
      id: booking,
    });
    const bookingData = bookingById.data.booking;
    setBookingDetail(bookingData);
    setIsLoading(false);
  }, []);

  const cancelReschedule = async () => {
    await history.push(
      `/scheduleHeliport?heliport=${bookingDetail.project.id}&company=${
        bookingDetail.meta.company_id
      }&booking=${bookingDetail.id}&propose=${true}&graph=${
        bookingDetail.graph
      }`
    );
  };

  const editDateAndTime = async () => {
    setIsLoadingButton(true);
    const bookingState = bookingDetail.state;
    const dateBooking = moment(bookingDate).format('YYYY-MM-DD');

    try {
      await axiosInstance.post(`/bookings/editDateTime`, {
        booking: bookingDetail,
        date: dateBooking,
        startHour: bookingHourStart,
        endHour: bookingHourEnd,
        state: bookingState,
      });
      setIsLoadingButton(false);
      await history.push(`/bookingDetail/${bookingDetail.id}`);
    } catch (error) {
      setIsLoadingButton(false);
      if (error?.response?.data?.errors) {
        setErrorEditManager(t('errors.editBooking'));
      } else {
        setErrorEditManager(
          error?.response?.data?.error?.message
            ? error.response.data.error.message
            : error.message
        );
      }
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <View>
      <AppBarHeader />
      <Center>
        <Box
          p={'2'}
          rounded="xl"
          backgroundColor="light.100"
          width={['70%', '70%', '40%', '40%']}
        >
          <VStack space="4" divider={<Divider />}>
            <Box px="4" pt="4">
              <Text
                p="1%"
                fontSize={['xs', 'sm', 'sm', 'md']}
                fontWeight="bold"
              >
                {t('proposeNewDate.title')}
              </Text>
            </Box>
            <Text fontSize={['xs', 'sm', 'sm', 'sm']} pl="4">
              {t('proposeNewDate.changeTo')}
            </Text>
            <Box px="4">
              <HStack space="2">
                <Text fontWeight="bold" fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('proposeNewDate.date')}
                </Text>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {moment(bookingDate).format('LL')}
                </Text>
              </HStack>
              <HStack space="2">
                <Text fontWeight="bold" fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('proposeNewDate.startHour')}
                </Text>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {bookingHourStart}
                </Text>
              </HStack>
              <HStack space="2">
                <Text fontWeight="bold" fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {t('proposeNewDate.endHour')}
                </Text>
                <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                  {bookingHourEnd}
                </Text>
              </HStack>
            </Box>
            <Box px="4" pb="4" alignItems="flex-end">
              <HStack space={3}>
                <Button
                  isLoading={isLoadingButton}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                  bgColor="yellow.300"
                  size="md"
                  _text={{
                    color: 'coolgray.800',
                  }}
                  onPress={() => {
                    editDateAndTime();
                  }}
                >
                  {t('proposeNewDate.acceptButton')}
                </Button>
                <Button
                  bgColor="danger.500"
                  size="md"
                  onPress={() => {
                    cancelReschedule();
                  }}
                >
                  {t('proposeNewDate.cancel')}
                </Button>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </Center>
    </View>
  );
};
export default RescheduleDateAndTimeManager;
