import React from 'react'
import {
  HStack,
  View,
  Box,
  Text,
  VStack,
  Center,
  Input,
  Button,
  Select,
  CheckIcon,
  NativeBaseProvider,
  Modal,
  Stack,
  Pressable,
  Image,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useForm, Controller } from 'react-hook-form'
import {
  GET_PILOTS,
  GET_FLEET,
  GET_OPERATOR,
  GET_MANAGERS_BY_PROJECT,
  GET_COMPANY_USERS,
} from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../Token'
import { useLocation } from '../routing'
import { SERVER_SENGRID } from '@env'
import Loading from '../components/Loading'
import 'react-phone-input-2/lib/style.css'
import NewAircraftBooking from '../components/NewAircraftBooking'
import NewPilotBooking from '../components/NewPilotBooking'
import button_plus from '../assets/button_plus.png'
import minus_icon from '../assets/minus_icon.png'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import axiosInstance from '../AxiosInstance'

export const BookingForm = (props) => {
  const [bookingDate, setBookingDate] = React.useState('')
  const [loading, setIsLoading] = React.useState(true)
  const [bookingHourStart, setBookingHourStart] = React.useState('')
  const [bookingHourEnd, setBookingHourEnd] = React.useState('')
  const [operatorName, setOperatorName] = React.useState('')
  const [operatorEmail, setOperatorEmail] = React.useState('')
  const [pilotsName, setPilotsName] = React.useState([])
  const [aircraftsList, setAircraftsList] = React.useState([])
  const [companyName, setCompanyName] = React.useState('')
  const [companyId, setCompanyId] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [user, setUser] = React.useState('')
  const [operatorId, setOperatorId] = React.useState('')
  const [timeslotInformation, setTimeslotInformation] = React.useState({})
  const [graph, setGraph] = React.useState('')
  const [bookingStart, setBookingStart] = React.useState('')
  const [bookingEnd, setBookingEnd] = React.useState('')
  const [projectId, setProjectId] = React.useState('')
  const [amountPrice, setAmountPrice] = React.useState(0)
  const [currency, setCurrency] = React.useState('')
  const [graphBooking, setGraphBooking] = React.useState('')
  const [phoneOperator, setPhoneOperator] = React.useState('')
  const [timezone, setTimezone] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [getAircrafts] = useLazyQuery(GET_FLEET, {
    fetchPolicy: 'network-only',
  })
  const [getPilots] = useLazyQuery(GET_PILOTS, {
    fetchPolicy: 'network-only',
  })
  const [reschedule, setReschedule] = React.useState(false)
  const [bookingId, setBookingId] = React.useState('')
  const [formValuesDisembarking, setFormValuesDisembarking] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])
  const [formValuesEmbarking, setFormValuesEmbarking] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])
  const [copilotExist, setCopilotExist] = React.useState(false)
  const [copilot, setCopilot] = React.useState('')
  const [locale, setLocale] = React.useState('')
  const { t } = useTranslation()
  const [heliportPhone, setHeliportPhone] = React.useState('')
  const [repeatedError, setRepeatedError] = React.useState('')
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [flightNumber, setFlightNumber] = React.useState('')
  const [userPhones, setUserPhones] = React.useState([])
  const [operatorEmails, setOperatorEmails] = React.useState([])
  const [operatorPhones, setOperatorPhones] = React.useState([])

  const days = [
    { day: 'sunday' },
    { day: 'monday' },
    { day: 'tuesday' },
    { day: 'wednesday' },
    { day: 'thursday' },
    { day: 'friday' },
    { day: 'saturday' },
  ]

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  const [operator] = useLazyQuery(GET_OPERATOR, {
    fetchPolicy: 'network-only',
  })

  const [pilots] = useLazyQuery(GET_PILOTS, {
    fetchPolicy: 'network-only',
  })

  const [fleet] = useLazyQuery(GET_FLEET, {
    fetchPolicy: 'network-only',
  })

  const [managers] = useLazyQuery(GET_MANAGERS_BY_PROJECT, {
    fetchPolicy: 'network-only',
  })

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const getTimeslot = await AsyncStorage.getItem('timeslot')

    const timeslot = JSON.parse(getTimeslot)

    const getCompanyName = timeslot.resources[0].name

    setUser(userId)

    setCompanyName(getCompanyName)

    const getOperator = await operator({
      variables: { userId: userId },
    })

    const operatorName = getOperator.data.operators[0].operator_name

    setOperatorName(operatorName)

    const operatorEmail = getOperator.data.operators[0].operators_contact.email

    setOperatorEmail(operatorEmail)

    const operatorPhone = getOperator.data.operators[0].operators_contact.phone

    setPhoneOperator(operatorPhone)

    const getOperatorId = getOperator.data.operators[0].id

    setOperatorId(getOperatorId)

    if (
      getOperator.data &&
      getOperator.data.operators.length > 0 &&
      getOperator.data.operators[0].operator_emails.length > 0
    ) {
      const getOperatorEmails =
        getOperator.data.operators[0].operator_emails.map((email) => {
          return email.email
        })
      setOperatorEmails(getOperatorEmails)
    }
    console.log(getOperator.data.operators)

    if (
      getOperator.data &&
      getOperator.data.operators.length > 0 &&
      getOperator.data.operators[0].operator_phones.length > 0
    ) {
      const getOperatorPhones =
        getOperator.data.operators[0].operator_phones.map((phone) => {
          return phone.phone_number
        })
      setOperatorPhones(getOperatorPhones)
    }

    const pilotsList = await pilots({
      variables: { userId: userId },
    })
    const pilotListName = getPilotsName(pilotsList.data.pilots)
    setPilotsName(pilotListName)

    const company = query.get('company')

    setCompanyId(company)

    const companyManagers = await managers({
      variables: { id: company },
    })

    const heliportProfilePhone =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile.phone

    setHeliportPhone(heliportProfilePhone)

    const usersCompanyManagers =
      companyManagers.data.company_by_pk.users_companies

    const emailsUsers =
      companyManagers.data.company_by_pk.email_users &&
      companyManagers.data.company_by_pk.email_users.length > 0
        ? companyManagers.data.company_by_pk.email_users
        : []

    const companyPhones =
      companyManagers.data.company_by_pk.phone_users &&
      companyManagers.data.company_by_pk.phone_users.length > 0
        ? companyManagers.data.company_by_pk.phone_users
        : []

    const getCompanyPhones = getPhoneUsers(companyPhones)

    setUserPhones(getCompanyPhones)

    const getCompanyUsersEmails = await getUsersEmail(
      usersCompanyManagers,
      emailsUsers
    )

    setUsers(getCompanyUsersEmails)

    const heliportTimeZone =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile.timezone

    setTimezone(heliportTimeZone)

    const getGraph =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile
        .reservations === 'automatic'
        ? 'instant'
        : 'confirm_decline'

    setGraph(getGraph)

    const getProjectId =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile
        .timekit_project_id

    setProjectId(getProjectId)

    setTimeslotInformation(timeslot)
    await getSelectedAircrafts(userId)
    if (timeslot) {
      const bookingStartDate = timeslot.start

      const start = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .locale('en')
        .day()

      setBookingStart(bookingStartDate)

      const bookingEndDate = timeslot.end

      setBookingEnd(bookingEndDate)

      const date = moment(bookingStartDate).tz(heliportTimeZone).format('LL')

      const bookingStart = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('HH:mm')

      const bookingStartToCompare = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('HH:mm:ss')

      const bookingEnd = moment(bookingEndDate)
        .tz(heliportTimeZone)
        .format('HH:mm')

      const bookingEndToCompare = moment(bookingEndDate)
        .tz(heliportTimeZone)
        .format('HH:mm:ss')

      if (
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .heliport_aviabilities
      ) {
        const day = getDay(start)

        getAmount(
          companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .heliport_aviabilities,
          day,
          bookingStartToCompare,
          bookingEndToCompare
        )
      }

      const getReschedule = query.get('reschedule')

      const getBookingId = query.get('booking')

      if (getBookingId) {
        setBookingId(getBookingId)
      }

      setReschedule(getReschedule)
      setBookingDate(date)
      setBookingHourStart(bookingStart)
      setBookingHourEnd(bookingEnd)
    }
    const currentLocale = getCountryLocale(
      companyManagers?.data?.company_by_pk?.heliports[0].heliport_profile
        ?.country
    )
    setLocale(currentLocale)
    setIsLoading(false)
  }, [])

  const getCountryLocale = (country) => {
    if (country) {
      if (country === 'USA') {
        return 'en'
      } else if (country === 'Brazil') {
        return 'pt'
      } else if (country === 'Mexico') {
        return 'es'
      }
    } else {
      return 'en'
    }
  }

  const getSelectedAircrafts = async (userId) => {
    const aircrafts = await fleet({
      variables: { userId: userId },
    })

    const aircraftsTailNumbers = getAircraftTailsNumbers(
      aircrafts.data.fleet[0].aircrafts
    )
    setAircraftsList(aircraftsTailNumbers)
  }

  const getUsersEmail = async (managersCompany, emailsUsers) => {
    let emails = []
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    if (emailsUsers.length > 0) {
      const emailUsersExtra = emailsUsers.map((email) => {
        return email.email
      })
      emails = emailUsersExtra
    }
    const companyUsersEmails = [...emails, ...usersEmails]
    return companyUsersEmails
  }
  const getPhoneUsers = (phones) => {
    if (phones.length > 0) {
      const companyPhoneUsers = phones.map((phone) => {
        return phone.phone_number
      })
      return companyPhoneUsers
    } else {
      return []
    }
  }

  const getDay = (date) => {
    const day = days[date].day
    return day
  }

  const getAmount = (aviablilities, day, bookingStart, bookingEnd) => {
    const newArrayAviabilities = aviablilities.map((a) => {
      return {
        ...a,
        startBookingHour: passStringHourToNumber(a.start_hour),
        endBookingHour: passStringHourToNumber(a.end_hour),
      }
    })

    const newBookingStart = passStringHourToNumber(bookingStart)

    const newBookingEnd = passStringHourToNumber(bookingEnd)

    const getAviability = newArrayAviabilities.filter((a) => {
      return (
        a.day === day &&
        newBookingStart >= a.startBookingHour &&
        newBookingEnd <= a.endBookingHour
      )
    })

    setAmountPrice(parseInt(getAviability[0].price) * 100)
    setCurrency(getAviability[0].currency)
    setGraphBooking(getAviability[0].booking_graph)
  }

  const passStringHourToNumber = (time) => {
    var array = time.split(':')
    var seconds =
      parseInt(array[0], 10) * 60 * 60 +
      parseInt(array[1], 10) * 60 +
      parseInt(array[2], 10)
    return seconds
  }

  const getPilotsName = (pilotsList) => {
    const getpilotsListName = pilotsList.map((pilots) => {
      return {
        id: pilots.id,
        name: `${pilots.first_name} ${pilots.last_name}`,
        certificateNumber: pilots.certificate_number,
      }
    })
    return getpilotsListName
  }

  const getAircraftTailsNumbers = (aircrafts) => {
    const getTailsNumbers = aircrafts.map((aircraft) => {
      return {
        tail_number: aircraft.tail_numbers,
        model: aircraft.helicopter_makes_models,
      }
    })
    return getTailsNumbers
  }

  const filterAircrafts = (tailNumber) => {
    const aircfratsModels = aircraftsList.filter((aircraft) => {
      return aircraft.tail_number === tailNumber
    })
    return aircfratsModels[0]
  }

  const filterPilots = (pilotId) => {
    const pilotsFind = pilotsName.filter((pilot) => {
      return pilot.id === pilotId
    })
    return pilotsFind[0]
  }

  let addFormFields = () => {
    setFormValuesDisembarking([
      ...formValuesDisembarking,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues.splice(i, 1)
    setFormValuesDisembarking(newFormValues)
  }

  let handleChangeFirstName = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].firstName = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeLastName = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].lastName = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeId = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].id = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeDocumentType = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].documentType = e
    setFormValuesDisembarking(newFormValues)
  }

  let addFormFieldsEmbarking = () => {
    setFormValuesEmbarking([
      ...formValuesEmbarking,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFieldsEmbarking = (i) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues.splice(i, 1)
    setFormValuesEmbarking(newFormValues)
  }

  let handleChangeFirstNameEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].firstName = e.target.value
    setFormValuesEmbarking(newFormValues)
  }
  let handleChangeLastNameEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].lastName = e.target.value
    setFormValuesEmbarking(newFormValues)
  }
  let handleChangeIdEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].id = e.target.value
    setFormValuesEmbarking(newFormValues)
  }

  let handleChangeDocumentTypeEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].documentType = e
    setFormValuesEmbarking(newFormValues)
  }

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({})

  const onSubmit = async (booking) => {
    setButtonLoading(true)
    if (reschedule) {
      await rescheduleBookingForm(booking)
    } else {
      await createBookingForm(booking)
    }
  }

  const disembarkingPassengers = (disembarking) => {
    if (disembarking[0].firstName) {
      const disembarkingNames = disembarking.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const disembarkingToString = disembarkingNames.toString()

      return disembarkingToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const embarkingPassengers = (embarking) => {
    if (embarking[0].firstName) {
      const embarkingNames = embarking.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const embarkingToString = embarkingNames.toString()

      return embarkingToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const createBookingFormInformation = async (booking) => {
    try {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      const getDisembarkingPassengers = disembarkingPassengers(
        formValuesDisembarking
      )
      const getEmbarkingPassengers = embarkingPassengers(formValuesEmbarking)
      const getHelicopterModel = filterAircrafts(booking.tail_number)
      booking.pilot_name = getPilot.name
      const newBooking = {
        name: operatorName,
        email: operatorEmail,
        ...booking,
        disembarking_passengers: getDisembarkingPassengers,
        embarking_passengers: getEmbarkingPassengers,
      }
      const bookingInformation = {
        project_id: projectId,
        resource_id: timeslotInformation.resources[0].id,
        graph: graphBooking ? graphBooking : graph,
        start: bookingStart,
        end: bookingEnd,
        what: timeslotInformation.resources[0].name,
        where: 'Booking',
        description: 'Booking Heliport',
        customer: newBooking,
        meta: {
          company_id: companyId,
          operator_id: operatorId,
          users: users,
          operator_emails: operatorEmails,
          operator_phones: operatorPhones,
          user_phones: userPhones,
          payment_intent_id: '',
          phone: phoneOperator,
          model: getHelicopterModel.model,
          price: parseInt(amountPrice) / 100,
          currency: currency ? currency : 'brl',
          certificate_number: getPilot.certificateNumber,
          timezone: timezone,
          locale: locale,
          copilot_name: getCopilot ? getCopilot.name : 'None',
          certicate_number_copilot: getCopilot
            ? getCopilot.certificateNumber
            : 'None',
          phone_manager: heliportPhone,
          flight_number: flightNumber ? flightNumber : 'None',
          total_passenger: booking.total_passengers
            ? parseInt(booking.total_passengers)
            : '0',
        },
        event_notifications: [
          {
            type: 'webhook',
            settings: {
              url: `${SERVER_SENGRID}/booking/reminders`,
              method: 'post',
              expected_response_code: 200,
            },
            when: {
              type: 'before',
              unit: 'hours',
              time: 2,
            },
          },
        ],
      }
      const jsonValue = JSON.stringify(bookingInformation)
      await AsyncStorage.setItem('booking', jsonValue)
      const bookingAmount = {
        amount: amountPrice,
        currency: currency ? currency : 'brl',
      }
      const value = JSON.stringify(bookingAmount)
      await AsyncStorage.setItem('bookingAmount', value)
      setButtonLoading(false)
      props.history.push(
        `/paymentMethods?company=${companyId}&operator=${operatorId}&company_name=${companyName}`
      )
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  const createBookingForm = async (booking) => {
    if (copilot) {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      if (getPilot.id !== getCopilot.id) {
        createBookingFormInformation(booking)
        setRepeatedError('')
      } else {
        setRepeatedError(t('bookingOperatorForm.repeatedPilot'))
      }
    } else {
      createBookingFormInformation(booking)
    }
  }

  const rescheduleBookingForm = async (booking) => {
    const getBooking = await axiosInstance.post(`/bookings/bookingById`, {
      id: bookingId,
    })
    if (getBooking.data.booking.graph === 'instant') {
      await rescheduleBookingInstant(booking, getBooking.data.booking)
    } else {
      rescheduleBookingConfirmDecline(booking, getBooking.data.booking)
    }
  }

  const goToBack = () => {
    props.history.goBack()
  }

  const newPilot = async () => {
    try {
      const refetchPilots = await getPilots({
        variables: { userId: user },
        fetchPolicy: 'network-only',
      })
      const newPilotsInformation = refetchPilots.data.pilots
      const newPilotsArray = await getPilotsName(newPilotsInformation)
      setPilotsName(newPilotsArray)
    } catch (error) {
      console.log(error)
    }
  }

  const refetchAircrafts = async () => {
    const refetchFleet = await getAircrafts({
      variables: { userId: user },
    })
    const aircfrats = refetchFleet.data.fleet[0].aircrafts
    return aircfrats
  }

  const newAircraft = async () => {
    try {
      const refetchSelectedAircrafts = await refetchAircrafts()
      const aircraftsArray = await getAircraftTailsNumbers(
        refetchSelectedAircrafts
      )
      setAircraftsList(aircraftsArray)
    } catch (error) {
      console.log(error)
    }
  }

  const onCloseModalAircraft = () => {
    resetField('tail_number', { defaultValue: '' })
    closeModalAircraft()
  }
  const onCloseModalPilot = () => {
    resetField('pilot_name', { defaultValue: '' })
    setCopilot('')
    closeModalPilot()
  }

  const rescheduleBookingInstantInformation = async (booking, bookingData) => {
    try {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      const getDisembarkingPassengers = disembarkingPassengers(
        formValuesDisembarking
      )
      const getEmbarkingPassengers = embarkingPassengers(formValuesEmbarking)
      booking.pilot_name = getPilot.name
      const newBooking = {
        name: operatorName,
        email: operatorEmail,
        ...booking,
        disembarking_passengers: getDisembarkingPassengers,
        embarking_passengers: getEmbarkingPassengers,
      }
      const getHelicopterModel = filterAircrafts(booking.tail_number)

      const bookingInformation = {
        project_id: projectId,
        resource_id: timeslotInformation.resources[0].id,
        graph: bookingData.graph,
        start: bookingStart,
        end: bookingEnd,
        what: timeslotInformation.resources[0].name,
        where: 'Booking',
        description: 'Booking Heliport',
        customer: newBooking,
        meta: {
          company_id: companyId,
          operator_id: operatorId,
          users: users,
          operator_emails: operatorEmails,
          operator_phones: operatorPhones,
          user_phones: userPhones,
          payment_intent_id: '',
          phone: phoneOperator,
          model: getHelicopterModel.model,
          price: parseInt(amountPrice) / 100,
          currency: currency ? currency : 'brl',
          certificate_number: getPilot.certificateNumber,
          timezone: timezone,
          stripe: bookingData.meta.stripe,
          locale: locale,
          copilot_name: getCopilot ? getCopilot.name : 'None',
          certicate_number_copilot: getCopilot
            ? getCopilot.certificateNumber
            : 'None',
          phone_manager: heliportPhone,
          flight_number: flightNumber ? flightNumber : 'None',
          total_passenger: booking.total_passengers
            ? parseInt(booking.total_passengers)
            : '0',
        },
        event_notifications: [
          {
            type: 'webhook',
            settings: {
              url: `${SERVER_SENGRID}/booking/reminders`,
              method: 'post',
              expected_response_code: 200,
            },
            when: {
              type: 'before',
              unit: 'hours',
              time: 2,
            },
          },
        ],
      }
      await axiosInstance.post(`/bookings/rescheduleBooking`, {
        booking: bookingInformation,
        bookingId: bookingId,
      })
      setButtonLoading(false)
      props.history.push(`/manageReservationOperator`)
      setRepeatedError('')
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  const rescheduleBookingInstant = async (booking, bookingData) => {
    if (copilot) {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      if (getPilot.id !== getCopilot.id) {
        rescheduleBookingInstantInformation(booking, bookingData)
        setRepeatedError('')
      } else {
        setRepeatedError(t('bookingOperatorForm.repeatedPilot'))
      }
    } else {
      rescheduleBookingInstantInformation(booking, bookingData)
    }
  }

  const rescheduleBookingManualInformation = async (booking, bookingData) => {
    try {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      const getDisembarkingPassengers = disembarkingPassengers(
        formValuesDisembarking
      )
      const getEmbarkingPassengers = embarkingPassengers(formValuesEmbarking)
      booking.pilot_name = getPilot.name
      const newBooking = {
        name: operatorName,
        email: operatorEmail,
        ...booking,
        disembarking_passengers: getDisembarkingPassengers,
        embarking_passengers: getEmbarkingPassengers,
      }
      const getHelicopterModel = filterAircrafts(booking.tail_number)
      const bookingInformation = {
        project_id: projectId,
        resource_id: timeslotInformation.resources[0].id,
        graph: bookingData.graph,
        start: bookingStart,
        end: bookingEnd,
        what: timeslotInformation.resources[0].name,
        where: 'Booking',
        description: 'Booking Heliport',
        customer: newBooking,
        meta: {
          company_id: companyId,
          operator_id: operatorId,
          users: users,
          operator_emails: operatorEmails,
          operator_phones: operatorPhones,
          user_phones: userPhones,
          payment_intent_id: bookingData.meta.payment_intent_id,
          phone: phoneOperator,
          model: getHelicopterModel.model,
          price: parseInt(amountPrice) / 100,
          currency: currency ? currency : 'brl',
          certificate_number: getPilot.certificateNumber,
          timezone: timezone,
          stripe: bookingData.meta.stripe,
          locale: locale,
          copilot_name: getCopilot ? getCopilot.name : 'None',
          certicate_number_copilot: getCopilot
            ? getCopilot.certificateNumber
            : 'None',
          phone_manager: heliportPhone,
          flight_number: flightNumber ? flightNumber : 'None',
          total_passenger: booking.total_passengers
            ? parseInt(booking.total_passengers)
            : '0',
        },
        event_notifications: [
          {
            type: 'webhook',
            settings: {
              url: `${SERVER_SENGRID}/booking/reminders`,
              method: 'post',
              expected_response_code: 200,
            },
            when: {
              type: 'before',
              unit: 'hours',
              time: 2,
            },
          },
        ],
      }
      if (
        bookingData.graph === 'confirm_decline' &&
        bookingData.state === 'confirmed'
      ) {
        await axiosInstance.post(`/bookings/rescheduleBookingConfirmed`, {
          booking: bookingInformation,
          bookingId: bookingId,
        })
        props.history.push(`/manageReservationOperator`)
      } else {
        await axiosInstance.post(`/bookings/rescheduleBookingTentative`, {
          booking: bookingInformation,
          bookingId: bookingId,
        })
        props.history.push(`/manageReservationOperator`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const rescheduleBookingConfirmDecline = async (booking, bookingData) => {
    if (copilot) {
      const getPilot = filterPilots(booking.pilot_name)
      const getCopilot = filterPilots(copilot)
      if (getPilot.id !== getCopilot.id) {
        rescheduleBookingManualInformation(booking, bookingData)
        setRepeatedError('')
      } else {
        setRepeatedError(t('bookingOperatorForm.repeatedPilot'))
      }
    } else {
      rescheduleBookingManualInformation(booking, bookingData)
    }
  }

  const openModalPilot = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow1(true)
  }

  const closeModalPilot = () => {
    document.body.style.overflow = 'scroll'
    setShow1(false)
  }

  const openModalAircraft = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModalAircraft = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  if (loading) return <Loading />
  return (
    <View>
      <AppBarHeader />
      <NativeBaseProvider>
        <View pr={['2%', '2%', '20%', '28%']} pl={['2%', '2%', '20%', '28%']}>
          <Box
            bgColor="#fbfbfb"
            rounded="xl"
            shadow="9"
            pt="10"
            pb="10"
            pl="6"
            pr="6"
          >
            <Center>
              <VStack space={2} alignItems="center">
                <Text fontSize="3xl">{bookingDate}</Text>
                <HStack space={3}>
                  <Text fontSize="md">{bookingHourStart}</Text>
                  <Text>-</Text>
                  <Text fontSize="md">{bookingHourEnd}</Text>
                </HStack>
                <Text fontSize="xs">{t('bookingOperatorForm.with')}</Text>
                <Text fontSize="md">{companyName}</Text>
              </VStack>
            </Center>
            <View>
              <p />
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.flightNumber')}
              </Text>
              <Input
                borderRadius="2xl"
                bgColor="white"
                placeholder={t('bookingOperatorForm.flightNumber')}
                onChangeText={(value) => setFlightNumber(value)}
              />
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.totalPassenger')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t('heliportForm.numbers'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    w={['60%', '60%', '40%', '40%']}
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingOperatorForm.totalPassenger')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="total_passengers"
              />
              {errors.total_passengers && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.total_passengers.message}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.disembarkingPassengers')}
              </Text>
              <View>
                {formValuesDisembarking.map((element, index) => (
                  <Stack
                    space={1}
                    p="1%"
                    key={index}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingOperatorForm.firstName')}
                      value={element.firstName || ''}
                      onChange={(e) => handleChangeFirstName(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingOperatorForm.lastName')}
                      value={element.lastName || ''}
                      onChange={(e) => handleChangeLastName(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />
                    {locale === 'pt' ? (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingOperatorForm.document')}
                        width={['100%', '100%', '100%', '175']}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                      >
                        <Select.Item label="Passporte" value="Passporte" />
                        <Select.Item label="CPF" value="CPF" />
                        <Select.Item label="RG" value="RG" />
                      </Select>
                    ) : (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingOperatorForm.document')}
                        width={['100%', '100%', '100%', '175']}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                      >
                        <Select.Item
                          label={t('bookingOperatorForm.passport')}
                          value="Passport"
                        />
                        <Select.Item
                          label={t('bookingOperatorForm.driversLicense')}
                          value="Driver's License"
                        />
                      </Select>
                    )}

                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="ID"
                      value={element.id || ''}
                      onChange={(e) => handleChangeId(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />

                    {index ? (
                      <Pressable
                        onPress={() => removeFormFields(index)}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Image
                          source={{
                            uri: minus_icon,
                          }}
                          alt="button minus"
                          style={{ width: 20, height: 20 }}
                        />
                      </Pressable>
                    ) : null}

                    <Pressable
                      onPress={() => addFormFields()}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: button_plus,
                        }}
                        alt="button plus"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </Stack>
                ))}
              </View>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.embarkingPassengers')}
              </Text>
              <View>
                {formValuesEmbarking.map((element, index) => (
                  <Stack
                    space={1}
                    key={index}
                    p="1%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingOperatorForm.firstName')}
                      value={element.firstName || ''}
                      onChange={(e) => handleChangeFirstNameEmbarking(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingOperatorForm.lastName')}
                      value={element.lastName || ''}
                      onChange={(e) => handleChangeLastNameEmbarking(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />
                    {locale === 'pt' ? (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingOperatorForm.document')}
                        width={['100%', '100%', '100%', '175']}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentTypeEmbarking(index, e)
                        }
                      >
                        <Select.Item label="Passporte" value="Passporte" />
                        <Select.Item label="CPF" value="CPF" />
                        <Select.Item label="RG" value="RG" />
                      </Select>
                    ) : (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingOperatorForm.document')}
                        width={['100%', '100%', '100%', '175']}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentTypeEmbarking(index, e)
                        }
                      >
                        <Select.Item
                          label={t('bookingOperatorForm.passport')}
                          value="Passport"
                        />
                        <Select.Item
                          label={t('bookingOperatorForm.driversLicense')}
                          value="Driver's License"
                        />
                      </Select>
                    )}
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="ID"
                      value={element.id || ''}
                      onChange={(e) => handleChangeIdEmbarking(index, e)}
                      width={['100%', '100%', '100%', '25%']}
                    />
                    {index ? (
                      <Pressable
                        onPress={() => removeFormFieldsEmbarking(index)}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Image
                          source={{
                            uri: minus_icon,
                          }}
                          alt="button minus"
                          style={{ width: 20, height: 20 }}
                        />
                      </Pressable>
                    ) : null}
                    <Pressable
                      onPress={() => addFormFieldsEmbarking()}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: button_plus,
                        }}
                        alt="button plus"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </Stack>
                ))}
              </View>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.originFlight')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingOperatorForm.originFlight')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="origin_flight"
              />
              {errors.origin_flight && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingOperatorForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.nextDestination')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingOperatorForm.nextDestination')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="next_destination"
              />
              {errors.next_destination && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingOperatorForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.selectPilot')}
              </Text>
              <HStack space={1}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      borderRadius="2xl"
                      onBlur={onBlur}
                      selectedValue={
                        value === 'Add_Pilot' ? openModalPilot() : value
                      }
                      width="94%"
                      accessibilityLabel="Select Pilot"
                      placeholder={t('bookingOperatorForm.selectPilot')}
                      _selectedItem={{
                        bg: 'teal.600',
                        endIcon: <CheckIcon size="5" />,
                      }}
                      mt={1}
                      onValueChange={onChange}
                    >
                      <Select.Item label="" value="" />
                      {pilotsName.map((pilot, index) => {
                        return (
                          <Select.Item
                            label={pilot.name}
                            value={pilot.id}
                            key={index}
                          />
                        )
                      })}
                      <Select.Item
                        label={t('bookingOperatorForm.addPilot')}
                        value="Add_Pilot"
                      />
                    </Select>
                  )}
                  defaultValue=""
                  name="pilot_name"
                />
                <Pressable
                  onPress={() => setCopilotExist(true)}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    source={{
                      uri: button_plus,
                    }}
                    alt="button plus"
                    style={{ width: 20, height: 20 }}
                  />
                </Pressable>
              </HStack>
              {errors.pilot_name && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingOperatorForm.required')}
                </Text>
              )}
              {copilotExist === true ? (
                <View>
                  <HStack space={1}>
                    <Select
                      borderRadius="2xl"
                      selectedValue={copilot}
                      width="94%"
                      accessibilityLabel="Select Copilot"
                      placeholder={t('bookingOperatorForm.selectCopilot')}
                      _selectedItem={{
                        bg: 'teal.600',
                        endIcon: <CheckIcon size="5" />,
                      }}
                      mt={1}
                      onValueChange={(itemValue) => setCopilot(itemValue)}
                    >
                      {pilotsName.map((pilot, index) => {
                        return (
                          <Select.Item
                            label={pilot.name}
                            value={pilot.id}
                            key={index}
                          />
                        )
                      })}
                    </Select>

                    <Pressable
                      onPress={() => setCopilotExist(false)}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: minus_icon,
                        }}
                        alt="button minus"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </HStack>
                  <Text p="2" fontSize="xs" color="#eb0100">
                    {repeatedError}
                  </Text>
                </View>
              ) : (
                <View />
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.selectAircraft')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    borderRadius="2xl"
                    onBlur={onBlur}
                    selectedValue={
                      value === 'Add_Aircraft' ? openModalAircraft() : value
                    }
                    minWidth="200"
                    accessibilityLabel="Select Aircraft"
                    placeholder={t('bookingOperatorForm.selectAircraft')}
                    _selectedItem={{
                      bg: 'teal.600',
                      endIcon: <CheckIcon size="5" />,
                    }}
                    mt={1}
                    onValueChange={onChange}
                  >
                    <Select.Item label="" value="" />
                    {aircraftsList.map((aircfrat, index) => {
                      return (
                        <Select.Item
                          label={`${aircfrat.model} - ${aircfrat.tail_number}`}
                          value={aircfrat.tail_number}
                          key={index}
                        />
                      )
                    })}
                    <Select.Item
                      label={t('bookingOperatorForm.addAircraft')}
                      value="Add_Aircraft"
                    />
                  </Select>
                )}
                defaultValue=""
                name="tail_number"
              />
              {errors.tail_number && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingOperatorForm.required')}
                </Text>
              )}
            </View>
          </Box>
          <View p="8" alignItems="center">
            <HStack space={3}>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                onPress={() => {
                  goToBack()
                }}
                p="4"
              >
                {t('bookingOperatorForm.back')}
              </Button>
              {reschedule ? (
                <Button
                  rounded="2xl"
                  colorScheme="yellow"
                  onPress={handleSubmit(onSubmit)}
                  p="4"
                  isLoading={buttonLoading}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                >
                  {t('bookingOperatorForm.confirmReservation')}
                </Button>
              ) : (
                <Button
                  rounded="2xl"
                  colorScheme="yellow"
                  onPress={handleSubmit(onSubmit)}
                  p="4"
                  isLoading={buttonLoading}
                  _loading={{
                    bg: 'amber.400:alpha.70',
                    _text: {
                      color: 'coolGray.700',
                    },
                  }}
                  _spinner={{
                    color: 'white',
                  }}
                  isLoadingText={t('submit_button.text')}
                >
                  {t('bookingOperatorForm.nextPayment')}
                </Button>
              )}
            </HStack>
          </View>
        </View>
      </NativeBaseProvider>

      <Modal
        isOpen={show}
        onClose={() => {
          resetField('tail_number', { defaultValue: '' })
          closeModalAircraft()
        }}
      >
        <NewAircraftBooking
          onAction={newAircraft}
          onClose={onCloseModalAircraft}
        />
      </Modal>

      <Modal
        isOpen={show1}
        onClose={() => {
          resetField('pilot_name', { defaultValue: '' })
          setCopilot('')
          closeModalPilot()
        }}
      >
        <NewPilotBooking onAction={newPilot} onClose={onCloseModalPilot} />
      </Modal>
    </View>
  )
}
export default BookingForm
