import * as React from 'react'
import {
  Box,
  View,
  Button,
  Center,
  VStack,
  Modal,
  Alert,
  HStack,
  IconButton,
  CloseIcon,
  Text,
  Image,
  Input,
  Checkbox,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLocation } from '../routing'
import { auth } from '../utils/nhost'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { GET_OPERATOR_BY_ID, GET_HELIPORT_BY_COMPANY } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import login from '../assets/login.png'
import { useForm, Controller } from 'react-hook-form'
import axiosInstance from '../AxiosInstance'
import Loading from '../components/Loading'

export const PaymentMethods = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [companyId, setCompanyId] = React.useState('')
  const [operatorId, setOperatorId] = React.useState('')
  const [token, setToken] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [isLoadingCard, setIsLoadingCard] = React.useState(false)
  const [isLoadingBoleto, setIsLoadingBoleto] = React.useState(false)
  const [isLoadingInvoice, setIsLoadingInvoice] = React.useState(false)
  const [visible, setIsVisible] = React.useState(false)
  const [isLoadingFastPass, setIsLoadingFastPass] = React.useState(false)
  const [error, setError] = React.useState('')
  const [operator] = useLazyQuery(GET_OPERATOR_BY_ID)
  const { t } = useTranslation()
  const [getHeliport] = useLazyQuery(GET_HELIPORT_BY_COMPANY)
  const [heliportCountry, setHeliportCountry] = React.useState({})
  const [locale, setLocale] = React.useState('en')
  const [heliportPrice, setHeliportPrice] = React.useState({})
  const [invoiceDays, setInvoiceDays] = React.useState(30)
  const [visibleModalInvoice, setIsVisibleModalInvoice] = React.useState(false)
  const [checkedInvoice, setCheckedInvoice] = React.useState(false)

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const getToken = auth.getJWTToken()

    setToken(getToken)

    const getCompanyId = query.get('company')

    setCompanyId(getCompanyId)

    const heliport = await getHeliport({
      variables: { id: getCompanyId },
    })

    setHeliportCountry(
      heliport.data.company_by_pk.heliports[0].heliport_profile.country
    )

    if (
      heliport.data &&
      heliport.data.company_by_pk &&
      heliport.data.company_by_pk.heliports.length > 0
    ) {
      setInvoiceDays(
        heliport.data.company_by_pk.heliports[0].heliport_profile
          .days_until_due >= 0
          ? heliport.data.company_by_pk.heliports[0].heliport_profile
              .days_until_due
          : 30
      )
    }

    const getOperatorId = query.get('operator')

    setOperatorId(getOperatorId)

    const getCompanyName = query.get('company_name')

    var today = new Date()

    const endDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      0
    )

    const getBooking = await AsyncStorage.getItem('booking')

    const getBookingCurrency = JSON.parse(getBooking)

    const currency = getBookingCurrency.meta.currency

    const bookingGraph = getBookingCurrency.graph

    const timeslot = await AsyncStorage.getItem('timeslot')

    const getDateBooking = JSON.parse(timeslot)

    const bookingDate = moment(getDateBooking.start).format('x')

    const bookingAmount = await AsyncStorage.getItem('bookingAmount')

    const price = JSON.parse(bookingAmount)

    setHeliportPrice(price.amount)

    if (
      bookingDate <= endDay.getTime() ||
      currency !== 'brl' ||
      bookingGraph === 'confirm_decline' ||
      heliportPrice === 0
    ) {
      setIsDisabled(true)
    }

    const stripeLocale = await getLocale()

    if (stripeLocale) {
      setLocale(stripeLocale)
    }
    setCompanyName(getCompanyName)
    setLoading(false)
  }, [])

  const getLocale = async () => {
    const selectedLanguage = await AsyncStorage.getItem('flag')

    if (selectedLanguage) {
      if (selectedLanguage === 'es') {
        return 'es-419'
      }
      if (selectedLanguage === 'en') {
        return 'en'
      }
      if (selectedLanguage === 'pt') {
        return 'pt'
      }
    } else {
      return 'en'
    }
  }

  const goToCheckout = async () => {
    setIsLoadingCard(true)
    try {
      const getOperator = await operator({
        variables: { id: operatorId },
      })

      const getBooking = await AsyncStorage.getItem('booking')

      const getAmount = await AsyncStorage.getItem('bookingAmount')

      const booking = JSON.parse(getBooking)
      const amountInformation = JSON.parse(getAmount)
      const customerBooking = booking.customer
      const stringUsersBooking = JSON.stringify(booking.meta.users)
      delete booking.meta.users
      let stringOperatorEmails = JSON.stringify([])
      if (
        booking.meta.operator_emails &&
        booking.meta.operator_emails.length > 0
      ) {
        stringOperatorEmails = JSON.stringify(booking.meta.operator_emails)
        delete booking.meta.operator_emails
      }
      const metadataBooking = booking.meta
      const reminders = booking.event_notifications
      const stringCustomerBooking = JSON.stringify(customerBooking)
      const stringMetadataBooking = JSON.stringify(metadataBooking)
      const stringReminders = JSON.stringify(reminders)

      delete booking.meta
      delete booking.customer
      delete booking.event_notifications

      const newBooking = JSON.stringify(booking)
      const urlCheckout = await axiosInstance.post(`/booking/checkout`, {
        token: token,
        operator: getOperator.data.operators_by_pk,
        amount: amountInformation.amount,
        company_id: companyId,
        currency: amountInformation.currency,
        reservationType: booking.graph,
        locale: locale,
        metadata: {
          company_id: companyId,
          operator_id: getOperator.data.operators_by_pk.id,
          customerBooking: stringCustomerBooking,
          metadataBooking: stringMetadataBooking,
          reminders: stringReminders,
          booking: newBooking,
          company_name: companyName,
          users: stringUsersBooking,
          operator_emails: stringOperatorEmails,
        },
      })
      await AsyncStorage.setItem('sessionId', urlCheckout.data.session.id)
      setIsLoadingCard(false)
      window.location.href = urlCheckout.data.session.url
    } catch (error) {
      setIsLoadingCard(false)
      openModalError()
    }
  }

  const goToBoleto = async () => {
    setIsLoadingBoleto(true)
    try {
      const getOperator = await operator({
        variables: { id: operatorId },
      })

      const getBooking = await AsyncStorage.getItem('booking')

      const getAmount = await AsyncStorage.getItem('bookingAmount')

      const booking = JSON.parse(getBooking)
      const amountInformation = JSON.parse(getAmount)
      const customerBooking = booking.customer
      const stringUsersBooking = JSON.stringify(booking.meta.users)
      delete booking.meta.users
      let stringOperatorEmails = JSON.stringify([])
      if (
        booking.meta.operator_emails &&
        booking.meta.operator_emails.length > 0
      ) {
        stringOperatorEmails = JSON.stringify(booking.meta.operator_emails)
        delete booking.meta.operator_emails
      }
      const metadataBooking = booking.meta
      const reminders = booking.event_notifications
      const stringCustomerBooking = JSON.stringify(customerBooking)
      const stringMetadataBooking = JSON.stringify(metadataBooking)
      const stringReminders = JSON.stringify(reminders)

      delete booking.meta
      delete booking.customer
      delete booking.event_notifications
      const newBooking = JSON.stringify(booking)

      const urlCheckoutBoleto = await axiosInstance.post(`/booking/boleto`, {
        token: token,
        operator: getOperator.data.operators_by_pk,
        amount: amountInformation.amount,
        company_id: companyId,
        currency: amountInformation.currency,
        reservationType: booking.graph,
        locale: locale,
        metadata: {
          company_id: companyId,
          operator_id: getOperator.data.operators_by_pk.id,
          customerBooking: stringCustomerBooking,
          metadataBooking: stringMetadataBooking,
          reminders: stringReminders,
          booking: newBooking,
          company_name: companyName,
          users: stringUsersBooking,
          operator_emails: stringOperatorEmails,
        },
      })
      await AsyncStorage.setItem('sessionId', urlCheckoutBoleto.data.session.id)
      setIsLoadingBoleto(false)
      props.history.push('/manageReservationOperator')
      window.open(`${urlCheckoutBoleto.data.session.url}`, '_blank')
    } catch (error) {
      setIsLoadingBoleto(false)
      openModalError()
    }
  }

  const createInvoiceItem = async () => {
    setIsLoadingInvoice(true)
    try {
      let daysToPay = invoiceDays
      if (invoiceDays === 0 || checkedInvoice === true) {
        daysToPay = 0
      }
      const getOperator = await operator({
        variables: { id: operatorId },
      })
      const getAmount = await AsyncStorage.getItem('bookingAmount')

      const amountInformation = JSON.parse(getAmount)

      const getCurrency = await axiosInstance.post(`/booking/currencyClient`, {
        token: token,
        operator: getOperator.data.operators_by_pk,
      })

      if (!getCurrency.data.customer_currency) {
        await axiosInstance.post(`/booking/createInvoiceItem`, {
          token: token,
          operator: getOperator.data.operators_by_pk,
          amount: amountInformation.amount,
          currency: amountInformation.currency,
          product_name: 'booking',
          days_until_due: daysToPay,
        })
        setIsLoadingInvoice(false)
        closeModalInvoice()
        await props.history.push('/invoiceBookingConfirmation')
      } else {
        if (amountInformation.currency === getCurrency.data.customer_currency) {
          await axiosInstance.post(`/booking/createInvoiceItem`, {
            token: token,
            operator: getOperator.data.operators_by_pk,
            amount: amountInformation.amount,
            currency: amountInformation.currency,
            product_name: 'booking',
            days_until_due: daysToPay,
          })
          setIsLoadingInvoice(false)
          closeModalInvoice()
          await props.history.push('/invoiceBookingConfirmation')
        } else {
          setIsLoadingInvoice(false)
          setError(t('paymentMethods.errorMonthlyInvoices'))
          openModalError()
        }
      }
    } catch (error) {
      setIsLoadingInvoice(false)
      openModalError()
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setIsLoadingFastPass(true)
      const getBooking = await AsyncStorage.getItem('booking')
      const booking = JSON.parse(getBooking)
      const updateMeta = {
        ...booking.meta,
        operator_id: operatorId,
        stripe: 'Paid-code',
      }
      booking.meta = updateMeta
      const createdBooking = await axiosInstance.post(`/bookings/fastPass`, {
        code: data.code,
        bookingInformation: booking,
      })
      setIsLoadingFastPass(false)
      closeModal()
      props.history.push(
        `bookingFastCode?booking=${createdBooking.data.booking.id}`
      )
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setError(t('paymentMethods.errorReservation'))
      } else {
        setError(
          error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
            ? error.response.data.error.message
            : error.message
        )
      }
      reset()
      setIsLoadingFastPass(false)
      openModalError()
    }
  }

  const onFastPass = async () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisible(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setIsVisible(false)
  }

  const openModalInvoice = async () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleModalInvoice(true)
  }

  const closeModalInvoice = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleModalInvoice(false)
  }

  const openModalError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModalError = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const goToBack = () => {
    props.history.goBack()
  }

  if (loading) {
    return <Loading />
  }

  return (
    <View>
      <AppBarHeader />
      <Center>
        <VStack>
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            isDisabled={heliportPrice === 0}
            onPress={() => goToCheckout()}
            rounded="2xl"
            isLoading={isLoadingCard}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
          >
            {t('paymentMethods.creditCard')}
          </Button>
          <br />
          {heliportCountry === 'Brazil' ? (
            <View>
              <Button
                colorScheme="yellow"
                size="md"
                maxWidth="200"
                isDisabled={isDisabled}
                onPress={() => goToBoleto()}
                rounded="2xl"
                isLoading={isLoadingBoleto}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('paymentMethods.boleto')}
              </Button>
              <br />
            </View>
          ) : (
            <View />
          )}
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            onPress={() => openModalInvoice()}
            rounded="2xl"
            isDisabled={heliportPrice === 0}
          >
            {t('paymentMethods.monthlyInvoice')}
          </Button>
          <br />
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            onPress={() => onFastPass()}
            rounded="2xl"
          >
            <HStack
              style={{ alignItems: 'center', justifyContent: 'center' }}
              space={2}
            >
              <Text fontWeight="medium">{t('paymentMethods.fastPass')}</Text>
              <Image
                source={{
                  uri: login,
                }}
                alt="Alternate Text"
                style={{ width: 20, height: 20 }}
              />
            </HStack>
          </Button>
          <br />
          <Button
            colorScheme="red"
            size="md"
            maxWidth="200"
            onPress={() => {
              goToBack()
            }}
            rounded="2xl"
          >
            {t('paymentMethods.back')}
          </Button>
        </VStack>
      </Center>
      <Modal isOpen={visible} onClose={() => closeModal()}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('paymentMethods.passCode.title')}</Modal.Header>
          <Modal.Body>
            <Text fontSize="md" p="2">
              {t('paymentMethods.passCode.introduceCode')}
            </Text>
            <Text bold fontSize="s" p="2">
              {t('paymentMethods.passCode.code')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  borderRadius="2xl"
                  placeholder="Code"
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  borderWidth={1}
                />
              )}
              name="code"
            />
            {errors.code && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {t('paymentMethods.passCode.required')}
              </Text>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal()
                }}
              >
                {t('paymentMethods.passCode.cancel')}
              </Button>
              <Button
                colorScheme="yellow"
                onPress={handleSubmit(onSubmit)}
                rounded="2xl"
                isLoading={isLoadingFastPass}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('paymentMethods.passCode.send')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={visibleModalInvoice} onClose={() => closeModalInvoice()}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('paymentMethods.monthlyInvoice')}</Modal.Header>
          <Modal.Body>
            <HStack space={3}>
              <Text fontSize="s" p="2">
                {t('invoice.sendInvoice')}
              </Text>
              <Center>
                <Checkbox
                  value={checkedInvoice}
                  onChange={() => {
                    setCheckedInvoice(true)
                  }}
                  accessibilityLabel="choose parking"
                  colorScheme="yellow"
                />
              </Center>
            </HStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModalInvoice()
                }}
              >
                {t('paymentMethods.passCode.cancel')}
              </Button>
              <Button
                colorScheme="yellow"
                size="md"
                maxWidth="200"
                onPress={() => createInvoiceItem()}
                rounded="2xl"
                isLoading={isLoadingInvoice}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('paymentMethods.passCode.send')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={show}>
        <Alert status="error" maxWidth={400}>
          <VStack space={1} flexShrink={1}>
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentMethods.selectOtherMethod')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => closeModalError()}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              {error}
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}

export default PaymentMethods
