import React from 'react'
import AppBarHeader from '../components/AppBar'
import { View, Modal, HStack, Button, Text } from 'native-base'
import { TIMEKIT_API_KEY } from '@env'
import { useLocation, useHistory } from '../routing'
import { getToken } from '../Token'
import { GET_MANAGERS_BY_PROJECT, GET_COMPANY_USERS } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { storage } from '../utils/nhost'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useTranslation } from 'react-i18next'

var TimekitBooking = require('timekit-booking')

export const ScheduleHeliport = (props) => {
  const { t } = useTranslation()
  const [user, setUserId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [modalAccount, setModalAccount] = React.useState(false)
  let history = useHistory()

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  const [managers] = useLazyQuery(GET_MANAGERS_BY_PROJECT)

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS)

  React.useEffect(() => {
    var widget = new TimekitBooking()
    initWidget(widget)
    return () => {
      widget.destroy()
    }
  }, [])

  const getUsersEmail = async (managersCompany) => {
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    return usersEmails
  }

  const initWidget = async (widget) => {
    const w = window.innerWidth

    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const userRoles =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
    const timekitProjectId = query.get('heliport')
    const roleOperator = userRoles.filter((role) => {
      if (role === 'operator') {
        return role
      }
    })

    if (roleOperator.length > 0 && roleOperator[0] === 'operator') {
      const company = query.get('company')

      const companyManagers = await managers({
        variables: { id: company },
      })

      const getBooking = query.get('booking')

      const booking = getBooking ? getBooking : ''

      const getReschedule = query.get('reschedule')

      const reschedule = getReschedule ? getReschedule : false
      let urlImage = ''
      if (
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .heliport_image
      ) {
        const fileId =
          companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .heliport_image.file_id
        const getUrl = await storage.getPresignedUrl({ fileId })
        urlImage = getUrl.presignedUrl.url
      } else {
        urlImage = `https://cdn.pixabay.com/photo/2017/03/04/14/19/helicopter-2116170_1280.jpg`
      }

      const timeZone =
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .timezone
      widget.init({
        app_key: TIMEKIT_API_KEY,
        project_id: timekitProjectId,
        fullcalendar: {
          defaultView: w <= 480 ? 'listWeek' : 'basicWeek',
          header: {
            left: '',
            center: 'title,basicDay,basicWeek',
            right: 'prev, next',
          },
          views: {
            basicDay: { buttonText: t('calendarView.day') },
            basicWeek: { buttonText: t('calendarView.week') },
          },
          locale: t('calendarView.locale'),
        },

        disable_confirm_page: true,
        callbacks: {
          clickTimeslot: async function (timeslot) {
            const newTimeslot = {
              resources: timeslot.resources,
              start: timeslot.start._i,
              end: timeslot.end._i,
            }

            const jsonValue = JSON.stringify(newTimeslot)

            await AsyncStorage.setItem('timeslot', jsonValue)

            if (reschedule) {
              props.history.push(
                `/bookingForm?company=${company}&booking=${booking}&reschedule=true`
              )
            } else {
              props.history.push(`/bookingForm?company=${company}`)
            }
          },
        },
        ui: {
          time_date_format: '24h-dmy-mon',
          timezone: timeZone,
          avatar: `${urlImage}`,
        },
      })
    } else {
      const company = query.get('company')
      const getPropose = query.get('propose')
      const getGraph = query.get('graph')
      const getBooking = query.get('booking')
      const booking = getBooking ? getBooking : ''
      const proposeNewDate = getPropose ? getPropose : false
      const graph = getGraph ? getGraph : ''
      const companyManagers = await managers({
        variables: { id: company },
      })

      const usersCompanyManagers =
        companyManagers.data.company_by_pk.users_companies

      const getCompanyUsersEmails = await getUsersEmail(usersCompanyManagers)
      let urlImage = ''
      if (
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .heliport_image
      ) {
        const fileId =
          companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .heliport_image.file_id
        const getUrl = await storage.getPresignedUrl({ fileId })
        urlImage = getUrl.presignedUrl.url
      } else {
        urlImage = `https://cdn.pixabay.com/photo/2017/03/04/14/19/helicopter-2116170_1280.jpg`
      }

      const timeZone =
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .timezone
      if (companyManagers.data.company_by_pk.account_stripe_id === null) {
        setModalAccount(true)
      } else {
        if (
          companyManagers.data.company_by_pk.heliports[0].helipads.length > 0 &&
          companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .heliport_aviabilities.length > 0
        ) {
          widget.init({
            app_key: TIMEKIT_API_KEY,
            project_id: timekitProjectId,
            fullcalendar: {
              defaultView: w <= 480 ? 'listWeek' : 'basicWeek',
              header: {
                left: '',
                center: 'title,month,basicDay,basicWeek',
                right: 'prev, next',
              },
              views: {
                month: { buttonText: t('calendarView.month') },
                basicDay: { buttonText: t('calendarView.day') },
                basicWeek: { buttonText: t('calendarView.week') },
              },
              locale: t('calendarView.locale'),
            },
            disable_confirm_page: true,
            callbacks: {
              clickTimeslot: async function (timeslot) {
                const newTimeslot = {
                  resources: timeslot.resources,
                  start: timeslot.start._i,
                  end: timeslot.end._i,
                }

                const jsonValue = JSON.stringify(newTimeslot)

                await AsyncStorage.setItem('timeslot', jsonValue)
                if (proposeNewDate) {
                  props.history.push(
                    `/rescheduleDateAndTime?company=${company}&booking=${booking}&graph=${graph}`
                  )
                } else {
                  props.history.push(`/manualReservation?company=${company}`)
                }
              },
            },
            ui: {
              time_date_format: '24h-dmy-mon',
              timezone: timeZone,
              avatar: `${urlImage}`,
            },
          })
        } else {
          setShow(true)
        }
      }
    }
  }

  return (
    <View>
      <AppBarHeader />
      <Modal isOpen={show} flex={1}>
        <Modal.Content>
          <Modal.Header>{t('errorManager.completeInformation')}</Modal.Header>
          <Modal.Body>
            <Text p={8}>{t('errorManager.message')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={3}>
              <Button
                bgColor="yellow.300"
                size="md"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => {
                  props.history.push('/manageHeliport')
                }}
              >
                {t('errorManager.button')}
              </Button>
              <Button
                bgColor="coolGray.300"
                size="md"
                _text={{
                  color: 'coolgray.800',
                }}
                onPress={() => props.history.push('/')}
              >
                {t('errorManager.cancel')}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal isOpen={modalAccount} flex={1} rounded="2xl">
        <Modal.Content>
          <Modal.Header>{t('reservations.reminder')}</Modal.Header>
          <Modal.Body>
            <Text>{t('reservations.reminderMessage')}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                colorScheme="yellow"
                rounded="2xl"
                onPress={() => {
                  props.history.push('/managerAccount')
                }}
              >
                {t('reservations.saveButton')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  )
}
export default ScheduleHeliport
