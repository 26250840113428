import * as React from 'react'
import {
  Box,
  View,
  Button,
  Center,
  VStack,
  Modal,
  Alert,
  HStack,
  IconButton,
  CloseIcon,
  Text,
  Checkbox,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useLocation } from '../routing'
import { auth } from '../utils/nhost'
import { GET_OPERATOR_BY_ID, GET_OPERATOR } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../Token'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const ManualReservationPayment = (props) => {
  const [loading, setLoading] = React.useState(true)
  const [companyId, setCompanyId] = React.useState('')
  const [operatorId, setOperatorId] = React.useState('')
  const [token, setToken] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [bookingId, setBookingId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [isLoadingCard, setIsLoadingCard] = React.useState(false)
  const [isLoadingBoleto, setIsLoadingBoleto] = React.useState(false)
  const [isLoadingInvoice, setIsLoadingInvoice] = React.useState(false)
  const [error, setError] = React.useState('')
  const { t } = useTranslation()
  const [locale, setLocale] = React.useState('en')

  const [operatorInformation] = useLazyQuery(GET_OPERATOR)

  const [operator] = useLazyQuery(GET_OPERATOR_BY_ID)

  const [invoiceDays, setInvoiceDays] = React.useState(30)

  const [visibleModalInvoice, setIsVisibleModalInvoice] = React.useState(false)

  const [checkedInvoice, setCheckedInvoice] = React.useState(false)

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  React.useEffect(async () => {
    const getTokenUser = auth.getJWTToken()

    setToken(getTokenUser)

    const getCompanyId = query.get('company')

    setCompanyId(getCompanyId)

    const booking = query.get('booking')

    setBookingId(booking)

    const bookingCurrency = await axiosInstance.post(`/bookings/bookingById`, {
      id: booking,
    })

    if (
      bookingCurrency.data &&
      bookingCurrency.data.booking &&
      bookingCurrency.data.booking &&
      bookingCurrency.data.booking.meta.days_until_due
    ) {
      setInvoiceDays(bookingCurrency.data.booking.meta.days_until_due)
    }

    const currency = bookingCurrency.data.booking.meta.currency

    if (currency !== 'brl') {
      setIsDisabled(true)
    }

    const tokenInformation = getToken()

    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']

    const getOperator = await operatorInformation({
      variables: { userId: userId },
    })

    const getOperatorId = getOperator.data.operators[0].id

    setOperatorId(getOperatorId)

    const getCompanyName = query.get('company_name')

    setCompanyName(getCompanyName)

    const stripeLocale = await getLocale()

    if (stripeLocale) {
      setLocale(stripeLocale)
    }

    setLoading(false)
  }, [])

  const getLocale = async () => {
    const selectedLanguage = await AsyncStorage.getItem('flag')

    if (selectedLanguage) {
      if (selectedLanguage === 'es') {
        return 'es-419'
      }
      if (selectedLanguage === 'en') {
        return 'en'
      }
      if (selectedLanguage === 'pt') {
        return 'pt'
      }
    } else {
      return 'en'
    }
  }

  const goToCheckout = async () => {
    setIsLoadingCard(true)
    try {
      const getOperator = await operator({
        variables: { id: operatorId },
      })
      const booking = await axiosInstance.post(`/bookings/bookingById`, {
        id: bookingId,
      })
      const urlCheckout = await axiosInstance.post(
        `/booking/checkoutManualReservation`,
        {
          token: token,
          operator: getOperator.data.operators_by_pk,
          amount: parseFloat(booking.data.booking.meta.price) * 100,
          company_id: companyId,
          currency: booking.data.booking.meta.currency,
          locale: locale,
          metadata: {
            company_id: companyId,
            operator_id: getOperator.data.operators_by_pk.id,
            booking: bookingId,
            company_name: companyName,
            manual_reservation: booking.data.booking.meta.manual_reservation,
          },
        }
      )

      setIsLoadingCard(false)
      window.location.href = urlCheckout.data.session.url
    } catch (error) {
      openModalError()
    }
  }

  const goToBoleto = async () => {
    setIsLoadingBoleto(true)

    try {
      const getOperator = await operator({
        variables: { id: operatorId },
      })
      const booking = await axiosInstance.post(`/bookings/bookingById`, {
        id: bookingId,
      })

      const urlCheckoutBoleto = await axiosInstance.post(
        `/booking/checkoutManualReservationBoleto`,
        {
          token: token,
          operator: getOperator.data.operators_by_pk,
          amount: parseFloat(booking.data.booking.meta.price) * 100,
          company_id: companyId,
          currency: booking.data.booking.meta.currency,
          locale: locale,
          metadata: {
            company_id: companyId,
            operator_id: getOperator.data.operators_by_pk.id,
            booking: bookingId,
            company_name: companyName,
            manual_reservation: booking.data.booking.meta.manual_reservation,
          },
        }
      )
      setIsLoadingCard(false)
      window.open(`${urlCheckoutBoleto.data.session.url}`, '_blank')
      props.history.push('/manageReservationOperator')
    } catch (error) {
      openModalError()
    }
  }

  const createInvoiceItem = async () => {
    setIsLoadingInvoice(true)
    try {
      let daysToPay = invoiceDays

      if (invoiceDays === 0 || checkedInvoice === true) {
        daysToPay = 0
      }

      const getOperator = await operator({
        variables: { id: operatorId },
      })

      const booking = await axiosInstance.post(`/bookings/bookingById`, {
        id: bookingId,
      })

      const currency = booking.data.booking.meta.currency

      const getCurrency = await axiosInstance.post(`/booking/currencyClient`, {
        token: token,
        operator: getOperator.data.operators_by_pk,
      })

      if (!getCurrency.data.customer_currency) {
        await axiosInstance.post(`/booking/createInvoiceItem`, {
          token: token,
          operator: getOperator.data.operators_by_pk,
          amount: parseFloat(booking.data.booking.meta.price) * 100,
          currency: booking.data.booking.meta.currency,
          product_name: 'booking',
          days_until_due: daysToPay,
        })
        setIsLoadingInvoice(false)
        closeModalInvoice()
        await props.history.push(
          `/manualReservationInvoiceConfirmation?booking=${booking.data.booking.id}&operator=${operatorId}`
        )
      } else {
        if (currency === getCurrency.data.customer_currency) {
          await axiosInstance.post(`/booking/createInvoiceItem`, {
            token: token,
            operator: getOperator.data.operators_by_pk,
            amount: parseFloat(booking.data.booking.meta.price) * 100,
            currency: booking.data.booking.meta.currency,
            product_name: 'booking',
            days_until_due: daysToPay,
          })
          setIsLoadingInvoice(false)
          closeModalInvoice()
          await props.history.push(
            `/manualReservationInvoiceConfirmation?booking=${booking.data.booking.id}&operator=${operatorId}`
          )
        } else {
          setIsLoadingInvoice(false)
          setError(t('paymentMethods.errorMonthlyInvoices'))
          openModalError()
        }
      }
    } catch (error) {
      setIsLoadingInvoice(false)
      openModalError()
    }
  }

  const openModalError = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModalError = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const openModalInvoice = async () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsVisibleModalInvoice(true)
  }

  const closeModalInvoice = () => {
    document.body.style.overflow = 'scroll'
    setIsVisibleModalInvoice(false)
  }

  if (loading) return <Text>Loading...</Text>
  return (
    <View>
      <AppBarHeader />
      <Center>
        <VStack space={3}>
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            onPress={() => goToCheckout()}
            rounded="2xl"
            isLoading={isLoadingCard}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
          >
            {t('paymentMethods.creditCard')}
          </Button>
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            isDisabled={isDisabled}
            onPress={() => goToBoleto()}
            rounded="2xl"
            isLoading={isLoadingBoleto}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
          >
            {t('paymentMethods.boleto')}
          </Button>
          <Button
            colorScheme="yellow"
            size="md"
            maxWidth="200"
            onPress={() => openModalInvoice()}
            rounded="2xl"
          >
            {t('paymentMethods.monthlyInvoice')}
          </Button>
        </VStack>
      </Center>
      <Modal isOpen={show}>
        <Alert status="error" maxWidth={400}>
          <VStack space={1} flexShrink={1}>
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('paymentMethods.selectOtherMethod')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => closeModalError()}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              {error}
            </Box>
          </VStack>
        </Alert>
      </Modal>
      <Modal isOpen={visibleModalInvoice} onClose={() => closeModalInvoice()}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('paymentMethods.monthlyInvoice')}</Modal.Header>
          <Modal.Body>
            <HStack space={3}>
              <Text fontSize="s" p="2">
                {t('invoice.sendInvoice')}
              </Text>
              <Center>
                <Checkbox
                  value={checkedInvoice}
                  onChange={() => {
                    setCheckedInvoice(true)
                  }}
                  accessibilityLabel="choose parking"
                  colorScheme="yellow"
                />
              </Center>
            </HStack>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModalInvoice()
                }}
              >
                {t('paymentMethods.passCode.cancel')}
              </Button>
              <Button
                colorScheme="yellow"
                size="md"
                maxWidth="200"
                onPress={() => createInvoiceItem()}
                rounded="2xl"
                isLoading={isLoadingInvoice}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('paymentMethods.passCode.send')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  )
}

export default ManualReservationPayment
