import React from 'react'
import {
  HStack,
  View,
  Box,
  Text,
  VStack,
  Center,
  Input,
  Select,
  Button,
  NativeBaseProvider,
  Stack,
  Image,
  Pressable,
  CheckIcon,
  Modal,
  IconButton,
  Alert,
  CloseIcon,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useForm, Controller } from 'react-hook-form'
import { GET_MANAGERS_BY_PROJECT, GET_COMPANY_USERS } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getToken } from '../Token'
import { useLocation } from '../routing'
import { SERVER_SENGRID } from '@env'
import PhoneInput from 'react-phone-input-2'
import button_plus from '../assets/button_plus.png'
import minus_icon from '../assets/minus_icon.png'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../AxiosInstance'
import phoneLanguage from '../PhoneLanguage'
import moment from 'moment-timezone'
import Loading from '../components/Loading'

export const ManualReservatiosnForm = (props) => {
  const [bookingDate, setBookingDate] = React.useState('')
  const [loading, setIsLoading] = React.useState(true)
  const [bookingHourStart, setBookingHourStart] = React.useState('')
  const [bookingHourEnd, setBookingHourEnd] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [companyId, setCompanyId] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [timeslotInformation, setTimeslotInformation] = React.useState({})
  const [bookingStart, setBookingStart] = React.useState('')
  const [bookingEnd, setBookingEnd] = React.useState('')
  const [projectId, setProjectId] = React.useState('')
  const [amountPrice, setAmountPrice] = React.useState(0)
  const [currency, setCurrency] = React.useState('')
  const [selectedCallingCode, setSelectedCallingCode] = React.useState('')
  const [timezone, setTimezone] = React.useState('')
  const [formValuesDisembarking, setFormValuesDisembarking] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])
  const [formValuesEmbarking, setFormValuesEmbarking] = React.useState([
    { firstName: '', lastName: '', id: '', documentType: '' },
  ])
  const [isLoadingButton, setIsLoadingButton] = React.useState(false)
  const [locale, setLocale] = React.useState('')
  const [errormanualReservation, setErrormanualReservations] =
    React.useState('')
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const [heliportPhone, setHeliportPhone] = React.useState('')
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [errorNumber, setErrorNumber] = React.useState('')
  const [flightNumber, setFlightNumber] = React.useState('')
  const [userPhones, setUserPhones] = React.useState([])
  const [invoiceDays, setInvoiceDays] = React.useState(30)

  const { t } = useTranslation()

  const days = [
    { day: 'sunday' },
    { day: 'monday' },
    { day: 'tuesday' },
    { day: 'wednesday' },
    { day: 'thursday' },
    { day: 'friday' },
    { day: 'saturday' },
  ]

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  const [managers] = useLazyQuery(GET_MANAGERS_BY_PROJECT)

  const [companyUsers] = useLazyQuery(GET_COMPANY_USERS)

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const getTimeslot = await AsyncStorage.getItem('timeslot')

    const timeslot = JSON.parse(getTimeslot)

    const getCompanyName = timeslot.resources[0].name

    setCompanyName(getCompanyName)

    const company = query.get('company')

    setCompanyId(company)

    const companyManagers = await managers({
      variables: { id: company },
    })

    const usersCompanyManagers =
      companyManagers.data.company_by_pk.users_companies

    const heliportTimeZone =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile.timezone

    const emailsUsers =
      companyManagers.data.company_by_pk.email_users &&
      companyManagers.data.company_by_pk.email_users.length > 0
        ? companyManagers.data.company_by_pk.email_users
        : []

    setTimezone(heliportTimeZone)

    const getCompanyUsersEmails = await getUsersEmail(
      usersCompanyManagers,
      emailsUsers
    )

    setUsers(getCompanyUsersEmails)

    const companyPhones =
      companyManagers.data.company_by_pk.phone_users &&
      companyManagers.data.company_by_pk.phone_users.length > 0
        ? companyManagers.data.company_by_pk.phone_users
        : []

    const getCompanyPhones = getPhoneUsers(companyPhones)

    setUserPhones(getCompanyPhones)

    const getProjectId =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile
        .timekit_project_id

    setProjectId(getProjectId)

    const heliportProfilePhone =
      companyManagers.data.company_by_pk.heliports[0].heliport_profile.phone

    setHeliportPhone(heliportProfilePhone)

    const days_until_due =
      companyManagers.data.company_by_pk &&
      companyManagers.data.company_by_pk.heliports.length > 0 &&
      companyManagers.data.company_by_pk.heliports[0].heliport_profile
        .days_until_due
        ? companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .days_until_due
        : 30

    setInvoiceDays(days_until_due)

    setTimeslotInformation(timeslot)
    if (timeslot) {
      const bookingStartDate = timeslot.start

      const start = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .locale('en')
        .day()

      setBookingStart(bookingStartDate)

      const bookingEndDate = timeslot.end

      setBookingEnd(bookingEndDate)

      const date = moment(bookingStartDate).tz(heliportTimeZone).format('LL')

      const bookingStart = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('HH:mm')

      const bookingStartToCompare = moment(bookingStartDate)
        .tz(heliportTimeZone)
        .format('HH:mm:ss')

      const bookingEnd = moment(bookingEndDate)
        .tz(heliportTimeZone)
        .format('HH:mm')

      const bookingEndToCompare = moment(bookingEndDate)
        .tz(heliportTimeZone)
        .format('HH:mm:ss')

      if (
        companyManagers.data.company_by_pk.heliports[0].heliport_profile
          .heliport_aviabilities
      ) {
        const day = getDay(start)

        getAmount(
          companyManagers.data.company_by_pk.heliports[0].heliport_profile
            .heliport_aviabilities,
          day,
          bookingStartToCompare,
          bookingEndToCompare
        )
      }

      setBookingDate(date)
      setBookingHourStart(bookingStart)
      setBookingHourEnd(bookingEnd)
    }
    const currentLocale = await AsyncStorage.getItem('i18nextLng')
    setLocale(currentLocale)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
    setIsLoading(false)
  }, [])

  const replaceWhitespace = (str) => {
    let res = ''
    const { length } = str
    for (let i = 0; i < length; i++) {
      const char = str[i]
      if (!(char === ' ')) {
        res += char
      } else {
        res += '%20'
      }
    }
    return res
  }

  const getUsersEmail = async (managersCompany, emailsUsers) => {
    let emails = []
    const usersEmails = await Promise.all(
      managersCompany.map(async (user) => {
        const managerUser = await companyUsers({
          variables: { id: user.user_id },
        })
        return managerUser.data.user.email
      })
    )
    if (emailsUsers.length > 0) {
      const emailUsersExtra = emailsUsers.map((email) => {
        return email.email
      })
      emails = emailUsersExtra
    }
    const companyUsersEmails = [...emails, ...usersEmails]
    return companyUsersEmails
  }

  const getPhoneUsers = (phones) => {
    if (phones.length > 0) {
      const companyPhoneUsers = phones.map((phone) => {
        return phone.phone_number
      })
      return companyPhoneUsers
    } else {
      return []
    }
  }

  const getDay = (date) => {
    const day = days[date].day
    return day
  }

  const getAmount = (aviablilities, day, bookingStart, bookingEnd) => {
    const newArrayAviabilities = aviablilities.map((a) => {
      return {
        ...a,
        startBookingHour: passStringHourToNumber(a.start_hour),
        endBookingHour: passStringHourToNumber(a.end_hour),
      }
    })

    const newBookingStart = passStringHourToNumber(bookingStart)

    const newBookingEnd = passStringHourToNumber(bookingEnd)
    const getAviability = newArrayAviabilities.filter((a) => {
      return (
        a.day === day &&
        newBookingStart >= a.startBookingHour &&
        newBookingEnd <= a.endBookingHour
      )
    })

    setAmountPrice(parseInt(getAviability[0].price) * 100)
    setCurrency(getAviability[0].currency)
  }

  const passStringHourToNumber = (time) => {
    var array = time.split(':')
    var seconds =
      parseInt(array[0], 10) * 60 * 60 +
      parseInt(array[1], 10) * 60 +
      parseInt(array[2], 10)
    return seconds
  }

  let addFormFields = () => {
    setFormValuesDisembarking([
      ...formValuesDisembarking,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues.splice(i, 1)
    setFormValuesDisembarking(newFormValues)
  }

  let handleChangeFirstName = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].firstName = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeLastName = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].lastName = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeId = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].id = e.target.value
    setFormValuesDisembarking(newFormValues)
  }
  let handleChangeDocumentType = (i, e) => {
    let newFormValues = [...formValuesDisembarking]
    newFormValues[i].documentType = e
    setFormValuesDisembarking(newFormValues)
  }
  let addFormFieldsEmbarking = () => {
    setFormValuesEmbarking([
      ...formValuesEmbarking,
      {
        firstName: '',
        lastName: '',
        id: '',
        documentType: '',
      },
    ])
  }

  let removeFormFieldsEmbarking = (i) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues.splice(i, 1)
    setFormValuesEmbarking(newFormValues)
  }

  let handleChangeFirstNameEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].firstName = e.target.value
    setFormValuesEmbarking(newFormValues)
  }
  let handleChangeLastNameEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].lastName = e.target.value
    setFormValuesEmbarking(newFormValues)
  }
  let handleChangeIdEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].id = e.target.value
    setFormValuesEmbarking(newFormValues)
  }
  let handleChangeDocumentTypeEmbarking = (i, e) => {
    let newFormValues = [...formValuesEmbarking]
    newFormValues[i].documentType = e
    setFormValuesEmbarking(newFormValues)
  }

  const openModalErrorManualReservations = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setIsOpenModal(true)
  }

  const closeModalErrorManualRservation = () => {
    document.body.style.overflow = 'scroll'
    setIsOpenModal(false)
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({})

  const onSubmit = async (booking) => {
    if (phoneValid === true) {
      setIsLoadingButton(true)
      const newUrlCompanyName = replaceWhitespace(companyName)
      const getDisembarkingPassengers = disembarkingPassengers(
        formValuesDisembarking
      )
      const getEmbarkingPassengers = embarkingPassengers(formValuesEmbarking)
      const newBooking = {
        certificate_number: booking.certificate_number,
        email: booking.email,
        model: booking.model,
        name: booking.name,
        next_destination: booking.next_destination,
        origin_flight: booking.origin_flight,
        pilot_name: `${booking.pilot_first_name} ${booking.pilot_last_name}`,
        tail_number: booking.tail_number,
        disembarking_passengers: getDisembarkingPassengers,
        embarking_passengers: getEmbarkingPassengers,
      }

      const bookingInformation = {
        project_id: projectId,
        resource_id: timeslotInformation.resources[0].id,
        graph: 'confirm_decline',
        start: bookingStart,
        end: bookingEnd,
        what: timeslotInformation.resources[0].name,
        where: 'Booking',
        description: 'Booking Heliport',
        customer: newBooking,
        meta: {
          users: users,
          user_phones: userPhones,
          payment_intent_id: '',
          phone: selectedCallingCode,
          model: booking.model,
          price: parseFloat(amountPrice) / 100,
          currency: currency ? currency : 'brl',
          certificate_number: booking.certificate_number,
          manual_reservation: true,
          company_name: newUrlCompanyName,
          company_id: companyId,
          timezone: timezone,
          locale: locale,
          stripe: 'unpaid',
          phone_manager: heliportPhone,
          copilot_name: 'None',
          flight_number: flightNumber ? flightNumber : 'None',
          total_passenger: booking.total_passengers
            ? parseInt(booking.total_passengers)
            : '0',
          days_until_due: invoiceDays,
        },
        event_notifications: [
          {
            type: 'webhook',
            settings: {
              url: `${SERVER_SENGRID}/booking/reminders`,
              method: 'post',
              expected_response_code: 200,
            },
            when: {
              type: 'before',
              unit: 'hours',
              time: 2,
            },
          },
        ],
      }
      try {
        await axiosInstance.post(`/bookings/manualReservations`, {
          bookingInformation: bookingInformation,
        })
        setIsLoadingButton(false)
        props.history.push('/')
      } catch (error) {
        setIsLoadingButton(false)
        if (error.response.data.errors) {
          setErrormanualReservations('Sorry, we can make your reservation')
        } else {
          setErrormanualReservations(error.response.data.error.message)
        }
        openModalErrorManualReservations()
      }
    } else {
      setErrorNumber(t('errorPhoneNumber'))
      setIsLoadingButton(false)
    }
  }

  const disembarkingPassengers = (disembarking) => {
    if (disembarking[0].firstName) {
      const disembarkingNames = disembarking.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const disembarkingToString = disembarkingNames.toString()

      return disembarkingToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const embarkingPassengers = (embarking) => {
    if (embarking[0].firstName) {
      const embarkingNames = embarking.map((passenger) => {
        return `${passenger.firstName} ${passenger.lastName} - ${passenger.documentType}: ${passenger.id}`
      })
      const embarkingToString = embarkingNames.toString()

      return embarkingToString.replaceAll(',', '\n')
    } else {
      return 'None'
    }
  }

  const goToBack = () => {
    props.history.goBack()
  }

  const placeholderPhone = () => {
    if (selectedLanguage === 'br') {
      return '55 18 391231233'
    } else if (selectedLanguage === 'us') {
      return '1 (704) 123-4567'
    } else if (selectedLanguage === 'mx') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }

  if (loading) return <Loading />
  return (
    <View>
      <AppBarHeader />
      <NativeBaseProvider>
        <View pr={['2%', '2%', '20%', '30%']} pl={['2%', '2%', '20%', '30%']}>
          <Box bgColor="#fbfbfb" rounded="xl" shadow="9" p="10">
            <Center>
              <VStack space={2} alignItems="center">
                <Text fontSize="3xl">{bookingDate}</Text>
                <HStack space={3}>
                  <Text fontSize="md">{bookingHourStart}</Text>
                  <Text>-</Text>
                  <Text fontSize="md">{bookingHourEnd}</Text>
                </HStack>
                <Text fontSize="xs">{t('bookingMannualyForm.with')}</Text>
                <Text fontSize="md">{companyName}</Text>
              </VStack>
            </Center>
            <View pr="2%" pl="2%">
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.name')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                    message: t('bookingMannualyForm.onlyLetters'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.name')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue={''}
                name="name"
              />
              {errors.name && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.name.message
                    ? errors.name.message
                    : t('bookingMannualyForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.email')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.email')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue={''}
                name="email"
              />
              {errors.email && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.email.message
                    ? errors.email.message
                    : t('bookingMannualyForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.phone')}
              </Text>
              <View>
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderColor: '#E3E3E3',
                  }}
                  buttonStyle={{ borderColor: '#D4D4D4' }}
                  country={selectedLanguage}
                  masks={{ br: '.. .........' }}
                  placeholder={placeholderPhone()}
                  value={selectedCallingCode}
                  onChange={(phone) => setSelectedCallingCode(phone)}
                  isValid={(value, country) => {
                    if (
                      value.length > 1 &&
                      country.name === 'United States' &&
                      value.length < 11
                    ) {
                      setPhoneValid(false)
                    } else if (
                      value.length > 2 &&
                      country.name === 'Brazil' &&
                      value.length < 12
                    ) {
                      setPhoneValid(false)
                    } else {
                      setPhoneValid(true)
                      return true
                    }
                  }}
                />
                {phoneValid === true ? (
                  <View />
                ) : (
                  <View zIndex="-2">
                    <Text fontSize="xs" color="#eb0100">
                      {errorNumber}
                    </Text>
                  </View>
                )}
              </View>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.flightNumber')}
              </Text>
              <Input
                borderRadius="2xl"
                bgColor="white"
                placeholder={t('bookingOperatorForm.flightNumber')}
                onChangeText={(value) => setFlightNumber(value)}
              />
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingOperatorForm.totalPassenger')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t('heliportForm.numbers'),
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    w={['60%', '60%', '40%', '40%']}
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingOperatorForm.totalPassenger')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="total_passengers"
              />
              {errors.total_passengers && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {errors.total_passengers.message}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.disembarkingPassengers')}
              </Text>
              <View zIndex="-2">
                {formValuesDisembarking.map((element, index) => (
                  <Stack
                    space={1}
                    key={index}
                    p="1%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingMannualyForm.firstName')}
                      value={element.firstName || ''}
                      onChange={(e) => handleChangeFirstName(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingMannualyForm.lastName')}
                      value={element.lastName || ''}
                      onChange={(e) => handleChangeLastName(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    {locale === 'pt' ? (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingMannualyForm.document')}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item label="Passporte" value="Passporte" />
                        <Select.Item label="CPF" value="CPF" />
                        <Select.Item label="RG" value="RG" />
                      </Select>
                    ) : (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingMannualyForm.document')}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentType(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item
                          label={t('bookingMannualyForm.passport')}
                          value="Passport"
                        />
                        <Select.Item
                          label={t('bookingMannualyForm.driversLicense')}
                          value="Driver's License"
                        />
                      </Select>
                    )}
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="ID"
                      value={element.id || ''}
                      onChange={(e) => handleChangeId(index, e)}
                      width={['100%', '100%', '100%', '40%']}
                    />

                    {index ? (
                      <Pressable
                        onPress={() => removeFormFields(index)}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Image
                          source={{
                            uri: minus_icon,
                          }}
                          alt="Alternate Text"
                          style={{ width: 20, height: 20 }}
                        />
                      </Pressable>
                    ) : null}

                    <Pressable
                      onPress={() => addFormFields()}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: button_plus,
                        }}
                        alt="Alternate Text"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </Stack>
                ))}
              </View>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.embarkingPassengers')}
              </Text>
              <View zIndex="-2">
                {formValuesEmbarking.map((element, index) => (
                  <Stack
                    space={1}
                    key={index}
                    p="1%"
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingMannualyForm.firstName')}
                      value={element.firstName || ''}
                      onChange={(e) => handleChangeFirstNameEmbarking(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder={t('bookingMannualyForm.lastName')}
                      value={element.lastName || ''}
                      onChange={(e) => handleChangeLastNameEmbarking(index, e)}
                      width={['100%', '100%', '100%', '30%']}
                    />
                    {locale === 'pt' ? (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingMannualyForm.document')}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentTypeEmbarking(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item label="Passporte" value="Passporte" />
                        <Select.Item label="CPF" value="CPF" />
                        <Select.Item label="RG" value="RG" />
                      </Select>
                    ) : (
                      <Select
                        borderRadius="2xl"
                        selectedValue={element.documentType || ''}
                        placeholder={t('bookingMannualyForm.document')}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        onValueChange={(e) =>
                          handleChangeDocumentTypeEmbarking(index, e)
                        }
                        width={['100%', '100%', '100%', '20%']}
                      >
                        <Select.Item
                          label={t('bookingMannualyForm.passport')}
                          value="Passport"
                        />
                        <Select.Item
                          label={t('bookingMannualyForm.driversLicense')}
                          value="Driver's License"
                        />
                      </Select>
                    )}
                    <Input
                      borderRadius="2xl"
                      bgColor="white"
                      placeholder="ID"
                      value={element.id || ''}
                      onChange={(e) => handleChangeIdEmbarking(index, e)}
                      width={['100%', '100%', '100%', '40%']}
                    />

                    {index ? (
                      <Pressable
                        onPress={() => removeFormFieldsEmbarking(index)}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Image
                          source={{
                            uri: minus_icon,
                          }}
                          alt="Alternate Text"
                          style={{ width: 20, height: 20 }}
                        />
                      </Pressable>
                    ) : null}

                    <Pressable
                      onPress={() => addFormFieldsEmbarking()}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        source={{
                          uri: button_plus,
                        }}
                        alt="Alternate Text"
                        style={{ width: 20, height: 20 }}
                      />
                    </Pressable>
                  </Stack>
                ))}
              </View>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.originFlight')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    zIndex="-2"
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.originFlight')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="origin_flight"
              />
              {errors.origin_flight && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingMannualyForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.nextDestination')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.nextDestination')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="next_destination"
              />
              {errors.next_destination && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingMannualyForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.pilotName')}
              </Text>
              <Stack
                space={1}
                p="1%"
                direction={['column', 'column', 'column', 'row']}
              >
                <View width={['100%', '100%', '100%', '30%']}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                        message: t('bookingMannualyForm.onlyLetters'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        bgColor="white"
                        placeholder={t('bookingMannualyForm.firstName')}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    defaultValue=""
                    name="pilot_first_name"
                  />
                  {errors.pilot_first_name && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.pilot_first_name.message
                        ? errors.pilot_first_name.message
                        : t('bookingMannualyForm.required')}
                    </Text>
                  )}
                </View>
                <View width={['100%', '100%', '100%', '30%']}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
                        message: t('bookingMannualyForm.onlyLetters'),
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        bgColor="white"
                        placeholder={t('bookingMannualyForm.lastName')}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    defaultValue=""
                    name="pilot_last_name"
                  />
                  {errors.pilot_last_name && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {errors.pilot_last_name.message
                        ? errors.pilot_last_name.message
                        : t('bookingMannualyForm.required')}
                    </Text>
                  )}
                </View>
                <View width={['100%', '100%', '100%', '40%']}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        borderRadius="2xl"
                        bgColor="white"
                        placeholder={t('bookingMannualyForm.pilotCertificate')}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                      />
                    )}
                    defaultValue=""
                    name="certificate_number"
                  />
                  {errors.certificate_number && (
                    <Text p="2" fontSize="xs" color="#eb0100">
                      {t('bookingMannualyForm.required')}
                    </Text>
                  )}
                </View>
              </Stack>
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.helicopterModel')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.helicopterModel')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="model"
              />
              {errors.model && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingMannualyForm.required')}
                </Text>
              )}
              <Text fontSize="s" p="2" color="blue.500">
                {t('bookingMannualyForm.helicopterTail')}
              </Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    borderRadius="2xl"
                    bgColor="white"
                    placeholder={t('bookingMannualyForm.helicopterTail')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                defaultValue=""
                name="tail_number"
              />
              {errors.tail_number && (
                <Text p="2" fontSize="xs" color="#eb0100">
                  {t('bookingMannualyForm.required')}
                </Text>
              )}
            </View>
          </Box>
          <View p="8" alignItems="center">
            <HStack space={3}>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                onPress={() => {
                  goToBack()
                }}
                p="4"
              >
                {t('bookingMannualyForm.back')}
              </Button>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                onPress={handleSubmit(onSubmit)}
                p="4"
                isLoading={isLoadingButton}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
              >
                {t('bookingMannualyForm.confirmReservation')}
              </Button>
            </HStack>
          </View>
        </View>
      </NativeBaseProvider>
      <Modal isOpen={isOpenModal}>
        <Alert status="error">
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              space={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  _dark={{
                    color: 'coolGray.800',
                  }}
                >
                  {t('try_again.text')}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" color="coolGray.600" />}
                onPress={() => closeModalErrorManualRservation()}
              />
            </HStack>
            <Box
              pl="6"
              _dark={{
                _text: {
                  color: 'coolGray.600',
                },
              }}
            >
              <Text>{errormanualReservation}</Text>
            </Box>
          </VStack>
        </Alert>
      </Modal>
    </View>
  )
}
export default ManualReservatiosnForm
