import React from 'react'
import {
  Text,
  Modal,
  Input,
  Center,
  View,
  Button,
  Heading,
  Image,
  Stack,
  Pressable,
} from 'native-base'
import { useMutation, useLazyQuery } from '@apollo/client'
import AppBarHeader from '../components/AppBar'
import { GET_COMPANY } from '../graphql/queries'
import {
  UPDATE_COMPANY_PROFILE,
  INSERT_EMAIL_USER,
  DELETE_EMAILS_USER,
  DELETE_PHONES_USER,
  INSERT_PHONE_USER,
} from '../graphql/mutations'
import { getToken } from '../Token'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loading from '../components/Loading'
import phoneLanguage from '../PhoneLanguage'
import { useForm, Controller } from 'react-hook-form'
import button_plus from '../assets/button_plus.png'
import minus_icon from '../assets/minus_icon.png'

const Phones = ({
  onAction,
  placeholderPhone,
  companyId,
  createdPhoneNumbers,
}) => {
  const [formPhones, setFormPhones] = React.useState([
    { phone_number: '', valid: true, company_id: companyId },
  ])
  const { t } = useTranslation()

  React.useEffect(() => {
    if (createdPhoneNumbers.length > 0) {
      const phones = getPhonesCompany(createdPhoneNumbers)
      setFormPhones(phones)
    }
  }, [])

  const getPhonesCompany = (phones) => {
    const phonesCompany = phones.map((phone) => {
      return {
        phone_number: phone.phone_number,
        company_id: phone.company_id,
        valid: true,
      }
    })
    return phonesCompany
  }

  let addFormFields = () => {
    setFormPhones([
      ...formPhones,
      {
        phone_number: '',
        valid: true,
        company_id: companyId,
      },
    ])
  }
  let removeFormFields = (i) => {
    let newFormValues = [...formPhones]
    newFormValues.splice(i, 1)
    setFormPhones(newFormValues)
    handleSubmit()
  }
  let handleChangePhone = (i, e) => {
    let newFormValues = [...formPhones]
    newFormValues[i].phone_number = e
    setFormPhones(newFormValues)
    handleSubmit()
  }

  let handleSubmit = () => {
    onAction(formPhones)
  }
  return (
    <View>
      {formPhones.map((element, index) => (
        <Stack
          space={1}
          p="1%"
          key={index}
          direction={['column', 'column', 'column', 'row']}
        >
          <PhoneInput
            inputStyle={{
              width: '100%',
              borderRadius: 15,
              backgroundColor: 'white',
              borderColor: '#E3E3E3',
            }}
            buttonStyle={{ borderColor: '#D4D4D4' }}
            masks={{ br: '.. .........' }}
            placeholder={placeholderPhone()}
            value={element.phone_number.toString() || ''}
            onChange={(e) => handleChangePhone(index, e)}
          />
          {index ? (
            <Pressable
              onPress={() => removeFormFields(index)}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                source={{
                  uri: minus_icon,
                }}
                alt="button minus"
                style={{ width: 20, height: 20 }}
              />
            </Pressable>
          ) : null}

          <Pressable
            onPress={() => addFormFields()}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              source={{
                uri: button_plus,
              }}
              alt="button plus"
              style={{ width: 20, height: 20 }}
            />
          </Pressable>
        </Stack>
      ))}
    </View>
  )
}

const Emails = ({ onAction, errorEmails, companyId, createdUsersEmails }) => {
  const [errors, setErrors] = React.useState('')
  const [formEmails, setFormEmails] = React.useState([
    { email: '', valid: false, company_id: companyId },
  ])
  const { t } = useTranslation()

  React.useEffect(() => {
    if (createdUsersEmails.length > 0) {
      const emails = getEmailsCompany(createdUsersEmails)
      setFormEmails(emails)
    }
  }, [])

  const getEmailsCompany = (emails) => {
    const emailsCompany = emails.map((email) => {
      return {
        email: email.email,
        company_id: email.company_id,
        valid: true,
      }
    })
    return emailsCompany
  }

  let addFormFields = () => {
    setFormEmails([
      ...formEmails,
      {
        email: '',
        valid: false,
        company_id: companyId,
      },
    ])
  }
  let removeFormFields = (i) => {
    let newFormValues = [...formEmails]
    newFormValues.splice(i, 1)
    setFormEmails(newFormValues)
    handleSubmit()
  }
  let handleChangeEmail = (i, e) => {
    emailValidation(i, e)
    let newFormValues = [...formEmails]
    newFormValues[i].email = e.target.value
    setFormEmails(newFormValues)
    handleSubmit()
  }
  let emailValidation = (i, e) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    if (!e.target.value || regex.test(e.target.value) === false) {
      let newFormValues = [...formEmails]
      newFormValues[i].valid = false
      setFormEmails(newFormValues)
      setErrors(`${e.target.value} Email is not valid`)
    } else {
      let newFormValues = [...formEmails]
      newFormValues[i].valid = true
      setFormEmails(newFormValues)
      setErrors(``)
    }
  }

  let handleSubmit = () => {
    onAction(formEmails)
  }
  return (
    <View>
      {formEmails.map((element, index) => (
        <Stack
          space={1}
          p="1%"
          key={index}
          direction={['column', 'column', 'column', 'row']}
        >
          <Input
            borderRadius="2xl"
            bgColor="white"
            placeholder={t('operatorAccount.emailModalPlaceholder')}
            value={element.email || ''}
            onChange={(e) => handleChangeEmail(index, e)}
            width={['100%', '100%', '100%', '70%']}
          />
          {index ? (
            <Pressable
              onPress={() => removeFormFields(index)}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                source={{
                  uri: minus_icon,
                }}
                alt="button minus"
                style={{ width: 20, height: 20 }}
              />
            </Pressable>
          ) : null}

          <Pressable
            onPress={() => addFormFields()}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              source={{
                uri: button_plus,
              }}
              alt="button plus"
              style={{ width: 20, height: 20 }}
            />
          </Pressable>
        </Stack>
      ))}
      {errors ? (
        <Text p="2" fontSize="xs" color="#eb0100">
          {errors}
        </Text>
      ) : (
        <Text></Text>
      )}
      {errorEmails ? (
        <Text p="2" fontSize="xs" color="#eb0100">
          {errorEmails}
        </Text>
      ) : (
        <Text></Text>
      )}
    </View>
  )
}

export const CompanyProfile = () => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUserId] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [updateCompanyProfile] = useMutation(UPDATE_COMPANY_PROFILE)
  const [createEmails] = useMutation(INSERT_EMAIL_USER)
  const [deleteUsersEmails] = useMutation(DELETE_EMAILS_USER)
  const [deleteUserPhones] = useMutation(DELETE_PHONES_USER)
  const [createPhones] = useMutation(INSERT_PHONE_USER)
  const { t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = React.useState('')
  const [getCompany] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
  })
  const [companyProfile, setCompanyProfile] = React.useState('')
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')
  const [usersEmails, setUsersEmails] = React.useState([])
  const [errorEmails, setErrorEmails] = React.useState('')
  const [companyId, setCompanyId] = React.useState('')
  const [createdUsersEmails, setCreatedUsersEmails] = React.useState([])
  const [createdPhoneNumbers, setCreatedPhoneNumbers] = React.useState([])
  const [usersPhones, setUsersPhones] = React.useState([])

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    await getCompanyUser(userId)
    const selectedLanguage = await phoneLanguage()
    setSelectedLanguage(selectedLanguage)
    setLoading(false)
  }, [])

  const getCompanyUser = async (userId) => {
    const company = await getCompany({
      variables: { userId: userId ? userId : user },
    })
    if (
      company.data &&
      company.data.users_companies &&
      company.data.users_companies.length > 0 &&
      company.data.users_companies[0].company
    ) {
      setCompanyId(company.data.users_companies[0].company.id)
      setCompanyProfile(company.data.users_companies[0].company.company_profile)
      if (company.data.users_companies[0].company.email_users.length > 0) {
        getCompanyUsers(company.data.users_companies[0].company.email_users)
      }
      if (company.data.users_companies[0].company.phone_users.length > 0) {
        getCompanyPhoneNumbers(
          company.data.users_companies[0].company.phone_users
        )
      }
    }
  }

  const getCompanyUsers = (emails) => {
    const usersEmailsToEdit = emails.map((email) => {
      return {
        email: email.email,
        company_id: email.company_id,
      }
    })
    setCreatedUsersEmails(usersEmailsToEdit)
  }

  const getCompanyPhoneNumbers = (phoneNumbers) => {
    const usersPhoneNumbersToEdit = phoneNumbers.map((phone) => {
      return {
        phone_number: phone.phone_number,
        company_id: phone.company_id,
      }
    })
    setCreatedPhoneNumbers(usersPhoneNumbersToEdit)
  }

  const showModal = () => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setShow(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'scroll'
    setShow(false)
  }

  const update = async (data) => {
    setButtonLoading(true)
    try {
      if (phoneValid === true) {
        const newCompany = await updateCompanyProfile({
          variables: {
            id: companyProfile.id,
            phone: data.selectedCallingCode
              ? data.selectedCallingCode
              : companyProfile.phone,
            company_name: data.companyName
              ? data.companyName
              : companyProfile.company_name,
            email: data.email ? data.email : companyProfile.email,
          },
        })
        setCompanyProfile(newCompany.data.update_company_profile_by_pk)
        if (usersEmails.length > 0) {
          await deleteUsersEmails({
            variables: {
              companyId: companyId,
            },
          })
          await createUsersEmails()
        }
        if (usersPhones.length > 0) {
          await deleteUserPhones({
            variables: {
              company_id: companyId,
            },
          })
          await createUserPhones()
        }
        closeModal()
        setButtonLoading(false)
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonLoading(false)
      }
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    const codeCountryBrazil = phoneNumberString.substr(0, 2)
    const codeCountryUsa = phoneNumberString.substr(0, 1)
    if (codeCountryBrazil === '55') {
      if (phoneNumberString.length === 13) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{5})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      } else if (phoneNumberString.length === 12) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(55|)?(\d{2})(\d{4})(\d{4})$/)
        if (match) {
          var intlCode = match[1] ? '+55 ' : ''
          return [intlCode, '', match[2], ' ', match[3], '-', match[4]].join('')
        }
        return null
      }
    }
    if (codeCountryUsa === '1') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    }
    if (codeCountryUsa !== '1' && codeCountryBrazil !== '55') {
      return phoneNumberString
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: companyProfile.company_name,
      email: companyProfile.email,
      selectedCallingCode: companyProfile.phone,
    },
  })
  const l = useTranslation()
  const language = l.i18n.languages[0]
  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }
  const addEmails = (emails) => {
    const addedEmails = emails.filter((email) => {
      return email.valid === true
    })
    if (addedEmails.length === emails.length) {
      setErrorEmails('')
      setUsersEmails(emails)
    } else {
      setErrorEmails(t('errors.errorValidEmail'))
    }
  }
  const createUsersEmails = async () => {
    const getCreatedUsersEmails = await Promise.all(
      usersEmails.map(async (email) => {
        const userEmail = await createEmails({
          variables: {
            email: email.email,
            company_id: email.company_id,
          },
        })

        return userEmail.data.insert_email_users_one
      })
    )

    setCreatedUsersEmails(getCreatedUsersEmails)
  }

  const createUserPhones = async () => {
    const getCreatedUserPhones = await Promise.all(
      usersPhones.map(async (phone) => {
        const userPhone = await createPhones({
          variables: {
            phone_number: phone.phone_number,
            company_id: phone.company_id,
          },
        })

        return userPhone.data.insert_phone_users_one
      })
    )

    setCreatedPhoneNumbers(getCreatedUserPhones)
  }

  const addPhoneNumbers = (phones) => {
    const addedPhonesNumbers = phones.filter((phone) => {
      return phone.valid === true
    })
    if (addedPhonesNumbers.length === phones.length) {
      setUsersPhones(phones)
    }
  }
  if (loading) return <Loading />

  return (
    <View
      backgroundColor="white"
      style={{
        flexDirection: 'column',
      }}
    >
      <AppBarHeader
        style={{
          flex: 1,
        }}
      />
      <Center>
        <View backgroundColor="light.100" rounded="2xl" shadow="9" p="4">
          <View>
            <Heading size="lg" p="2">
              {t('companyProfile.title')}
            </Heading>

            <Text color="muted.600">{t('companyProfile.name')}</Text>
            <Text pt="2" pb="2">
              {companyProfile.company_name}
            </Text>
            <Text color="muted.600">{t('companyProfile.emailLabel')}</Text>
            <Text pt="2" pb="2">
              {companyProfile.email}
            </Text>
            <Text color="muted.600">{t('companyProfile.emails')}</Text>
            {createdUsersEmails.length > 0 ? (
              createdUsersEmails.map((element, index) => (
                <Text pt="2" pb="2">
                  {element.email}
                </Text>
              ))
            ) : (
              <Text>{t('companyProfile.none')}</Text>
            )}
            <Text color="muted.600">{t('companyProfile.phones')}</Text>
            {createdPhoneNumbers.length > 0 ? (
              createdPhoneNumbers.map((element, index) => (
                <Text pt="2" pb="2">
                  {formatPhoneNumber(element.phone_number.toString())}
                </Text>
              ))
            ) : (
              <Text>{t('companyProfile.none')}</Text>
            )}
            <Text color="muted.600">
              {t('companyProfile.phoneNumberLabel')}
            </Text>
            <Text pt="2" pb="2">
              {formatPhoneNumber(companyProfile.phone.toString())}
            </Text>
            <Button
              rounded="2xl"
              colorScheme="yellow"
              onPress={() => showModal()}
            >
              {t('companyProfile.editButton')}
            </Button>
          </View>
        </View>
      </Center>
      <Modal isOpen={show} onClose={() => closeModal()}>
        <Modal.Content maxWidth="350">
          <Modal.CloseButton />
          <Modal.Header>{t('companyProfile.modalTitle')}</Modal.Header>
          <Modal.Body>
            <Text pt="1" pb="2">
              {t('companyProfile.companyNameModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
                  message: t('operatorAccount.lettersAndNumbers'),
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  borderRadius="2xl"
                  onChangeText={onChange}
                  value={value}
                  onBlur={onBlur}
                  placeholder={t('companyProfile.companyNameModalLabel')}
                  bgColor="white"
                />
              )}
              defaultValue={companyProfile.company_name}
              name="companyName"
            />
            {errors.companyName && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.companyName.message
                  ? errors.companyName.message
                  : t('operatorAccount.required')}
              </Text>
            )}
            <Text pt="1" pb="2">
              {t('companyProfile.emailModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  borderRadius="2xl"
                  onChangeText={onChange}
                  value={value}
                  onBlur={onBlur}
                  placeholder={t('operatorAccount.emailModalPlaceholder')}
                  bgColor="white"
                />
              )}
              defaultValue={companyProfile.email}
              name="email"
            />
            {errors.email && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {errors.email.message
                  ? errors.email.message
                  : t('operatorAccount.required')}
              </Text>
            )}
            <Text>{t('companyProfile.addEmails')}</Text>
            <Emails
              onAction={addEmails}
              errorEmails={errorEmails}
              companyId={companyId}
              createdUsersEmails={createdUsersEmails}
            />
            <Text pt="1" pb="2">
              {t('companyProfile.phoneNumberModalLabel')}
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    borderRadius: 15,
                    backgroundColor: 'white',
                    borderColor: '#E3E3E3',
                  }}
                  buttonStyle={{ borderColor: '#D4D4D4' }}
                  masks={{ br: '.. .........' }}
                  placeholder={placeholderPhone()}
                  value={value}
                  onChange={onChange}
                  isValid={(value, country) => {
                    if (
                      value.length > 1 &&
                      country.name === 'United States' &&
                      value.length < 11
                    ) {
                      setPhoneValid(false)
                    } else if (
                      value.length > 2 &&
                      country.name === 'Brazil' &&
                      value.length < 12
                    ) {
                      setPhoneValid(false)
                    } else {
                      setPhoneValid(true)
                      return true
                    }
                  }}
                />
              )}
              defaultValue={companyProfile.phone.toString()}
              name="selectedCallingCode"
            />
            {errors.selectedCallingCode && (
              <Text p="2" fontSize="xs" color="#eb0100">
                {t('operatorAccount.required')}
              </Text>
            )}
            {phoneValid === true ? (
              <View />
            ) : (
              <View zIndex="-2">
                <Text fontSize="xs" color="#eb0100">
                  {errorNumber}
                </Text>
              </View>
            )}
            <Text>{t('companyProfile.addPhones')}</Text>
            <Phones
              placeholderPhone={placeholderPhone}
              onAction={addPhoneNumbers}
              companyId={companyId}
              createdPhoneNumbers={createdPhoneNumbers}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  closeModal()
                }}
              >
                {t('calendarView.cancel')}
              </Button>
              <Button
                rounded="2xl"
                colorScheme="yellow"
                isLoading={buttonLoading}
                _loading={{
                  bg: 'amber.400:alpha.70',
                  _text: {
                    color: 'coolGray.700',
                  },
                }}
                _spinner={{
                  color: 'white',
                }}
                isLoadingText={t('submit_button.text')}
                onPress={handleSubmit(update)}
              >
                {t('companyProfile.editButtonSave')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  )
}
export default CompanyProfile
