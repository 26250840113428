import React from 'react'
import { StyleSheet } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import {
  HStack,
  View,
  Box,
  Button,
  Center,
  Image,
  Text,
  VStack,
  Select,
  CheckIcon,
  NativeBaseProvider,
  Stack,
} from 'native-base'
import AppBarHeader from '../components/AppBar'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useHistory, useLocation } from '../routing'
import {
  GET_HELIPORTS_CITIES,
  GET_HELIPORT_BY_COUNTRY_AND_CITY,
} from '../graphql/queries'
import { storage } from '../utils/nhost'
import locationIcon from '../assets/location_icon_yellow.png'
import { useTranslation } from 'react-i18next'
import Loading from '../components/Loading'

export const SearchHeliports = (props) => {
  let [country, setCountry] = React.useState('')
  let [city, setCity] = React.useState('')
  const [heliports, setHeliports] = React.useState([])
  let history = useHistory()
  const { t } = useTranslation()

  const goToHeliport = (id) => {
    history.push(`/${id}`)
  }

  function useParamQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useParamQuery()

  const goToScheduleHeliport = (timekitProjectId, comapnyId) => {
    history.push({
      pathname: '/scheduleHeliport',
      search: `?heliport=${timekitProjectId}&company=${comapnyId}`,
    })
  }

  const [getHeliports] = useLazyQuery(GET_HELIPORT_BY_COUNTRY_AND_CITY)

  React.useEffect(async () => {
    const urlCountry = query.get('country')
    setCountry(urlCountry)
    const urlCity = query.get('city')
    setCity(urlCity)
    const arrayHeliports = await getHeliports({
      variables: { country: urlCountry, city: urlCity },
    })
    await getHeliportsWithImage(arrayHeliports.data.heliports)
  }, [])

  const refecthHeliport = async (value) => {
    setCity(value)
    history.push(`/searchHeliports?country=${country}&city=${value}`)
    window.location.reload(false)
  }

  const refetchCountryHeliport = async (value) => {
    setCountry(value)
    history.push(`/searchHeliports?country=${value}&city=`)
    window.location.reload(false)
  }

  const getHeliportsWithImage = async (heliports) => {
    const heliportsWithImage = await Promise.all(
      heliports.map(async (heliport) => {
        let url = ''
        if (heliport.heliport_profile.heliport_image) {
          const fileId = heliport.heliport_profile.heliport_image.file_id
          const getUrl = await storage.getPresignedUrl({ fileId })
          url = getUrl.presignedUrl.url
        } else {
          url = `https://cdn.pixabay.com/photo/2017/03/04/14/19/helicopter-2116170_1280.jpg`
        }

        const days = await heliport.heliport_profile.heliport_aviabilities.map(
          (day) => {
            if (day.day === 'monday') {
              const mondayDay = {
                day: t('editAvailability.monday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '1',
              }
              return mondayDay
            }
            if (day.day === 'tuesday') {
              const tuesdayDay = {
                day: t('editAvailability.tuesday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '2',
              }
              return tuesdayDay
            }
            if (day.day === 'wednesday') {
              const wednesdayDay = {
                day: t('editAvailability.wednesday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '3',
              }
              return wednesdayDay
            }
            if (day.day === 'thursday') {
              const thursdayDay = {
                day: t('editAvailability.thursday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '4',
              }
              return thursdayDay
            }
            if (day.day === 'friday') {
              const fridayDay = {
                day: t('editAvailability.friday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '5',
              }
              return fridayDay
            }
            if (day.day === 'saturday') {
              const saturdayDay = {
                day: t('editAvailability.saturday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '6',
              }
              return saturdayDay
            }
            if (day.day === 'sunday') {
              const sundayDay = {
                day: t('editAvailability.sunday'),
                start: day.start_hour,
                end: day.end_hour,
                order: '7',
              }
              return sundayDay
            }
          }
        )
        //Days Filtered
        const allDaysFiltered = days.filter(function (x) {
          return x !== undefined
        })
        //Days Order
        const allDaysOrder = allDaysFiltered.sort(function (a, b) {
          return a.order - b.order
        })
        //Availability Days
        const availabilityDays = {
          dateStart: allDaysOrder[0],
          dateEnd: allDaysFiltered[allDaysFiltered.length - 1],
        }
        const heliportInformation = {
          id: heliport.id,
          name: heliport.heliport_profile.name,
          city: heliport.heliport_profile.city,
          country: heliport.heliport_profile.country,
          heliport_currency: heliport.heliport_profile.heliport_currency,
          icao_code: heliport.heliport_profile.icao_code,
          landing_slot_length: heliport.heliport_profile.landing_slot_length,
          landing_fee: heliport.heliport_profile.landing_fee,
          timekit_project_id: heliport.heliport_profile.timekit_project_id,
          companyId: heliport.company.id,
          urlImage: url,
          daysAvailable: availabilityDays,
          maxPriceHeliport: getMaxPrice(
            heliport.heliport_profile.heliport_aviabilities
          ),
          minPriceHeliport: getMinPrice(
            heliport.heliport_profile.heliport_aviabilities
          ),
        }
        return heliportInformation
      })
    )
    setHeliports(heliportsWithImage)
  }

  const getMaxPrice = (aviabilities) => {
    const pricesAviabilities = aviabilities.map((aviability) => {
      return aviability.price
    })
    const maxPrice = Math.max(...pricesAviabilities)
    return maxPrice
  }

  const getMinPrice = (aviabilities) => {
    const pricesAviabilities = aviabilities.map((aviability) => {
      return aviability.price
    })
    const minPrice = Math.min(...pricesAviabilities)
    return minPrice
  }

  const goToSearch = async () => {
    history.push(`/searchHeliports?country=${country}&city=${city}`)
    window.location.reload(false)
  }

  const { loading, error, data } = useQuery(GET_HELIPORTS_CITIES, {
    variables: { country: country },
  })

  const countryDictionary = {
    Brazil: t('country.brazil'),
    USA: t('country.usa'),
    Mexico: t('country.mexico'),
  }

  const getLocalizationCountry = (country) => {
    return countryDictionary[country]
  }

  const HeliportSearchBar = () => {
    return (
      <Stack
        direction={['column', 'column', 'row']}
        rounded="xl"
        overflow="hidden"
        p="12"
        space={8}
        width={['80', '80', '6/6']}
        shadow="9"
        _light={{
          backgroundColor: 'muted.100',
        }}
        _dark={{
          backgroundColor: 'muted.100',
        }}
      >
        <Stack>
          <Text pb="2" fontWeight="bold">
            {t('homepage.country')}
          </Text>
          <VStack alignItems="center" space={4}>
            <Box rounded="xl" bgColor="#e0e0e0">
              <Select
                width="250"
                variant="unstyled"
                selectedValue={country}
                accessibilityLabel="Country"
                placeholder={t('homepage.countryPlaceholder')}
                InputLeftElement={
                  <View style={styles.locationIconSearch}>
                    <Image
                      source={{
                        uri: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
                      }}
                      alt="Alternate Text"
                      style={{ width: 15, height: 15 }}
                    />
                  </View>
                }
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                style={{ marginBottom: '5px' }}
                onValueChange={(itemValue) => refetchCountryHeliport(itemValue)}
              >
                <Select.Item
                  label={t('appBar.language.brazil')}
                  value="Brazil"
                />
                <Select.Item
                  label={t('appBar.language.mexico')}
                  value="Mexico"
                />
                <Select.Item label={t('appBar.language.usa')} value="USA" />
              </Select>
            </Box>
          </VStack>
        </Stack>
        <View>
          <Text pb="2" fontWeight="bold">
            {t('homepage.city')}
          </Text>
          <VStack alignItems="center" space={4}>
            <Box rounded="xl" bgColor="#e0e0e0">
              <Select
                width="250"
                selectedValue={city}
                accessibilityLabel="City"
                placeholder={t('homepage.cityPlaceholder')}
                variant="unstyled"
                InputLeftElement={
                  <View style={styles.locationIconSearch}>
                    <Image
                      source={{
                        uri: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
                      }}
                      alt="Alternate Text"
                      style={{ width: 15, height: 15 }}
                    />
                  </View>
                }
                _selectedItem={{
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                style={{ marginBottom: '5px' }}
                onValueChange={(itemValue) => {
                  refecthHeliport(itemValue)
                }}
              >
                {data.heliports.map((m, index) => {
                  return (
                    <Select.Item
                      label={m.heliport_profile.city}
                      value={m.heliport_profile.city}
                      key={index}
                    />
                  )
                })}
              </Select>
            </Box>
          </VStack>
        </View>
        <View pt="7">
          <Button
            bgColor="yellow.300"
            size="lg"
            pb={3}
            pl={10}
            pr={10}
            _text={{
              color: 'coolgray.800',
            }}
            onPress={() => {
              goToSearch()
            }}
          >
            {t('homepage.searchButton')}
          </Button>
        </View>
      </Stack>
    )
  }

  const landingSlotLanguage = (landing) => {
    const number = landing.split(' ')[0]
    const time = landing.split(' ')[1]
    if (time === 'minutes') {
      return `${number} ${t('heliportDetailsOperator.minutes')}`
    } else {
      return `${number} ${t('heliportDetailsOperator.hours')}`
    }
  }

  const Heliports = () => {
    return (
      <Box rounded="xl" bgColor="muted.100" pb={5}>
        {heliports.length === 0 ? (
          <View
            style={{
              alignItems: 'center',
            }}
          >
            <Text p="5">{t('homepage.selectCity')}</Text>
          </View>
        ) : (
          <FlatList
            data={heliports}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => (
              <Stack
                direction={['column', 'column', 'column', 'row']}
                rounded="xl"
                space={8}
                pb={3}
                key={item.id.toString()}
                pt={8}
                pl={8}
                pr={8}
              >
                <Box>
                  <Center>
                    <Image
                      source={{
                        uri: item.urlImage,
                      }}
                      rounded="xl"
                      alt="Alternate Text"
                      style={{ width: 196, height: 196 }}
                    />
                  </Center>
                  <Center
                    backgroundColor="light.100"
                    position="absolute"
                    bottom="5%"
                    px="3"
                    py="1.5"
                    alignSelf="center"
                    left={item.city.length >= 17 ? '5%' : '15%'}
                    right={item.city.length >= 17 ? '5%' : '15%'}
                    rounded="xl"
                  >
                    <HStack space={1}>
                      <Center>
                        <Image
                          source={{
                            uri: locationIcon,
                          }}
                          alt="Alternate Text"
                          style={{ width: 20, height: 20 }}
                        />
                      </Center>
                      <Text
                        onPress={() => {
                          goToHeliport(item.icao_code)
                        }}
                        fontSize="12"
                        bold
                      >
                        {item.city}
                      </Text>
                    </HStack>
                  </Center>
                </Box>

                <Box
                  h={[250, 250, 350, 196]}
                  p={['3', '5', '8']}
                  rounded="xl"
                  backgroundColor="coolGray.50"
                  shadow="2"
                  key={item.id.toString()}
                >
                  <Stack
                    direction={['column', 'column', 'column', 'row']}
                    rounded="xl"
                    width={['80', '80', '80', '790']}
                    space={8}
                  >
                    <Box w="50%">
                      <Text bold fontSize={['sm', 'sm', 'lg']}>
                        {item.name} ({item.icao_code})
                      </Text>
                      <Text fontSize={['sm', 'sm', 'lg']}>
                        {item.city}, {getLocalizationCountry(item.country)}
                      </Text>
                      {item.daysAvailable.dateEnd === undefined &&
                      item.daysAvailable.dateStart === undefined ? (
                        <View />
                      ) : (
                        <View>
                          <HStack space={1}>
                            <Text fontSize={['sm', 'sm', 'lg']}>
                              {item.daysAvailable.dateEnd.start.slice(0, 5)}{' '}
                              {t('homepage.toTime')}{' '}
                              {item.daysAvailable.dateEnd.end.slice(0, 5)}{' '}
                              {t('homepage.hours')}
                            </Text>
                            <Text fontSize={['sm', 'sm', 'lg']}>
                              {item.daysAvailable.dateStart.day}{' '}
                              {t('homepage.toDays')}{' '}
                              {item.daysAvailable.dateEnd.day}
                            </Text>
                          </HStack>
                        </View>
                      )}

                      <HStack space={1}>
                        <Text bold fontSize={['sm', 'sm', 'lg']}>
                          {t('homepage.duration')}
                        </Text>
                        <Text fontSize={['sm', 'sm', 'lg']}>
                          {landingSlotLanguage(item.landing_slot_length)}
                        </Text>
                      </HStack>
                      <HStack space={1}>
                        <Text fontSize={['sm', 'sm', 'lg']} bold>
                          {t('homepage.fee')}
                        </Text>
                        {item.minPriceHeliport > 0 &&
                        item.maxPriceHeliport > 0 ? (
                          <View>
                            <HStack space={1}>
                              <View>
                                <Text fontSize={['sm', 'sm', 'lg']}>
                                  {item.heliport_currency === 'R$' ? 'R$' : '$'}
                                  {item.minPriceHeliport}
                                </Text>
                              </View>
                              {item.minPriceHeliport ===
                              item.maxPriceHeliport ? (
                                <View />
                              ) : (
                                <View>
                                  <HStack space={1}>
                                    <Text fontSize={['sm', 'sm', 'lg']} bold>
                                      -
                                    </Text>
                                    <View>
                                      <Text fontSize={['sm', 'sm', 'lg']}>
                                        {item.heliport_currency === 'R$'
                                          ? 'R$'
                                          : '$'}
                                        {item.maxPriceHeliport}
                                      </Text>
                                    </View>
                                  </HStack>
                                </View>
                              )}
                            </HStack>
                          </View>
                        ) : (
                          <View>
                            <Text fontSize={['sm', 'sm', 'lg']}>
                              {t('homepage.free')}
                            </Text>
                          </View>
                        )}
                      </HStack>
                    </Box>

                    <Box w="50%" alignItems="flex-end" pr={[0, 0, 10, 8]}>
                      <VStack space={3}>
                        <Button
                          width={[40, 80, 100]}
                          bgColor="yellow.300"
                          onPress={() => {
                            goToScheduleHeliport(
                              item.timekit_project_id,
                              item.companyId
                            )
                          }}
                        >
                          <Text bold={true} fontSize={['xs', 'sm', 'sm']}>
                            {t('homepage.reserve')}
                          </Text>
                        </Button>
                        <Button
                          bgColor="#e0e0e0"
                          width={[40, 80, 100]}
                          onPress={() => {
                            goToHeliport(item.icao_code)
                          }}
                        >
                          <Text bold={true} fontSize={['xs', 'sm', 'sm']}>
                            {t('homepage.details')}
                          </Text>
                        </Button>
                      </VStack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            )}
          />
        )}
      </Box>
    )
  }

  if (loading) return <Loading />
  if (error) return `Error! ${error.message}`
  return (
    <View>
      <AppBarHeader />
      <View>
        <NativeBaseProvider>
          <Center flex={1}>
            <HeliportSearchBar />
          </Center>
        </NativeBaseProvider>
      </View>
      <View style={styles.staticCards}>
        <View>
          <NativeBaseProvider>
            <Heliports />
          </NativeBaseProvider>
        </View>
        <p />
      </View>
    </View>
  )
}
export default SearchHeliports
const styles = StyleSheet.create({
  staticCards: {
    paddingTop: '5%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  locationIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  locationIconSearch: {
    paddingLeft: '10px',
    paddingBottom: '5px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
