const Spanish = {
  translation: {
    login: {
      title: 'Inicia sesión',
      welcomeBack: '¡Bienvenido de nuevo! Por favor ingrese sus datos',
      rememberMe: 'Recuérdame',
      notRegister: '¿Todavía no estas registrado?',
      linkToRegister: '¡Crea una cuenta!',
      username: 'Correo electrónico',
      userNameorEmail: '*Correo electrónico',
      password: 'Contraseña',
      passwordInput: '*Contraseña',
      show: 'Mostrar',
      hide: 'Ocultar',
      signIn: 'Iniciar sesión',
      forgotPassword: 'Olvidaste tu constraseña?',
      login: 'Regístrate',
    },
    register: {
      title: 'Registrarse',
      subtitle: 'Por favor, introduzca sus datos.',
      userType: 'Seleccione su tipo de usuario *',
      helpedManager: 'Gerente de helipuerto',
      helicopterOperator: 'Operador/Piloto de helicóptero',
      companyNameLabel: 'Nombre de la empresa',
      companyNamePlaceHolder: 'Nombre de la empresa',
      firstNameLabel: 'Nombre',
      firstNamePlaceHolder: 'Nombre',
      lastNameLabel: 'Apellido',
      lastNamePlaceHolder: 'Apellido',
      phoneNumberLabel: 'Número de teléfono',
      phonenumberCodeSelect: 'Por favor selecione o DDI do seu país',
      phoneNumberPlaceHolder: 'Número de teléfono',
      userNameLabel: 'Correo electrónico',
      userNamePlaceHolder: 'Correo electrónico',
      passwordLabel: 'Contraseña',
      passwordPlaceHolder: 'Contraseña',
      acceptTerms: 'Estoy de acuerdo con los',
      tos: 'Términos y condiciones',
      show: 'Mostrar',
      hide: 'Ocultar',
      member: '¿Ya eres usuario?',
      signIn: '¡Iniciar sesión!',
      registerButtonText: 'Regístrate',
      tosMessage: 'Acepte los Términos y Condiciones',
      type: 'Por favor, seleccione el tipo de usuario',
      required: 'Esto es requerido.',
      onlyLetters: 'Invalido, solo se puede usar letras.',
      lettersAndNumbers: 'Inválido, solo letras y números',
      invalidValue: 'Valor invalido',
    },
    forgotPassword: {
      title: 'Has olvidado tu contraseña',
      enterEmailAddress: 'Introduzca su dirección de correo electrónico',
      emailPlaceHolder: 'Correo electrónico',
      send: 'Enviar',
    },
    changePassword: {
      title: 'Crear una nueva contraseña',
      enterEmailAddress: 'Introduzca su dirección de correo electrónico',
      emailLabel: 'Correo electrónico',
      emailPlaceHolder: 'Correo electrónico',
      createPasswordButton: 'Crear contraseña',
    },
    changePasswordOperator: {
      change: 'Cambiar Contraseña',
      title: 'Por favor, cree su nueva contraseña',
      enterNewPassword: 'Por favor ingrese su nueva contraseña',
      passwordInput: '*Contraseña',
      show: 'Mostrar',
      hide: 'Ocultar',
      cancel: 'Cancelar',
      save: 'Guardar',
    },
    appBar: {
      pilotsAndOperator: 'PILOTOS Y OPERADORES',
      operatorAccount: 'Cuenta de operador',
      fleet: 'Flota',
      pilots: 'Pilotos',
      profiles: 'Perfiles',
      userProfile: 'Perfil del usuario',
      companyProfile: 'Perfil de la compañía',
    },
    operatorForm: {
      title: 'Operador',
      information: 'Información del operador',
      fillFields: 'Por favor llena todos los espacios',
      companyNameLabel: 'Nombre de la empresa',
      companyNamePlaceHolder: 'Nombre de la empresa',
      contactInformation: 'Información del contacto',
      nameLabel: 'Nombre',
      namePlaceHolder: 'Nombre',
      emailLabel: 'Correo electrónico',
      emailPlaceHolder: 'Correo electrónico',
      phoneNumberLabel: 'Número de teléfone',
      phoneNumberPlaceHolder: 'Número de teléfono',
      addressLabel: 'Dirección',
      addressPlaceHolder: 'Dirección',
      saveButton: 'Guardar',
    },
    operatorAccount: {
      title: 'Operador',
      information: 'Información del operador',
      nameLabel: 'Nombre',
      companyName: 'Nombre de la empresa',
      contactInformation: 'Información del contacto',
      contactName: 'Nombre',
      contactEmail: 'Correo electrónico',
      contactPhone: 'Número de teléfono',
      contactAddress: 'Dirección',
      editButton: 'Editar',
      titleModal: 'Editar operador',
      informationModal: 'Información del operador',
      companyNameLabel: 'Nombre de la empresa',
      companyNamePlaceholder: 'Nombre de la empresa',
      contactInformationModal: 'Información del contacto',
      nameModalLabel: 'Nombre',
      nameModalPlaceholder: 'Nombre',
      emailModalLabel: 'Correo electrónico',
      emailModalPlaceholder: 'Correo electrónico',
      phoneNumberModalLabel: 'Número de teléfono',
      phoneNumberPlaceHolder: 'Número de teléfono',
      addressModalLabel: 'Dirección',
      addressModalPlaceholder: 'Dirección',
      changePassword: 'Cambiar Contraseña',
      logOut: 'Cerrar Sesión',
      saveModalButton: 'Guardar',
      cancelModalButton: 'Cancelar',
      required: 'Esto es requerido.',
      onlyLetters: 'Solo letras',
      numbers: 'Solo números',
      lettersAndNumbers: 'Solo letras y números',
      invalidValue: 'Valor inválido',
    },
    operatorAccountMenu: {
      info: 'Información del Operador',
      loginSecurity: 'Inicio de sesión y Seguridad',
      billing: 'Facturación',
    },
    operatorPayment: {
      paymentMethods: 'Métodos de pago',
      paymentHistory: 'Historial de pagos',
    },
    manageReservationOperator: {
      bookingTentative: 'Requested',
      bookingConfirmed: 'Confirmed',
      noBooking: 'Sin reservas',
      upcoming: 'Upcoming Bookings',
      bookingTentative: 'Solicitado',
      bookingConfirmed: 'Confirmado',
      upcoming: 'Próximas reservas',
      past: 'Reservas pasadas',
      title: 'Reservas',
      upcomingBookings: {
        title: 'Reservas',
        upcomingBookings: 'Próximas reservas',
        reservation: {
          title: 'Reservas',
          date: 'Fecha:',
          start: 'Inicio:',
          end: 'Fin:',
        },
        customers: {
          title: 'Clientes',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumbers: 'Número de cola:',
        },
        status: {
          title: 'Estado',
          booking: 'Reserva:',
          payment: 'Pago:',
        },
        actions: {
          title: 'Acciones',
          viewDetails: 'Ver detalles',
        },
      },
      pastBookings: {
        title: 'Reservas canceladas',
        completed: 'Terminada',
        completedTitle: 'Reservas Completadas',
        reservation: {
          title: 'Reservas',
          date: 'Fecha:',
          start: 'Inicio:',
          end: 'Fin:',
        },
        customers: {
          title: 'Clientes',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumbers: 'Número de cola:',
        },
        actions: {
          title: 'Acciones',
          viewDetails: 'Ver detalles',
        },
        status: {
          title: 'Estado',
          booking: 'Reserva:',
          payment: 'Pago:',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rechazado',
        },
        note: 'Nota:',
      },
      bookingOperatorDetail: {
        title: 'Detalle de la Reserva',
        deleteButton: 'Eliminar',
        rescheduleButton: 'Reprogramar reserva',
        cancelButton: 'Cancelar reserva',
        cancelModalTitle: 'Cancelar reserva',
        sureCancel: '¿Está seguro de que desea cancelar?',
        returnReservation: 'Volver a la reserva',
        cancelModal: 'Cancelar',
        declinedBooking: 'Reserva Rechazada',
        reason: 'Razón:',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rechazado',
          completed: 'Completado',
        },
        operator: 'Operador:',
        status: 'Estado de la reservación',
        booking: 'Reserva:',
        payment: 'Pago:',
        bookingDetail: 'Detalles de la reserva:',
        additional_field: 'Campo Adicional',
        disembarking_passengers: 'Nombres de los pasajeros que desembarcan',
        email: 'Correo electrónico',
        embarking_passengers: 'Nombres de los pasajeros que embarcan',
        next_destination: 'Próximo destino',
        origin_flight: 'Vuelo de origen',
        passengers_ids: 'ID de pasajeros',
        pilot_name: 'Nombre del piloto',
        tail_number: 'Número de cola de la aeronave',
        timezone: 'Zona horaria',
        name: 'Nombre',
        model: 'Marca/modelo de aeronave',
        certificate_number: 'Número de certificado de piloto',
        reason: 'Razón:',
        error: 'No podemos encontrar su reserva',
        flightNumber: 'Número de vuelo',
        totalPassenger: 'Número total de pasajeros',
      },
    },
    methodsPayments: {
      notPayment: 'No tienes Métodos de Pago',
      addPaymentMethod: 'Añadir método de pago',
      expiration: 'Vencimiento:',
      removePaymentMethod: 'Remover',
      setDefaultPaymentMethod: 'Establecer predeterminado',
      addPaymentMethodButton: 'Añadir método de pago',
      createPaymentMethod: 'Crear método de pago',
      cardInformation: 'Información de la tarjeta',
      saveButton: 'Guardar',
    },
    userProfile: {
      title: 'Perfil del usuario',
      firstNameLabel: 'Nombre',
      firstNamePlaceHolder: 'Nombre',
      lastNameLabel: 'Apellido',
      lastNamePlaceHolder: 'Apellido',
      phoneNumberLabel: 'Número de teléfono',
      phoneNumberPlaceHolder: 'Número de teléfono',
      editButton: 'Editar',
      modalTitle: 'Editar perfil de usuario',
      firstNameModalLabel: 'Nombre',
      firstNameModalPlaceHolder: 'Nombre',
      lastNameModalLabel: 'Apellido',
      lastNameModalPlaceHolder: 'Apellido',
      phoneNumberModalLabel: 'Número de teléfono',
      phoneNumberModalPlaceHolder: 'Número de teléfono',
      editButtonSave: 'Guardar',
      changePassword: 'Cambiar Contraseña',
      logOut: 'Cerrar Sesión',
    },
    companyProfile: {
      title: 'Perfil de empresa',
      name: 'Nombre de la Empresa',
      emailLabel: 'Correo electrónico',
      phoneNumberLabel: 'Número de teléfono',
      editButton: 'Editar',
      modalTitle: 'Editar perfil de empresa',
      companyNameModalLabel: 'Perfil de empresa',
      emailModalLabel: 'Nombre del correo electrónico',
      phoneNumberModalLabel: 'Número de teléfono',
      editButtonSave: 'Guardar',
      emails: 'Correos electrónicos de la empresa',
      phones: 'Teléfonos de empresa',
      addEmails: 'Agregar más correos electrónicos para notificaciones:',
      addPhones: 'Agregar más números de teléfono para notificaciones:',
      none: 'Ninguno',
    }, // // here we will place our translations...
    appBar: {
      operatorMenu: {
        manageReservation: 'Administrar Reservas',
        manageFleet: 'Administrar Flota',
        managePilots: 'Administrar Pilotos',
        account: 'Cuenta',
        logOut: 'Cerrar Sesión',
      },
      managerMenu: {
        manageReservation: 'Administrar Reservas',
        manageHeliport: 'Administrar Helipuerto',
        manageCompany: 'Administrar Empresa',
        account: 'Cuenta',
        logOut: 'Cerrar Sesión',
      },
      publicMenu: {
        register: 'Registrarse',
        logIn: 'Iniciar Sesión',
      },
      language: {
        mexico: 'México',
        brazil: 'Brasil',
        usa: 'USA',
      },
      languageItems: {
        mexico: 'Español',
        brazil: 'Portugués',
        usa: 'Inglés',
      },
    },
    fleet: {
      required: 'Esto es requerido.',
      errorImage: 'Por favor, sube una imagen',
      noAircraft: 'No tienes aeronaves',
      addAircraft: 'Agregar aeronave',
      aircraftTitle: 'Aeronave',
      createAircraft: 'Crear aeronave',
      aircraftInformation: 'Información de la aeronave',
      tailNumber: 'Número de cola',
      model: 'Marca/modelo de helicóptero',
      maxWeight: 'Peso máximo al despegue',
      rotorLength: 'Longitud del rotor',
      updateButton: 'Actualizar',
      removeButton: 'Eliminar',
      uploadButton: 'Subir imagen',
      cancelButton: 'Cancelar',
      saveButton: 'Guardar',
      editButton: 'Editar',
      deleteButton: 'Eliminar',
      editAircraftTitle: 'Editar Aeronave',
      currentAircraftImage: 'Imagen actual de la aeronave',
      deleteFleet: 'Eliminar Aeronave',
      confirmMessage: '¿Quieres eliminar esta aeronave?',
      confirmButton: 'Confirmar',
      neverMindButton: 'No importa',
      errors: {
        create: 'Lo sentimos, no podemos crear su helicoptero.',
        update: 'Lo sentimos, no podemos actualizar su helicoptero.',
        delete: 'Lo sentimos, no podemos eliminar tu helicoptero',
      },
      ruleNumber: 'Invalido, solo números',
    },
    loading: {
      loading: 'Cargando',
    },
    homepage: {
      country: 'País',
      countryPlaceholder: 'País',
      city: 'Ciudad',
      cityPlaceholder: 'Ciudad',
      searchButton: 'Buscar',
      selectCity: 'Seleccione una Ciudad',
      reserve: 'Reserva',
      details: 'Detalles',
      toTime: 'a',
      toDays: 'a',
      hours: 'horas',
      duration: 'Duración:',
      fee: 'Tarifa:',
      featuredHelipads: 'Helipuertos Destacados',
      notHelipads: 'No Existen Helipuertos',
      firstTitle: 'Todo es más fácil con Altaport.',
      secondTitle: 'Aterrizar es mejor con Altaport.',
      privacy: 'Privacidad',
      terms: 'Términos',
      siteMap: 'Mapa del sitio',
      firstMessage: {
        message: '"Encontré el sistema muy',
        use: ' fácil de usar".',
        hosting: 'Supervisor de hospedaje,',
      },
      secondMessage: {
        message: '“Una plataforma que optimiza y',
        coordination: 'agiliza nuestro trabajo.”',
        operations: 'Operaciones de Vuelo, Air Jet',
      },
      landingsMessage: {
        title: 'Aterrizar es mejor con Altaport.',
        simplifiedMessage: {
          simplifiedMessage: {
            management: 'Helipuertos',
            heliports: 'fácil y rápido',
          },
          minimizingManual:
            'Minimizar las acciones manuales requeridas para programar',
          passengers: 'aterrizajes, gestionar pasajeros y procesar pagos.',
        },
        centralizedMessage: {
          centralizedMessage: {
            booking: 'Reserva centralizada de',
            helipad: 'helipuertos',
            place: ' en un solo lugar',
          },
          offering:
            'Ofreciendo a los operadores de helicópteros una experiencia ',
          helipad: 'optimizada para programación y pagos de helipuertos.',
        },
        highVolumeMessage: {
          first: 'Operaciones de',
          second: 'helipuerto',
          third: 'simplificadas',
          enabling:
            'Permitiendo el futuro de las operaciones autónomas de drones',
          operations: 'y taxis aéreos de gran volumen.',
        },
      },
      free: 'Gratis',
    },
    pilots: {
      required: 'Esto es requerido.',
      editButton: 'Editar',
      deleteButton: 'Eliminar',
      editTitle: 'Editar Piloto',
      currentPilotImage: 'Imagen del piloto actual',
      updateButton: 'Actualizar',
      removeButton: 'Eliminar',
      uploadImage: 'Subir imagen',
      pilotInformation: 'Información del piloto',
      certificateNumber: 'Número de certificado',
      firstName: 'Nombre',
      lastName: 'Apellido',
      phone: 'Número de teléfono',
      email: 'Correo electrónico',
      cancelButton: 'Cancelar',
      saveButton: 'Guardar',
      imageError: 'Por favor, sube una imagen',
      noPilots: 'No tienes pilotos',
      addPilotButton: 'Agregar Piloto',
      pilotsTitle: 'Pilotos',
      createPilot: 'Crear Piloto',
      deletePilot: 'Eliminar Piloto',
      confirmMessage: '¿Quieres eliminar este piloto?',
      confirmButton: 'Confirmar',
      neverMindButton: 'No importa',
      errors: {
        create: 'Lo sentimos, no podemos crear su piloto.',
        update: 'Lo sentimos, no podemos actualizar su piloto.',
        delete: 'Lo sentimos, no podemos eliminar su piloto.',
      },
    },
    reservations: {
      title: 'Reservas',
      addReservationsManually: 'Añadir reserva manualmente',
      blockTimes: 'Bloquear tiempos',
      listView: 'Vista de lista',
      calendarView: 'Vista de calendario',
      bookingBlockTimes: 'Tiempos de bloqueo de reservas',
      startDateHour: 'Fecha y hora de inicio',
      endDateHour: 'Fecha y hora de finalización',
      cancelButton: 'Cancelar',
      saveButton: 'OK',
      errorAccount: 'Error en la cuenta',
      completeBankInformation:
        'Hubo un error al completar su información bancaria, por favor complete toda la información bancaria.',
      reminder: 'Recordatorio',
      reminderMessage:
        'Recuerda completar tus datos bancarios para poder realizar reservas, puedes hacerlo desde la cuenta de administrador en la opción Información Bancaria',
    },
    calendarView: {
      deleteButton: 'Eliminar',
      accept: 'Aceptar',
      reject: 'Rechazar',
      declineBooking: 'Rechazar Reserva',
      reason: 'Razón:',
      cancel: 'Cancelar',
      save: 'Guardar',
      cancelReservation: 'Cancelar Reserva',
      tentative: 'Solicitado',
      confirmed: 'Confirmado',
      cancelledCustomer: 'Cancelado',
      cancelled: 'Cancelado',
      declined: 'Rechazado',
      completed: 'Completado',
      approved: 'Aprobado',
      pendingApproval: 'Pendiente de Aprobación',
      completed: 'Completado',
      blockPeriod: 'Período de bloqueo',
      cancelled: 'Cancelado',
      unPaidInvoice: 'No pagada - factura',
      paidAuthorized: 'Pagada - autorizada',
      paidCharged: 'Pagada - cobrada',
      unPaid: 'No pagada',
      booking: 'Reservas',
      notBooking: 'Sin Reservas',
      month: 'Mes',
      day: 'Día',
      week: 'Semana',
      block: 'Bloqueo',
      blockedTime: 'Tiempo bloqueado',
      bookingModal: 'Reserva',
      operator: 'Operador:',
      tailNumber: 'Número de cola:',
      status: 'Estado:',
      payment: 'Pago:',
      viewDetails: 'Ver Detalles',
      manualReservation: 'Reservación manual',
      locale: 'es',
    },
    heliportDetailsOperator: {
      latitude: 'Lat',
      longitude: 'Long',
      description: 'Descripción',
      helipad: 'Helipuerto',
      helipadDimensions: 'Dimensiones del helipuerto',
      location: 'Ubicación',
      helipadDetails: 'Detalles del helipuerto',
      availability: 'Disponibilidad',
      landing: 'ranura de aterrizaje',
      capacity: 'Capacidad Helipuertos:',
      fuel: 'Disponible',
      notFuel: 'No disponible',
      yes: 'Sí',
      no: 'No',
      restrictions: 'Restricciones',
      cancellation: 'Política de cancelación:',
      otherRestrictions: 'Otras Restricciones:',
      fees: 'Tarifas',
      landingFee: 'Tarifa de aterrizaje',
      parkingFee: 'Tarifa de estacionamiento',
      hourlyRate: 'Tarifa por hora',
      overnightRate: 'Tarifa noche',
      selectLanding: 'Seleccione una hora de aterrizaje',
      reserveLanding: 'Reservar hora de aterrizaje',
      standar: 'Tiempo estándar',
      helipadManager: 'Gerente de Helipuerto',
      makeReservation: 'Hacer reservación',
      map: 'Mapa',
      surfaceType: 'Superficie de',
      helipads: 'Helipuertos',
      notParking: 'No hay estacionamiento disponible',
      maxWeight: 'Peso máximo al despegue:',
      helipadDimensions: 'Dimensiones del helipuerto:',
      fuelAvailability: 'Disponibilidad de combustible: ',
      helipadsNumber: 'Helipuertos: ',
      typeSurface: 'Tipo de superficie: ',
      minutes: 'minuto(s)',
      hours: 'hora(s)',
      otherRestrictions: 'Otras Restricciones',
      none: 'Ninguna',
    },
    managerBooking: {
      upcomingBookings: 'Próximas Reservas',
      pastBookings: 'Reservas Pasadas',
      bookingPendingConfirm: {
        title: 'Reservas',
        noBooking: 'Sin Reservas',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rechazado',
          completed: 'Completado',
        },

        confirmedRequests: {
          title: 'Solicitudes Confirmadas',
          reservation: 'Reserva',
          date: 'Fecha:',
          start: 'Inicio:',
          end: 'Fin:',
          customer: 'Cliente',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumber: 'Número de cola:',
          operation: 'Operación:',
          embarking: 'Embarque',
          disembarking: 'Desembarque',
          passengerName: 'Nombres de los pasajeros:',
          status: 'Estado:',
          booking: 'Reserva:',
          payment: 'Pago:',
          actions: 'Acciones',
          viewDetails: 'Ver detalles',
          cancelButton: 'Cancelar',
        },
        pendingRequests: {
          title: 'Solicitudes pendientes',
          reservation: 'Reserva',
          date: 'Fecha:',
          start: 'Inicio:',
          end: 'Fin:',
          customer: 'Cliente',
          operator: 'Operador:',
          aircraftType: 'Tipo de aeronave:',
          tailNumber: 'Número de cola:',
          operation: 'Operación:',
          embarking: 'Embarque',
          disembarking: 'Desembarque',
          passengerName: 'Nombres de los pasajeros:',
          status: 'Estado:',
          booking: 'Reserva:',
          payment: 'Pago:',
          actions: 'Acciones',
          viewDetails: 'Ver detalles',
          acceptButton: 'Aceptar',
          rejectButton: 'Rechazar',
          declineBooking: 'Rechazar reserva',
          reason: 'Razón:',
          cancel: 'Cancelar',
          save: 'Guardar',
        },
        canceledByTime:
          'Reserva cancelada, por exceder el tiempo de la solicitud',
        errors: {
          decline: 'Lo sentimos, no podemos rechazar su reserva.',
          cancel: '¡Error al cancelar la reserva!',
        },
        manualReservation: 'Reservas Manuales',
      },
      cancelCompletedRequests: {
        cancelled: 'Reservas Canceladas',
        completed: 'Reservas Completadas',
        reservation: 'Reserva',
        date: 'Fecha:',
        start: 'Inicio:',
        end: 'Fin:',
        customer: 'Cliente',
        operator: 'Operador:',
        aircraftType: 'Tipo de aeronave:',
        tailNumber: 'Número de cola:',
        status: 'Estado:',
        booking: 'Reserva:',
        payment: 'Pago:',
        actions: 'Acciones',
        viewDetails: 'Ver detalles',
        deleteButton: 'Eliminar',
        acceptButton: 'Aceptar',
        rejectButton: 'Rechazar',
        declineBooking: 'Rechazar reserva',
        reason: 'Razón:',
        cancel: 'Cancelar',
        save: 'Guardar',
        bookingStatus: {
          tentative: 'Solicitado',
          confirmed: 'Confirmado',
          cancelledCustomer: 'Cancelado',
          cancelled: 'Cancelado',
          declined: 'Rechazado',
          completed: 'Completado',
        },
        note: 'Nota:',
        errorPayment: 'Error de pago',
        exceedingReservation:
          'Reserva cancelada, por exceder el tiempo de la solicitud',
      },
    },
    managerBookingDetail: {
      deleteButton: 'Eliminar',
      acceptButton: 'Aceptar',
      rejectButton: 'Rechazar',
      proposeButton: 'Proponer una nueva hora de reserva',
      rescheduleBooking: 'Reprogramar Reserva',
      declineBooking: 'Rechazar Reserva',
      reason: 'Razón:',
      cancel: 'Cancelar',
      save: 'Guardar',
      editDateTime: 'Editar fecha y hora',
      cancelReservation: 'Cancelar Reserva',
      editBooking: 'Editar Reserva',
      landingDateTime: 'Fecha y hora de aterrizaje',
      start: 'Inicio:',
      end: 'Fin:',
      additionalfield: 'Campo adicional',
      disembarkingPassengers: 'Pasajeros que desembarcan',
      email: 'Correo electrónico:',
      embarkingPassengers: 'Pasajeros que embarcan',
      operationEmbarking: 'Operación: Embarque',
      operationDisembarking: 'Operación: Desembarque',
      nextDestination: 'Próximo destino',
      originFlight: 'Vuelo de origen',
      passengersIds: 'ID de pasajeros:',
      pilotName: 'Nombre del piloto',
      tailNumber: 'Número de cola de la aeronave',
      timezone: 'Zona horaria:',
      name: 'Nombre:',
      model: 'Marca/Modelo de aeronave:',
      certificateNumber: 'Número de certificado piloto',
      copilotName: 'Nombre del copiloto',
      certificateNumberCopilot: 'Número de certificado de copiloto',
      bookingDetail: 'Detalle de la reserva',
      operatorContact: 'Contacto del operador:',
      operator: 'Operador',
      phone: 'Teléfono',
      email: 'Correo electrónico',
      status: 'Estado de la reservación',
      booking: 'Reserva:',
      payment: 'Pago',
      bookingDetails: 'Detalles de la reserva:',
      editBooking: 'Editar detalles de la reserva',
      declinedBooking: 'Reserva rechazada',
      reason: 'Razón',
      bookingStatus: {
        tentative: 'Solicitado',
        confirmed: 'Confirmado',
        cancelledCustomer: 'Cancelado',
        cancelled: 'Cancelado',
        declined: 'Rechazado',
        completed: 'Completado',
      },
      actualArrival: 'Llegada real',
      actualDeparture: 'Salida real',
      enterActualArrival: 'Ingrese la hora de llegada real',
      enterActualDeparture: 'Ingrese la hora de salida real',
      flightNumber: 'Número de vuelo',
      totalPassenger: 'Número total de pasajeros',
      notes: {
        addNoteTitle: 'Agregar Nota',
        editNoteTitle: 'Editar Nota',
        note: 'Nota:',
        errorNote: 'Lo sentimos, no pudimos agregar la nota',
        cancelButton: 'Cancelar',
        saveButton: 'Guardar',
        managerNote: 'Nota del gerente',
        operatorNote: 'Nota del operador',
        emptyNote: 'Debe agregar una nota',
      },
    },
    editHeliport: {
      identifier: 'Identificador de Helipuerto',
      name: 'Nombre del Helipuerto',
      code: 'CÓDIGO OACI',
      contactInformation: 'Información de contacto',
      managerName: 'Nombre del Gerente',
      phone: 'Número de teléfono',
      location: 'Ubicación',
      city: 'Ciudad',
      state: 'Estado',
      address: 'Calle',
      latitude: 'Latitud',
      longitude: 'Longitud',
      timezone: 'Zona horaria',
      availability: 'Disponibilidad',
      timeslot: 'Duración del intervalo de tiempo',
      hours: 'Horas',
      minutes: 'Minutos',
      days: 'Días',
      numberHelipads: 'Número de helipuertos (capacidad)',
      fuel: '¿Combustible disponible?',
      yes: 'Sí',
      no: 'No',
      reservation: 'Tipo de reserva',
      automatic: 'Automático',
      manual: 'Aprobación manual',
      restrictions: 'Restricciones',
      size: 'Límite de tamaño',
      weight: 'Peso',
      maximumRotor: 'Dimensiones del helipuerto',
      surface: 'Tipo de Superficie',
      otherRestrictions: 'Otras Restricciones',
      cancellation: 'Ventana de cancelación',
      fees: 'Tarifas',
      landingFee: 'Tarifa de aterrizaje',
      currency: 'Moneda del Helipuerto',
      parkingCurrency: 'Moneda',
      real: 'Real',
      usd: 'USD',
      mexican: 'Peso Mexicano',
      parkingAvailable: '¿Estacionamiento disponible?',
      editParking: 'Estacionamiento',
      parkingHour: 'Tarifa por hora',
      parkingNight: 'Tarifa nocturna',
      description: 'Descripción del Helipuerto',
      saveButton: 'GUARDAR',
      error: 'No podemos editar su helipuerto',
      fee: 'Tarifa',
      limitLateBooking: 'Límite de reserva tardía',
    },
    managerAccount: {
      menu: {
        userProfile: 'Perfil de usuario',
        loginSecurity: 'Inicio de sesión y seguridad',
        transactions: 'Transacciones y pagos',
      },
      payoutHistory: 'Historial de Pagos',
      transactionHistory: 'Historial de Transacciones',
      bank: 'Información Bancaria',
      noPayment: 'No tienes Pagos',
      payout: 'Pago',
      chaseChecking: 'Chase Checking...',
      routingNumber: 'Número de ruta:',
      changePayout: 'Cambiar método de pago',
      verifyAccount: 'Verificar cuenta',
      createBank: 'Crear información bancaria',
    },
    manageHeliport: {
      latitude: 'Latitud: ',
      longitude: 'Longitud: ',
      description: 'Descripción',
      helipad: 'Helipuerto',
      helipadDimensions: 'Dimensiones del helipuerto',
      manager: 'Gerente',
      phone: 'Teléfono: ',
      name: 'Nombre: ',
      email: 'Correo: ',
      address: 'Dirección',
      companyInformation: 'Información de la empresa',
      companyName: 'Nombre: ',
      companyPhone: 'Teléfono: ',
      state: 'Estado: ',
      maxWeight: 'Peso máximo al despegue:',
      helipadDimensions: 'Dimensiones del helipuerto:',
      cancellation: 'Ventana de cancelación:',
      editHeliport: 'Editar Helipuerto',
      addReservationsManually: 'Añadir reserva manualmente',
      editImage: 'Editar Imagen',
      currentImage: 'Imagen Actual',
      updateButton: 'Actualizar',
      removeButton: 'Eliminar',
      uploadImage: 'Subir imagen',
      cancel: 'Cancelar',
      save: 'Guardar',
      editAviabilities: 'Editar disponibilidades de helipuerto',
      manageHelipad: {
        notHelipads: 'No tienes helipuertos',
        addHelipad: 'AÑADIR HELIPUERTO',
        helipadDetails: 'Detalles del helipuerto',
        editHelipad: 'EDITAR HELIPUERTO',
        delete: 'ELIMINAR',
        createHelipadTitle: 'Crear helipuerto',
        helipadInformation: 'Información de helipuerto',
        name: 'Nombre',
        required: 'Esto es requerido.',
        timezone: 'Zona horaria',
        editHelipadTitle: 'Editar Helipuerto',
        createHelipad: 'CREAR HELIPUERTO',
        saveButton: 'Guardar',
        tryAgain: '¡Inténtalo de nuevo más tarde!',
        errors: {
          create: 'Lo sentimos, no podemos crear tu helipuerto.',
          delete: 'Lo sentimos, no podemos eliminar tu helipuerto.',
          edit: 'Lo sentimos, no podemos editar tu helipuerto.',
          deleteErrorServer:
            'No se puede borrar el helipuerto porque tiene reservas pendientes',
        },
      },
      reminder: 'Recordatorio',
      reminderMessage:
        'Recuerda que debes completar la información del helipuerto para hacer una reservación, puedes hacerlo desde Editar Helipuerto',
      reminderAccept: 'Aceptar',
      country: {
        mexico: 'México',
        brazil: 'Brasil',
        usa: 'USA',
      },
    },
    submit_button: {
      text: 'Enviando',
    },
    try_again: {
      text: '¡Por favor, inténtelo de nuevo más tarde!',
    },
    heliportForm: {
      required: 'Esto es requerido.',
      identifier: 'Identificador de Helipuerto',
      name: 'Nombre del Helipuerto',
      code: 'CÓDIGO OACI',
      contactInformation: 'Información de contacto',
      managerName: 'Nombre del Gerente',
      firstName: 'Nombre',
      lastName: 'Apellido',
      phone: 'Número de Trabajo',
      location: 'Ubicación',
      city: 'Ciudad',
      state: 'Estado',
      field: 'Campo para 2 caracteres (por ejemplo, SP)',
      address: 'Calle',
      latitude: 'Latitud',
      numbers: 'Solo números',
      longitude: 'Longitud',
      timezone: 'Zona horaria',
      country: 'País',
      availability: 'Disponibilidad',
      timeslot: 'Duración del intervalo de tiempo',
      hours: 'Horas',
      hour: 'Hora',
      night: 'Noche',
      minutes: 'Minutos',
      days: 'Días',
      numberHelipads: 'Número de helipuertos (capacidad)',
      numberHelipadsLabel: 'Número de helipuertos',
      fuel: '¿Combustible disponible?',
      yes: 'Sí',
      no: 'No',
      reservation: 'Tipo de reserva',
      automatic: 'Automático',
      manual: 'Aprobación manual',
      restrictions: 'Restricciones',
      size: 'Límite de tamaño',
      weight: 'Peso',
      maximumRotor: 'Dimensiones del helipuerto',
      maximumRotorLabel: 'Dimensiones',
      surface: 'Tipo de Superficie',
      aspalthLabel: 'Asfalto',
      aspalth: 'e.g., Asfalto',
      otherRestrictions: 'Otras Restricciones',
      noAvailables: 'No disponible el fin de semana',
      limitLate: 'Limitar reservas tardías',
      cancellation: 'Ventana de cancelación',
      before: 'Antes',
      flight: 'del vuelo',
      fees: 'Tarifas',
      heliportCurrency: 'Moneda del Helipuerto',
      currency: 'Moneda',
      real: 'Real',
      usd: 'USD',
      mexican: 'Peso Mexicano',
      parkingHour: 'Tarifa por hora',
      parkingNight: 'Tarifa nocturna',
      description: 'Descripción del Helipuerto',
      descriptionLabel: 'Una pequeña descripción del helipuerto.',
      uploadImage: 'Subir Imágenes',
      update: 'Actualizar',
      remove: 'Eliminar',
      requiedReservation: 'Detalles de reserva requeridos',
      disembarkingPassengers: 'Nombres de pasajeros Desembarcando',
      embarkingPassengers: 'Nombres de pasajeros Embarcando',
      originFlight: 'Origen del vuelo',
      nextDestination: 'Próximo destino',
      passengerIDs: 'Identificación del pasajero (por ejemplo, pasaporte)',
      accept: 'Acepto',
      servicesAgreement: 'Acuerdo de servicios de Altaport',
      step: 'Paso',
      createHeliport: 'CREAR HELIPUERTO',
      parkingAvailable: '¿Estacionamiento disponible?',
      acceptTos: 'Por favor, acepte los Términos de servicio',
      addressField: 'El campo de dirección es obligatorio',
      imagesField: 'Imagen(es) requerida(s)',
    },
    managerReports: {
      reports: 'Informes',
      enterDate: 'Ingrese la fecha de inicio y la fecha de finalización',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      startError: 'Ingrese una fecha de inicio',
      downloadReport: 'Descargar',
      downloadLoading: 'Cargando...',
    },
    bookingOperatorForm: {
      with: 'en',
      disembarkingPassengers: 'Pasajeros que Desembarcan',
      embarkingPassengers: 'Pasajeros que Embarcan',
      firstName: 'Nombre',
      lastName: 'Apellido',
      document: 'Documento',
      passport: 'Pasaporte',
      driversLicense: 'Licencia de conducir',
      originFlight: 'Vuelo de origen',
      nextDestination: 'Próximo destino',
      required: 'Esto es requerido.',
      selectPilot: 'Seleccionar piloto',
      selectCopilot: 'Seleccionar copiloto',
      addPilot: 'Añadir piloto',
      selectAircraft: 'Seleccionar Aeronave',
      addAircraft: 'Añadir aeronave',
      back: 'Atrás',
      confirmReservation: 'Confirmar reservación',
      nextPayment: 'Siguiente: Detalles de pago',
      repeatedPilot: 'No puedes seleccionar el mismo piloto',
      flightNumber: 'Número de vuelo',
      totalPassenger: 'Número total de pasajeros',
    },
    bookingMannualyForm: {
      with: 'en',
      name: 'Nombre',
      email: 'Correo Electrónico',
      phone: 'Número de Teléfono',
      disembarkingPassengers: 'Pasajeros que Desembarcan',
      embarkingPassengers: 'Pasajeros que Embarcan',
      firstName: 'Nombre',
      lastName: 'Apellido',
      document: 'Documento',
      passport: 'Pasaporte',
      driversLicense: 'Licencia de conducir',
      originFlight: 'Vuelo de origen',
      nextDestination: 'Próximo destino',
      required: 'Esto es requerido.',
      pilotName: 'Piloto',
      pilotCertificate: 'Número de certificado',
      helicopterModel: 'Marca/modelo de helicóptero',
      helicopterTail: 'Número de cola de helicóptero',
      back: 'Atrás',
      confirmReservation: 'Confirmar reservación',
      required: 'Esto es requerido.',
      onlyLetters: 'Invalido, solo se puede usar letras.',
      lettersAndNumbers: 'Inválido, solo letras y números',
      invalidValue: 'Valor invalido',
      totalPassenger: 'Número total de pasajeros',
    },
    paymentMethods: {
      errorMonthlyInvoices:
        'Lo siento, solo puedes usar facturas mensuales con helipuertos de tu misma moneda.',
      errorReservation: 'Lo sentimos, no podemos hacer su reserva',
      creditCard: 'Tarjeta de crédito',
      boleto: 'Boleto',
      monthlyInvoice: 'Factura mensual',
      fastPass: 'Pase Rápido',
      back: 'Atrás',
      selectOtherMethod: 'Por favor elija otro método de pago',
      passCode: {
        title: 'Código de acceso rápido',
        introduceCode: 'Por favor, introduce tu código',
        code: 'Código:',
        required: 'Esto es requerido.',
        cancel: 'Cancelar',
        send: 'Enviar',
      },
    },
    paymentSummary: {
      errorBooking: {
        title: '¡Error en la reserva!',
        message: 'Su reserva no ha sido recibida, por favor contáctenos',
      },
      bookingReceived: {
        title: '¡Reserva Recibida!',
        yourBooking: 'Su reserva ha sido recibida',
        paymentsDetails: 'Detalles de pago',
        amount: 'Cantidad:',
        billingDetails: 'Detalles de facturación',
        email: 'Correo electrónico:',
        phone: 'Teléfono:',
        paymentMethod: 'Método de pago',
        type: 'Tipo:',
        viewReservations: 'Ver Reservas',
        promotionalCode: 'Código promocional',
      },
      bookingDetailPayment: {
        bookingDetails: 'Detalles de la reserva',
        additionalField: 'Campo Adicional',
        disembarkingPassengers: 'Nombres de los pasajeros que desembarcan',
        email: 'Correo electrónico',
        embarkingPassengers: 'Nombres de los pasajeros que embarcan',
        nextDestination: 'Próximo destino',
        originFlight: 'Vuelo de origen',
        passengersIds: 'ID de pasajeros',
        pilotName: 'Nombre del piloto',
        tailNumber: 'Número de cola de la aeronave',
        timezone: 'Zona horaria',
        name: 'Nombre',
        totalPassenger: 'Número total de pasajeros',
      },
    },
    editAvailability: {
      title: 'Editar Disponibilidad',
      day: 'Día',
      hour: 'Horas',
      price: 'Precio',
      approval: 'Aprobación',
      sunday: 'Domingo',
      saturday: 'Sábado',
      friday: 'Viernes',
      thursday: 'Jueves',
      wednesday: 'Miércoles',
      tuesday: 'Martes',
      monday: 'Lunes',
      automatic: 'Automática',
      manual: 'Manual',
      start: 'Inicio:',
      end: 'Fin:',
      save: 'Guardar',
      editButton: 'Editar',
      deleteButton: 'Eliminar',
      add: 'Agregar',
      noAvailability: 'Sin Disponibilidad',
      none: 'Ninguna',
      actions: 'Acciones',
      buttons: {
        edit: 'Guardar',
        save: 'Guardar',
      },
      errorSave: 'No pudimos guardar sus dias y horas.',
      errorEdit: 'No pudimos editar sus dias y horas',
    },
    errors: {
      bookings: 'Lo sentimos, no podemos mostar sus reservas.',
      completeInformation: 'Por favor, complete el formulario de helipuerto.',
      errorHeliportForm: 'Formulario de Helipuerto.',
      createHeliport: 'Lo sentimos, no podemos crear su helipuerto',
      save: 'Lo sentimos, no podemos guardar las disponibilidades del helipuerto.',
      routeNotFound: 'Lo sentimos, no pudimos encontrar lo que buscas.',
      heliportNotFound: 'Lo sentimos, no pudimos encontrar el helipuerto.',
      acceptBooking: 'Lo sentimos, no podemos aceptar su reserva.',
      cancelBooking: 'Lo sentimos, no podemos cancelar su reserva.',
      declineBooking: 'Lo sentimos, no podemos cancelar su reserva.',
      editBooking: "Lo sentimos, no podemos editar su reserva.'",
      userProfile:
        'Lo sentimos, no pudimos encontrar el perfil que estás buscando',
      connectAccount: 'Error con conectar cuenta',
      errorValidEmail: 'Todos los emails deben ser válidos',
    },
    changePassword: {
      title: 'Crear nueva contraseña',
      introduce: 'Por favor ingrese su nueva contraseña',
      button: 'Enviar',
    },
    paymentHistorial: {
      emptyPayments: 'No tienes Pagos.',
      paymentHistorialTitle: 'Historial de pagos',
    },
    errorManager: {
      completeInformation: 'Por favor, complete la información del helipuerto.',
      message:
        'Debes crear un helipuerto desde Administrar mi helipuerto o crear disponibilidades de helipuerto.',
      button: 'Gestionar mi helipuerto',
      cancel: 'Cancelar',
    },
    paymentStatus: {
      unPaidInvoice: 'No pagado - factura',
      paidAuthorized: 'Requerido',
      paidCharged: 'Exitoso',
      unPaid: 'No pagado',
      paidCancel: 'Cancelado',
      manualReservation: 'No pagado - reserva manual',
      paidCode: 'Código promocional',
      succeeded: 'Exitoso',
      canceled: 'Cancelado',
      requireCapture: 'Requerido',
      payment: 'pago',
    },
    time: {
      days: 'dia(s)',
      minutes: 'minuto(s)',
      hours: 'hora(s)',
    },
    country: {
      brazil: 'Brasil',
      usa: 'Estados Unidos',
      mexico: 'México',
    },
    errorPhoneNumber: 'Inválido para el código de país seleccionado',
    errorsPassword: {
      minLength: 'Al menos 8 caracteres.',
      specialChar: 'Al menos un carácter especial.',
      number: 'Al menos un número.',
      capital: 'Al menos una letra mayúscula.',
      lowercase: 'Al menos una letra minúscula.',
    },
    invoice: {
      title: 'Factura',
      daysInvoice: 'Número de días para pagar la factura',
      sendInvoice: 'Enviar automáticamente la factura con el pago.',
      monthlyInvoice: 'Enviar facturas cada comienzo de mes',
      halfMonthlyInvoice: 'Enviar facturas cada quincena',
    },
    west: 'O',
    proposeNewDate: {
      title: '¿Está seguro de que desea reprogramar la reserva?',
      changeTo: 'Cambiar la reserva a:',
      date: 'Fecha: ',
      startHour: 'Inicio:',
      endHour: 'Fin: ',
      acceptButton: 'Aceptar',
      cancel: 'Cancelar',
    },
  },
}
export default Spanish
