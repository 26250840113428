import React from 'react'
import { HStack, Button, View, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../AxiosInstance'

const ActionsBlockTime = ({ bookingDetail, timekitProjectId, onDelete }) => {
  const { t } = useTranslation()
  const [error, setError] = React.useState(null)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const deleteBlockTime = async () => {
    try {
      setButtonLoading(true)
      await axiosInstance.post(`/reservations/deleteBlockTime`, {
        blockTimeDate: bookingDetail?.meta?.block_period?.start,
        projectId: timekitProjectId,
      })
      await onDelete()
      setButtonLoading(false)
    } catch (error) {
      setButtonLoading(false)
      setError(error?.message)
    }
  }

  const closeError = () => {
    document.body.style.overflow = 'scroll'
    setShowError(false)
  }
  return (
    <View>
      <HStack space={3}>
        <Button
          size="xs"
          bgColor="danger.500"
          isLoading={buttonLoading}
          _loading={{
            bg: 'amber.400:alpha.70',
            _text: {
              color: 'coolGray.700',
            },
          }}
          _spinner={{
            color: 'white',
          }}
          isLoadingText={t('submit_button.text')}
          onPress={() => {
            deleteBlockTime()
          }}
        >
          {t('calendarView.deleteButton')}
        </Button>
      </HStack>
      <Text>{error}</Text>
    </View>
  )
}
export default ActionsBlockTime
