import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Heading,
  Text,
  Divider,
  Center,
  Button,
  View,
  Input,
  VStack,
  Stack,
  Pressable,
  Image,
  Modal,
} from 'native-base'
import { useMutation } from '@apollo/client'
import {
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_CONTACT,
  INSERT_PHONE_OPERATOR,
  INSERT_EMAIL_OPERATOR,
  DELETE_EMAILS_OPERATOR,
  DELETE_PHONES_OPERATOR,
} from '../../graphql/mutations'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import button_plus from '../../assets/button_plus.png'
import minus_icon from '../../assets/minus_icon.png'

const Phones = ({ onAction, placeholderPhone, operatorId, operatorPhones }) => {
  const [formPhones, setFormPhones] = React.useState([
    { phone_number: '', valid: true, operator_id: operatorId },
  ])
  const { t } = useTranslation()

  React.useEffect(() => {
    if (operatorPhones.length > 0) {
      const phones = getPhonesOperator(operatorPhones)
      setFormPhones(phones)
    }
  }, [])

  const getPhonesOperator = (phones) => {
    const phonesOperator = phones.map((phone) => {
      return {
        phone_number: phone.phone_number,
        operator_id: phone.operator_id,
        valid: true,
      }
    })
    return phonesOperator
  }

  let addFormFields = () => {
    setFormPhones([
      ...formPhones,
      {
        phone_number: '',
        valid: true,
        operator_id: operatorId,
      },
    ])
  }
  let removeFormFields = (i) => {
    let newFormValues = [...formPhones]
    newFormValues.splice(i, 1)
    setFormPhones(newFormValues)
    handleSubmit(newFormValues)
  }
  let handleChangePhone = (i, e) => {
    let newFormValues = [...formPhones]
    newFormValues[i].phone_number = e
    setFormPhones(newFormValues)
    handleSubmit()
  }

  let handleSubmit = (newValues) => {
    if (newValues) {
      onAction(newValues)
    } else {
      onAction(formPhones)
    }
  }
  return (
    <View>
      {formPhones.map((element, index) => (
        <Stack
          space={1}
          p="1%"
          key={index}
          direction={['column', 'column', 'column', 'row']}
        >
          <PhoneInput
            inputStyle={{
              width: '100%',
              borderRadius: 15,
              backgroundColor: 'white',
              borderColor: '#E3E3E3',
            }}
            buttonStyle={{ borderColor: '#D4D4D4' }}
            masks={{ br: '.. .........' }}
            placeholder={placeholderPhone()}
            value={element.phone_number.toString() || ''}
            onChange={(e) => handleChangePhone(index, e)}
          />
          {index ? (
            <Pressable
              onPress={() => removeFormFields(index)}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                source={{
                  uri: minus_icon,
                }}
                alt="button minus"
                style={{ width: 20, height: 20 }}
              />
            </Pressable>
          ) : null}

          <Pressable
            onPress={() => addFormFields()}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              source={{
                uri: button_plus,
              }}
              alt="button plus"
              style={{ width: 20, height: 20 }}
            />
          </Pressable>
        </Stack>
      ))}
    </View>
  )
}

const Emails = ({ onAction, errorEmails, operatorId, operatorEmails }) => {
  const [errors, setErrors] = React.useState('')
  const [formEmails, setFormEmails] = React.useState([
    { email: '', valid: false, operator_id: operatorId },
  ])
  const { t } = useTranslation()

  React.useEffect(() => {
    if (operatorEmails.length > 0) {
      const emails = getEmailsOperator(operatorEmails)
      setFormEmails(emails)
    }
  }, [])

  const getEmailsOperator = (emails) => {
    const emailsOperator = emails.map((email) => {
      return {
        email: email.email,
        operator_id: email.operator_id,
        valid: true,
      }
    })
    return emailsOperator
  }

  let addFormFields = () => {
    setFormEmails([
      ...formEmails,
      {
        email: '',
        valid: false,
        operator_id: operatorId,
      },
    ])
  }
  let removeFormFields = (i) => {
    let newFormValues = [...formEmails]
    newFormValues.splice(i, 1)
    setFormEmails(newFormValues)
    handleSubmit(newFormValues)
  }
  let handleChangeEmail = (i, e) => {
    emailValidation(i, e)
    let newFormValues = [...formEmails]
    newFormValues[i].email = e.target.value
    setFormEmails(newFormValues)
    handleSubmit()
  }
  let emailValidation = (i, e) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    if (!e.target.value || regex.test(e.target.value) === false) {
      let newFormValues = [...formEmails]
      newFormValues[i].valid = false
      setFormEmails(newFormValues)
      setErrors(`${e.target.value} Email is not valid`)
    } else {
      let newFormValues = [...formEmails]
      newFormValues[i].valid = true
      setFormEmails(newFormValues)
      setErrors(``)
    }
  }

  let handleSubmit = (newValues) => {
    if (newValues) {
      onAction(newValues)
    } else {
      onAction(formEmails)
    }
  }
  return (
    <View>
      {formEmails.map((element, index) => (
        <Stack
          space={1}
          p="1%"
          key={index}
          direction={['column', 'column', 'column', 'row']}
        >
          <Input
            borderRadius="2xl"
            bgColor="white"
            placeholder={t('operatorAccount.emailModalPlaceholder')}
            value={element.email || ''}
            onChange={(e) => handleChangeEmail(index, e)}
            width={['100%', '100%', '100%', '70%']}
          />
          {index ? (
            <Pressable
              onPress={() => removeFormFields(index)}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                source={{
                  uri: minus_icon,
                }}
                alt="button minus"
                style={{ width: 20, height: 20 }}
              />
            </Pressable>
          ) : null}

          <Pressable
            onPress={() => addFormFields()}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              source={{
                uri: button_plus,
              }}
              alt="button plus"
              style={{ width: 20, height: 20 }}
            />
          </Pressable>
        </Stack>
      ))}
      {errors ? (
        <Text p="2" fontSize="xs" color="#eb0100">
          {errors}
        </Text>
      ) : (
        <Text></Text>
      )}
      {errorEmails ? (
        <Text p="2" fontSize="xs" color="#eb0100">
          {errorEmails}
        </Text>
      ) : (
        <Text></Text>
      )}
    </View>
  )
}

const Actions = ({
  editOperator,
  onClose,
  onEdit,
  operatorEmails,
  operatorPhones,
  onEditPhones,
  onEditEmails,
  operatorId,
  onUpdate,
}) => {
  const [updateOperator] = useMutation(UPDATE_OPERATOR)
  const [updateOperatorContact] = useMutation(UPDATE_OPERATOR_CONTACT)
  const { t } = useTranslation()
  const [phoneValid, setPhoneValid] = React.useState(false)
  const [buttonEditLoading, setButtonEditLoading] = React.useState(false)
  const [errorNumber, setErrorNumber] = React.useState('')
  const [operatorPhonesToCreate, setOperatorsPhonesToCreate] = React.useState(
    []
  )
  const [operatorEmailsToCreate, setOperatorsEmailsToCreate] = React.useState(
    []
  )
  const [errorEmails, setErrorEmails] = React.useState('')

  const [deleteEmailsOperator] = useMutation(DELETE_EMAILS_OPERATOR)
  const [deletePhonesOperator] = useMutation(DELETE_PHONES_OPERATOR)
  const [insertEmailsOperator] = useMutation(INSERT_EMAIL_OPERATOR)
  const [insertPhonesOperator] = useMutation(INSERT_PHONE_OPERATOR)

  const updateOperatorInformation = async (data) => {
    setButtonEditLoading(true)
    onUpdate(operatorId)
    try {
      if (phoneValid === true) {
        await updateOperatorContact({
          variables: {
            id: editOperator.operators_contact.id,
            address: data.address
              ? data.address
              : editOperator.operators_contact.address,
            email: data.email
              ? data.email
              : editOperator.operators_contact.email,
            name: data.name ? data.name : editOperator.operator_name,
            phone: data.selectedCallingCode
              ? data.selectedCallingCode
              : editOperator.operators_contact.phone,
          },
        })
        const operator = await updateOperator({
          variables: {
            id: editOperator.id,
            operator_company_name: data.companyName
              ? data.companyName
              : editOperator.operator_company_name,
            operator_name: data.name ? data.name : editOperator.operator_name,
          },
        })

        const newOperator = {
          id: editOperator.id,
          operator_company_name: operator.data.update_operators_by_pk
            .operator_company_name
            ? operator.data.update_operators_by_pk.operator_company_name
            : editOperator.operator_company_name,
          operator_name: operator.data.update_operators_by_pk.operator_name
            ? operator.data.update_operators_by_pk.operator_name
            : editOperator.operator_name,
          operators_contact: {
            id: editOperator.operators_contact.id,
            email: operator.data.update_operators_by_pk.operators_contact.email
              ? operator.data.update_operators_by_pk.operators_contact.email
              : editOperator.operators_contact.email,
            phone: operator.data.update_operators_by_pk.operators_contact.phone
              ? operator.data.update_operators_by_pk.operators_contact.phone
              : editOperator.operators_contact.phone,
          },
        }
        if (operatorEmailsToCreate.length > 0) {
          await deleteEmailsOperator({
            variables: {
              operatorId: operatorId,
            },
          })
          await createOperatorEmails()
        }
        if (operatorPhonesToCreate.length > 0) {
          await deletePhonesOperator({
            variables: {
              operatorId: operatorId,
            },
          })
          await createOperatorPhones()
        }
        onEdit(newOperator)
        onClose()
      } else {
        setErrorNumber(t('errorPhoneNumber'))
        setButtonEditLoading(false)
      }
    } catch (error) {
      onClose()
      console.log(error)
    }
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: editOperator.operator_company_name,
      name: editOperator.operator_name,
      email: editOperator.operators_contact.email,
      selectedCallingCode: editOperator.operators_contact.phone.toString(),
    },
  })
  const l = useTranslation()
  const language = l.i18n.languages[0]

  const placeholderPhone = () => {
    if (language === 'pt') {
      return '55 18 391231233'
    } else if (language === 'en') {
      return '1 (704) 123-4567'
    } else if (language === 'es') {
      return '52 123 456 1789'
    } else {
      return '1 (704) 123-4567'
    }
  }

  const addPhoneNumbers = (phones) => {
    const addedPhonesNumbers = phones.filter((phone) => {
      return phone.valid === true
    })
    if (addedPhonesNumbers.length === phones.length) {
      setOperatorsPhonesToCreate(phones)
    }
  }

  const addEmails = (emails) => {
    const addedEmails = emails.filter((email) => {
      return email.valid === true
    })
    if (addedEmails.length === emails.length) {
      setErrorEmails('')
      setOperatorsEmailsToCreate(emails)
    } else {
      setErrorEmails(t('errors.errorValidEmail'))
    }
  }

  const createOperatorEmails = async () => {
    const getCreatedOperatorEmails = await Promise.all(
      operatorEmailsToCreate.map(async (email) => {
        const userEmail = await insertEmailsOperator({
          variables: {
            email: email.email,
            operatorId: email.operator_id,
          },
        })

        return userEmail.data.insert_operator_emails_one
      })
    )

    onEditEmails(getCreatedOperatorEmails)
  }

  const createOperatorPhones = async () => {
    const getCreatedOperatorPhones = await Promise.all(
      operatorPhonesToCreate.map(async (phone) => {
        const userPhone = await insertPhonesOperator({
          variables: {
            phone_number: phone.phone_number,
            operatorId: phone.operator_id,
          },
        })

        return userPhone.data.insert_operator_phones_one
      })
    )

    onEditPhones(getCreatedOperatorPhones)
  }

  return (
    <Modal.Content>
      <Modal.Header>{t('operatorAccount.titleModal')}</Modal.Header>
      <Modal.Body>
        <Text pb="2" color="muted.600">
          {t('operatorAccount.informationModal')}
        </Text>
        <Text bold color="muted.600">
          {t('operatorAccount.companyNameLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-ZÀ-ÿ0-9 _]*[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 _]*$/,
              message: t('operatorAccount.lettersAndNumbers'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.companyNamePlaceholder')}
              bgColor="white"
            />
          )}
          name="companyName"
        />
        {errors.companyName && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.companyName.message
              ? errors.companyName.message
              : t('operatorAccount.required')}
          </Text>
        )}

        <Divider />
        <Text pb="2" color="muted.600">
          {t('operatorAccount.contactInformationModal')}
        </Text>
        <Text bold color="muted.600">
          {t('operatorAccount.nameModalLabel')}
        </Text>

        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
              message: t('operatorAccount.onlyLetters'),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.nameModalLabel')}
              bgColor="white"
            />
          )}
          name="name"
        />
        {errors.name && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.name.message
              ? errors.name.message
              : t('operatorAccount.required')}
          </Text>
        )}
        <Text bold color="muted.600">
          {t('operatorAccount.emailModalLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={t('operatorAccount.emailModalPlaceholder')}
              bgColor="white"
            />
          )}
          name="email"
        />
        {errors.email && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {errors.email.message
              ? errors.email.message
              : t('operatorAccount.required')}
          </Text>
        )}
        <Text>{t('companyProfile.addEmails')}</Text>
        <Emails
          onAction={addEmails}
          errorEmails={errorEmails}
          operatorId={operatorId}
          operatorEmails={operatorEmails}
        />
        <Text bold color="muted.600">
          {t('operatorAccount.phoneNumberModalLabel')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhoneInput
              inputStyle={{
                width: '100%',
                backgroundColor: 'white',
                borderColor: '#E3E3E3',
              }}
              buttonStyle={{ borderColor: '#D4D4D4' }}
              masks={{ br: '.. .........' }}
              placeholder={placeholderPhone()}
              value={value}
              onChange={onChange}
              isValid={(value, country) => {
                if (
                  value.length > 1 &&
                  country.name === 'United States' &&
                  value.length < 11
                ) {
                  setPhoneValid(false)
                } else if (
                  value.length > 2 &&
                  country.name === 'Brazil' &&
                  value.length < 12
                ) {
                  setPhoneValid(false)
                } else {
                  setPhoneValid(true)
                  return true
                }
              }}
            />
          )}
          name="selectedCallingCode"
        />
        {errors.selectedCallingCode && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {t('operatorAccount.required')}
          </Text>
        )}
        {phoneValid === true ? (
          <View />
        ) : (
          <View zIndex="-2">
            <Text fontSize="xs" color="#eb0100">
              {errorNumber}
            </Text>
          </View>
        )}
        <br />
        <Text>{t('companyProfile.addPhones')}</Text>
        <Phones
          placeholderPhone={placeholderPhone}
          onAction={addPhoneNumbers}
          operatorId={operatorId}
          operatorPhones={operatorPhones}
        />
        <VStack space={1}>
          <Button
            rounded="2xl"
            colorScheme="yellow"
            isLoading={buttonEditLoading}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
            onPress={handleSubmit(updateOperatorInformation)}
          >
            {t('operatorAccount.saveModalButton')}
          </Button>
          <Button
            rounded="2xl"
            colorScheme="red"
            onPress={() => {
              onClose()
            }}
          >
            {t('operatorAccount.cancelModalButton')}
          </Button>
        </VStack>
      </Modal.Body>
    </Modal.Content>
  )
}

export default Actions
