import React from 'react'
import {
  HStack,
  Select,
  Text,
  Modal,
  CheckIcon,
  View,
  Input,
  Button,
  Center,
  Box,
  Image,
  Tooltip,
} from 'native-base'
import explanation from '../assets/question_icon.png'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_FLEET } from '../graphql/queries'
import { CREATE_AIRCRAFT, CREATE_AIRCRAFT_IMAGE } from '../graphql/mutations'
import { getToken } from '../Token'
import { useForm, Controller } from 'react-hook-form'
import ImageUploading from 'react-images-uploading'
import { auth, storage } from '../utils/nhost'
import { HASURA } from '@env'
import { useTranslation } from 'react-i18next'

export const newAircraftBooking = ({ onAction, onClose }) => {
  const [user, setUser] = React.useState('')
  const [images, setImages] = React.useState([])
  const [fleetId, setFleetId] = React.useState('')
  const maxNumber = 69
  const [createAircraftInFleet] = useMutation(CREATE_AIRCRAFT)
  const [createAircraftImage] = useMutation(CREATE_AIRCRAFT_IMAGE)
  const [getAircrafts] = useLazyQuery(GET_FLEET)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const { t } = useTranslation()

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    const fleet = await getAircrafts({
      variables: { userId: userId },
    })
    setUser(userId)
    setFleetId(fleet.data.fleet[0].id)
  }, [])

  const uploadImage = async () => {
    const token = auth.getJWTToken()
    const file = images[0].file
    const data = new FormData()
    data.append('file[]', file)
    data.append('metadata[]', file)
    try {
      let res = await fetch(`${HASURA}/v1/storage/files/`, {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      let responseJson = await res.json()
      const imageId = responseJson.processedFiles[0].id
      return imageId
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (aircraft) => {
    setButtonLoading(true)
    try {
      if (images.length > 0) {
        await createAircraftWithImage(aircraft)
      } else {
        await createAircraftinFleet(aircraft)
      }
      setButtonLoading(false)
      onAction()
      onClose()
    } catch (error) {
      setButtonLoading(false)
      setError(t('fleet.errors.create'))
    }
  }
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  const {
    control,
    reset,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tailNumbers: '',
      helicopterModel: '',
      mtow: '',
      checked: '',
    },
  })

  const createAircraftWithImage = async (aircraft) => {
    const imageId = await uploadImage()
    const aircraftImage = await createAircraftImage({
      variables: {
        file_id: imageId,
      },
    })
    await createAircraftInFleet({
      variables: {
        fleet_id: fleetId,
        helicopter_makes_models: aircraft.helicopterModel,
        mtow: aircraft.mtow,
        tail_numbers: aircraft.tailNumbers,
        weight_measure_type: aircraft.checked,
        aircraft_image_id: aircraftImage.data.insert_aircraft_images_one.id,
      },
    })
    reset()
    setImages([])
  }

  const createAircraftinFleet = async (aircraft) => {
    await createAircraftInFleet({
      variables: {
        fleet_id: fleetId,
        helicopter_makes_models: aircraft.helicopterModel,
        mtow: aircraft.mtow,
        tail_numbers: aircraft.tailNumbers,
        weight_measure_type: aircraft.checked,
      },
    })
    reset()
    setImages([])
  }

  if (error)
    return (
      <Text p="2" color="#eb0100">
        {error}
      </Text>
    )
  return (
    <Modal.Content maxWidth="550px">
      <Modal.CloseButton />
      <Modal.Header>{t('fleet.createAircraft')}</Modal.Header>
      <Modal.Body>
        <Text fontSize="md" p="2">
          {t('fleet.aircraftInformation')}
        </Text>
        <Text bold fontSize="s" p="2">
          {t('fleet.tailNumber')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('fleet.tailNumber')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., N904DE"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="tailNumbers"
        />
        {errors.tailNumbers && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {t('fleet.required')}
          </Text>
        )}

        <Text bold fontSize="s" p="2">
          {t('fleet.model')}
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('fleet.model')}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={{ backgroundColor: 'white' }}
              variant="unstyled"
              borderWidth={1}
              InputRightElement={
                <Tooltip
                  bg="yellow.100"
                  _text={{
                    color: 'black',
                  }}
                  placement="left"
                  label="e.g., Bell 206"
                >
                  <Image
                    source={{
                      uri: explanation,
                    }}
                    alt="Alternate Text"
                    mr={2}
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              }
            />
          )}
          name="helicopterModel"
        />
        {errors.helicopterModel && (
          <Text p="2" fontSize="xs" color="#eb0100">
            {t('fleet.required')}
          </Text>
        )}

        <Text bold fontSize="s" p="2">
          {t('fleet.maxWeight')}
        </Text>
        <HStack space={3}>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[1-9]\d*(\.\d+)?$/,
                message: t('fleet.ruleNumber'),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                placeholder={t('fleet.maxWeight')}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                style={{ backgroundColor: 'white' }}
                variant="unstyled"
                borderWidth={1}
                InputRightElement={
                  <Tooltip
                    bg="yellow.100"
                    _text={{
                      color: 'black',
                    }}
                    placement="left"
                    label="e.g., 500"
                  >
                    <Image
                      source={{
                        uri: explanation,
                      }}
                      alt="Alternate Text"
                      mr={2}
                      style={{ width: 20, height: 20 }}
                    />
                  </Tooltip>
                }
              />
            )}
            name="mtow"
          />
          {errors.mtow && (
            <Text p="2" fontSize="xs" color="#eb0100">
              {errors.mtow.message ? errors.mtow.message : t('fleet.required')}
            </Text>
          )}
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                onBlur={onBlur}
                selectedValue={value}
                minWidth="200"
                accessibilityLabel="Choose Service"
                placeholder="kg/lbs"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={onChange}
              >
                <Select.Item label="kg" value="kg" />
                <Select.Item label="lbs" value="lbs" />
                <Select.Item label="t" value="t" />
              </Select>
            )}
            name="checked"
          />
          {errors.checked && (
            <Text p="2" fontSize="xs" color="#eb0100">
              {t('fleet.required')}
            </Text>
          )}
        </HStack>
        <View pt="30">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <View className="upload__image-wrapper">
                {imageList.map((image, index) => (
                  <Center key={index}>
                    <Image
                      source={{
                        uri: `${image['data_url']}`,
                      }}
                      alt="Alternate Text"
                      size={'xl'}
                      resizeMode="cover"
                      rounded="xl"
                    />
                    <HStack space={3} p="4">
                      <Box shadow={2} maxWidth="40">
                        <Text pt="2" isTruncated="true">
                          {image.file.name}
                        </Text>
                      </Box>
                      <Button
                        colorScheme="yellow"
                        onPress={() => onImageUpdate(index)}
                      >
                        {t('fleet.updateButton')}
                      </Button>
                      <Button
                        colorScheme="red"
                        onPress={() => onImageRemove(index)}
                      >
                        {t('fleet.removeButton')}
                      </Button>
                    </HStack>
                  </Center>
                ))}
                <Center>
                  <Button
                    width="40%"
                    colorScheme="yellow"
                    size="md"
                    style={isDragging ? { color: 'red' } : undefined}
                    onPress={onImageUpload}
                    {...dragProps}
                  >
                    {t('fleet.uploadButton')}
                  </Button>
                </Center>
              </View>
            )}
          </ImageUploading>
        </View>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group space={2}>
          <Button
            variant="ghost"
            colorScheme="blueGray"
            onPress={() => {
              onClose()
            }}
          >
            {t('fleet.cancelButton')}
          </Button>
          <Button
            colorScheme="yellow"
            isLoading={buttonLoading}
            _loading={{
              bg: 'amber.400:alpha.70',
              _text: {
                color: 'coolGray.700',
              },
            }}
            _spinner={{
              color: 'white',
            }}
            isLoadingText={t('submit_button.text')}
            onPress={handleSubmit(onSubmit)}
          >
            {t('fleet.saveButton')}
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  )
}
export default newAircraftBooking
