import React from 'react'
import {
  View,
  Button,
  Text,
  Box,
  Heading,
  Divider,
  HStack,
  VStack,
  Container,
  NativeBaseProvider,
  Stack,
} from 'native-base'
import { useLazyQuery } from '@apollo/client'
import { GET_HELIPORT_MANAGER } from '../../graphql/queries'
import { getToken } from '../../Token'
import { FlatList } from 'react-native-gesture-handler'
import { useHistory } from '../../routing'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../AxiosInstance'
import moment from 'moment-timezone'

const BookingsIsCancelled = ({ booking, timezone }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const goToBookingDetail = (id) => {
    history.push(`/bookingDetail/${id}`)
  }

  const getDate = (bookingDate) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }
  const bookingStatus = {
    cancelled_by_customer: t(
      'managerBooking.bookingPendingConfirm.bookingStatus.cancelledCustomer'
    ),
    cancelled: t(
      'managerBooking.bookingPendingConfirm.bookingStatus.cancelled'
    ),
    declined: t('managerBooking.bookingPendingConfirm.bookingStatus.declined'),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
              {t('managerBooking.cancelCompletedRequests.cancelled')}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.reservation'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('managerBooking.cancelCompletedRequests.date')}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getDate(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.start'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getStartBooking(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('managerBooking.cancelCompletedRequests.end')}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getEndBooking(item.attributes.event.end)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Helipad:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.attributes.event.what}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.customer'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.aircraftType'
                              )}
                            </Heading>
                            <Text></Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.tailNumber'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.tail_number
                                ? item.meta.tail_number
                                : item.attributes.customer
                                ? item.attributes.customer.tail_number
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('managerBooking.cancelCompletedRequests.status')}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('managerBooking.cancelCompletedRequests.booking')}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('managerBooking.cancelCompletedRequests.payment')}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      {item.meta.reason ? (
                        <View>
                          <HStack space={2}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('managerBooking.cancelCompletedRequests.note')}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta.reason === 'payment error'
                                ? t(
                                    'managerBooking.cancelCompletedRequests.errorPayment'
                                  )
                                : item.meta.reason ===
                                  'Reservation canceled, due to exceeding the time of the request'
                                ? t(
                                    'managerBooking.cancelCompletedRequests.exceedingReservation'
                                  )
                                : item.meta.reason}
                            </Text>
                          </HStack>
                        </View>
                      ) : (
                        <Text></Text>
                      )}
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width="20%" height="100%">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('managerBooking.cancelCompletedRequests.actions')}
                      </Text>
                      <View>
                        <Text
                          fontSize={['xs', 'sm', 'sm', 'sm']}
                          bold
                          underline
                          onPress={() => {
                            goToBookingDetail(item.id)
                          }}
                        >
                          {t(
                            'managerBooking.cancelCompletedRequests.viewDetails'
                          )}
                        </Text>
                      </View>
                    </Container>
                  </Stack>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

const BookingsIsCompleted = ({ booking, timezone }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const deleteBooking = async (id) => {
    try {
      await axiosInstance.post(`/bookings/deleteBookingById`, {
        id: id,
      })
      window.location.reload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const goToBookingDetail = (id) => {
    history.push(`/bookingDetail/${id}`)
  }

  const getDate = (bookingDate) => {
    const date = moment(bookingDate).tz(timezone).format('LL')
    return date
  }

  const getStartBooking = (bookingDate) => {
    const bookingStart = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingStart
  }

  const getEndBooking = (bookingDate) => {
    const bookingEnd = moment(bookingDate).tz(timezone).format('HH:mm')
    return bookingEnd
  }

  const bookingStatus = {
    completed: t(
      'managerBooking.cancelCompletedRequests.bookingStatus.confirmed'
    ),
  }

  const getPaymentStatusStripe = (paymentStatus) => {
    if (paymentStatus.meta.paymentStatus === 'succeeded') {
      return t('paymentStatus.succeeded')
    } else if (paymentStatus.meta.paymentStatus === 'canceled') {
      return t('paymentStatus.canceled')
    } else if (paymentStatus.meta.paymentStatus === 'requires_capture') {
      return t('paymentStatus.requireCapture')
    } else {
      return t('paymentStatus.payment')
    }
  }

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus.meta.manual_reservation || paymentStatus.meta.stripe) {
      if (
        paymentStatus.meta.manual_reservation &&
        paymentStatus.meta.stripe === 'unpaid'
      ) {
        return t('paymentStatus.manualReservation')
      } else if (paymentStatus.meta.stripe === 'paid-authorized') {
        return t('paymentStatus.paidAuthorized')
      } else if (paymentStatus.meta.stripe === 'paid-charged') {
        return t('paymentStatus.paidCharged')
      } else if (paymentStatus.meta.stripe === 'unpaid') {
        return t('paymentStatus.unPaidInvoice')
      } else if (paymentStatus.meta.stripe === 'paid-cancel') {
        return t('paymentStatus.paidCancel')
      } else if (paymentStatus.meta.stripe === 'Paid-code') {
        return t('paymentStatus.paidCode')
      }
    } else {
      return t('paymentStatus.payment')
    }
  }

  if (booking.length > 0) {
    return (
      <View>
        <NativeBaseProvider>
          <Box
            p={['4', '4', '10', '10']}
            rounded="xl"
            backgroundColor="light.100"
          >
            <Text p="1%" fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
              {t('managerBooking.cancelCompletedRequests.completed')}
            </Text>
            <FlatList
              data={booking}
              keyExtractor={(item) => item.id.toString()}
              ItemSeparatorComponent={() => <br />}
              renderItem={({ item }) => (
                <Box
                  p={['4', '4', '12', '12']}
                  rounded="xl"
                  backgroundColor="white"
                  key={item.id.toString()}
                >
                  <Stack
                    space={3}
                    direction={['column', 'column', 'column', 'row']}
                  >
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.reservation'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('managerBooking.cancelCompletedRequests.date')}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getDate(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.start'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getStartBooking(item.attributes.event.start)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t('managerBooking.cancelCompletedRequests.end')}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {getEndBooking(item.attributes.event.end)}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              Helipad:
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.attributes.event.what}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '25%']}>
                      <VStack space={3}>
                        <View>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'md']}
                            fontWeight="bold"
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.customer'
                            )}
                          </Text>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.operator'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.name
                                ? item.meta.name
                                : item.attributes.customer &&
                                  item.attributes.customer.name
                                ? item.attributes.customer.name
                                : ''}
                            </Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.aircraftType'
                              )}
                            </Heading>
                            <Text></Text>
                          </HStack>
                          <HStack space={3}>
                            <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {t(
                                'managerBooking.cancelCompletedRequests.tailNumber'
                              )}
                            </Heading>
                            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                              {item.meta && item.meta.tail_number
                                ? item.meta.tail_number
                                : item.attributes.customer
                                ? item.attributes.customer.tail_number
                                : ''}
                            </Text>
                          </HStack>
                        </View>
                      </VStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width={['100%', '100%', '100%', '20%']}>
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('managerBooking.cancelCompletedRequests.status')}
                      </Text>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('managerBooking.cancelCompletedRequests.booking')}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {bookingStatus[item.state]}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Heading fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {t('managerBooking.cancelCompletedRequests.payment')}
                        </Heading>
                        <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
                          {item.meta.paymentStatus
                            ? getPaymentStatusStripe(item)
                            : getPaymentStatus(item)}
                        </Text>
                      </HStack>
                      <Divider bg="yellow.400" />
                    </Container>
                    <Container width="25%" height="100%">
                      <Text
                        fontSize={['xs', 'sm', 'sm', 'md']}
                        fontWeight="bold"
                      >
                        {t('managerBooking.cancelCompletedRequests.actions')}
                      </Text>
                      <View>
                        <HStack space={3}>
                          <Text
                            fontSize={['xs', 'sm', 'sm', 'sm']}
                            bold
                            underline
                            p="3"
                            onPress={() => {
                              goToBookingDetail(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.viewDetails'
                            )}
                          </Text>
                          <Button
                            _text={{
                              fontSize: ['xs', 'sm', 'sm', 'sm'],
                            }}
                            bgColor="danger.500"
                            size="md"
                            onPress={() => {
                              deleteBooking(item.id)
                            }}
                          >
                            {t(
                              'managerBooking.cancelCompletedRequests.deleteButton'
                            )}
                          </Button>
                        </HStack>
                      </View>
                    </Container>
                  </Stack>
                </Box>
              )}
            />
          </Box>
        </NativeBaseProvider>
      </View>
    )
  } else {
    return <Text></Text>
  }
}

export const BookingsCancelandCompleted = () => {
  const [user, setUserId] = React.useState('')
  const [bookingsCompleted, setBookingsCompleted] = React.useState([])
  const [bookingsCancelled, setBookingsCancelled] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [timezone, setTimezone] = React.useState('')
  const [error, setError] = React.useState('')
  const { t } = useTranslation()

  const [manager] = useLazyQuery(GET_HELIPORT_MANAGER, {
    variables: { userId: user },
  })

  React.useEffect(async () => {
    const tokenInformation = getToken()
    const userId =
      tokenInformation['https://hasura.io/jwt/claims']['x-hasura-user-id']
    setUserId(userId)
    const getManagerProjectId = await manager()
    const heliportTimeZone =
      getManagerProjectId.data.heliports[0].heliport_profile.timezone

    setTimezone(heliportTimeZone)
    const timeKitProjectId =
      getManagerProjectId.data.heliports[0].heliport_profile.timekit_project_id
    await getBookingsManager(timeKitProjectId)
    setIsLoading(false)
  }, [])

  const getBookingsManager = async (timeKitProjectId) => {
    try {
      const getHeliportBookingsCancelandReject = await axiosInstance.post(
        `/bookings/getBookingsCancelandReject`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )

      setBookingsCancelled(getHeliportBookingsCancelandReject.data.bookings)
      const getHeliportBookingsCompleted = await axiosInstance.post(
        `/bookings/getBookingsCompleted`,
        {
          timeKitProjectId: timeKitProjectId,
        }
      )

      setBookingsCompleted(getHeliportBookingsCompleted.data.bookings)
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setError(t('errors.bookings'))
        } else {
          setError(
            error.response.data &&
              error.response.data.error &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        }
      } else {
        setError(t('errors.bookings'))
      }
    }
  }

  if (error) return <Text>{error}</Text>

  return (
    <View>
      {isLoading ? (
        <Loading />
      ) : bookingsCompleted.length === 0 && bookingsCancelled.length === 0 ? (
        <View>
          <Heading p="1%">
            {t('managerBooking.bookingPendingConfirm.title')}
          </Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <Text fontSize={['xs', 'sm', 'sm', 'sm']}>
              {t('managerBooking.bookingPendingConfirm.noBooking')}
            </Text>
          </Box>
        </View>
      ) : (
        <View>
          <Heading p="1%">
            {t('managerBooking.bookingPendingConfirm.title')}
          </Heading>
          <Box p={['4', '4', '12', '12']} rounded="xl" bgColor="gray.200">
            <BookingsIsCancelled
              booking={bookingsCancelled}
              timezone={timezone}
            />
            <p />
            <BookingsIsCompleted
              booking={bookingsCompleted}
              timezone={timezone}
            />
          </Box>
        </View>
      )}
    </View>
  )
}
export default BookingsCancelandCompleted
